import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CartView from './CartView';

function CartContainer() {
	return (
		<CartView
		/>
	)
}

function mapStateToProps(state) {
	return {}
}

const mapDispatchToProps = {}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CartContainer);
