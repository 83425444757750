import React from 'react';
import { connect } from 'react-redux';
import CheckoutInfoView from './CheckoutInfoView';
import { withRouter } from 'react-router';

function CheckoutInfoContainer (
    {
        match
    }
) {
    return <CheckoutInfoView
        match={match}
    ></CheckoutInfoView>
}

function mapStateToProps(state) {
	return {
		
	}
}

const mapDispatchToProps = {
	
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(CheckoutInfoContainer));
