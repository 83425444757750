import React from "react";
import "./CheckoutShipping.scss";
import {
    generateCheckoutLink,
    getCheckoutPromotion,
    startCheckout,
    validateEmail,
    getCheckout,
    updateUser,
    saveAddress,
    saveShippingInfo,
    getDiscount,
    deleteCartItem,
    getIpInfo,
    getInstallments
} from '../../../services/Service';
import {
    saveDataLayerEvent,
    saveDataLayerStepEvent,
    saveSatelliteEvent
} from '../../../services/TrackerService';

import Summary from './../shared/Summary'
import Contact from './../shared/Contact'
import User from './../shared/User'
import { KEYS } from '../../../constants/index';

import { ReactComponent as IconHelp } from './../../../assets/help.svg';
import { ReactComponent as IconWarning } from './../../../assets/warning.svg';
import { ReactComponent as IconClose } from './../../../assets/close.svg';
import { ReactComponent as IconInfo } from './../../../assets/info.svg';

import DatePicker from "react-datepicker";
import * as $ from 'jquery';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import * as moment from "moment";
import 'moment/locale/es';
import es from 'date-fns/locale/es';

registerLocale('es', es);

class CheckoutShippingView extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        checkout: null,
        address: null,
        user: null,

        stores: [],
        showPrices: true,
        storesModal: {
            mode: '',
            selectedAddress: null,
            selectedStore: null,
            pickUpStore: [],
            pickUpStoreDateRange: {
                min: new Date(),
                max: new Date()
            },
            searchedAddress: null,
            pickUpDateScheadule: [],
            defaultLat: -12.0986256,
            defaultLong: -77.0703135
        },
        selectedStore: null,
        alternativePickupPersonFullName: '',
        alternativePickupPersonPhone: '',
        alternativePickupPersonIdentificationNumber: "",
        alternativePickupPersonIdentificationType: "1",
        documentNumberLength: 8,
        submitting: false,
        errors: {}
    }

    componentDidMount = async () => {
        this.loadUser();
        this.loadAddress();

        await this.loadCheckout();

        this.mergeItems();
        this.loadInfo();
        moment.locale('es');
        // this.loadPromo();
    }

    loadUser = () => {
        let user = JSON.parse(window.localStorage.getItem("__user__"));

        this.setState({
            user
        })
    }

    loadAddress = () => {
        let address = JSON.parse(window.localStorage.getItem("__address__"));

        this.setState({
            address
        })
    }

    getCheckoutId = () => {
        const { match } = this.props;

        let id = match.params.id;
        return id;
    }

    loadCheckout = async () => {
        let id = this.getCheckoutId();

        let checkout = await getCheckout(id);
        if (checkout.data) {
            this.setState({
                checkout: checkout.data,
            });
        }
    }

    loadInfo = () => {
        let previousState = window.localStorage.getItem("__shippingState__");

        if (previousState) {
            previousState = JSON.parse(previousState);
            console.log(previousState);

            previousState.submitting = false;

            delete previousState.checkout;
            delete previousState.stores;

            this.setState({
                ...previousState
            });
        }

        let shippingInfo = window.localStorage.getItem("__shippingInfo__");
        if (shippingInfo) {
            const { stores } = this.state;
            let storesInfo = JSON.parse(shippingInfo);
            let _stores = stores;

            _stores.forEach(s => {
                let prevStore = storesInfo.find(si => {
                    return si.store === s.store;
                });

                if (prevStore) {
                    s.deliveryDeliveryWindow = prevStore.deliveryDeliveryWindow;
                    s.deliveryDeliveryWindowId = prevStore.deliveryDeliveryWindowId;
                    s.deliveryScheadule = prevStore.deliveryScheadule;
                    s.scheadule = prevStore.scheadule;
                    s.selectedDate = prevStore.selectedDate;
                    s.selectedDates = prevStore.selectedDates;
                    s.selectedDeliveryDate = prevStore.selectedDeliveryDate;
                    s.selectedDeliveryScheadule = prevStore.selectedDeliveryScheadule;
                    s.selectedDeliverySla = prevStore.selectedDeliverySla;
                    s.selectedDeliveryWindow = prevStore.selectedDeliveryWindow;
                    s.shippingMethod = prevStore.shippingMethod;
                }
            });

            this.setState({
                stores: _stores
            });
        }
    }

    formatMoney = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' }).format(amount) : 'S/ 0.00';
    }

    formatNumber = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE').format(amount) : '0';
    }

    mergeItems = () => {
        const { checkout, address } = this.state;

        let externalCart = checkout.externalCart.map((el, i) => {
            el.shippingData = JSON.parse(el.externalJsonShipping);
            return el;
        })
        let sItems = [];

        if (externalCart) {
            externalCart.forEach((cart, i) => {
                cart.shippingData.items.forEach((el, i) => {
                    
                    let exist = sItems.find((sItem) => {
                        return sItem.id === el.id && sItem.sellingPrice === el.sellingPrice;
                    });

                    //Todo: Check seller info
                    el.storeInfo = cart.shippingData.sellers.find(seller => seller.id === el.seller);

                    if (!exist) {
                        sItems.push(el);
                    }
                })
            })
        }

        let stores = {};
        let storesArray = [];
        let gItems = checkout.items;

        console.log(sItems);

        let logisticInfo = externalCart[0].shippingData ? externalCart[0].shippingData.logisticsInfo : externalCart[0].logisticsInfo;

        if (!logisticInfo)
            logisticInfo = externalCart[0].shippingData.shippingData.logisticsInfo;

        sItems.forEach((s, i) => {
            // var itemSD = externalCart.filter((store, idx) => {
            //     return s.seller == store.externalSellerId;
            // })

            // if (itemSD && itemSD.length > 0) {
            //     itemSD = itemSD[0];
            // }

            let lInfo = logisticInfo.find(li => s.requestIndex === li.itemIndex); //itemSD.shippingData ? itemSD.shippingData.logisticsInfo : itemSD.logisticsInfo;

            // if (!lInfo) {
            //     if (itemSD.shippingData.shippingData)
            //         lInfo = itemSD.shippingData.shippingData.logisticsInfo;
            // }

            gItems.forEach((g, j) => {
                if (s.id === g.productId) {
                    s.productInfo = g;
                }
            });

            s.logisticInfo = lInfo;

            // if (lInfo) {
            //     lInfo.forEach((l, k) => {
            //         if (s.requestIndex === l.itemIndex || s.id === l.itemId) {
            //             s.logisticInfo = l;
            //         }
            //     })
            // }
        })

        sItems.forEach((el, i) => {
            let key = el.productInfo.sellerName.replace(/ /g, "");
            if (key === "")
                key = el.seller;

            if (stores[key]) {
                stores[key].products.push(el);
            } else {
                stores[key] = {
                    products: [el]
                }
            }
        });

        Object.keys(stores).forEach(storeKey => {
            const store = stores[storeKey];
            let shippingMethods = store.products.reduce((methods, el, i) => {
                if (el.logisticInfo) {
                    if (i === 0 || methods.length > el.logisticInfo.deliveryChannels.length) {
                        return el.logisticInfo.deliveryChannels;
                    }
                }
                return methods;
            }, []);
        
            const firstProduct = store.products[0];
            const sellerName = firstProduct.productInfo.sellerName;
            const storeName = sellerName && sellerName.length > 0 ? sellerName : firstProduct.storeInfo.name;
            
            const storeSeller = firstProduct.seller !== firstProduct.storeInfo.name ? firstProduct.seller : firstProduct.storeInfo.name;
        
            storesArray.push({
                storeName: storeName,
                store: storeSeller,
                storeInfo: firstProduct.storeInfo,
                products: store.products,
                shippingMethods: shippingMethods,
                shippingMethod: shippingMethods.length > 0 ? shippingMethods[0].id : ''
            });
        });
        
        if (address) {
            storesArray.forEach((el, i) => {
                el.address = {
                    addressType: "residential",
                    addressId: "123",
                    department: address.department,
                    province: address.productId,
                    district: address.district,
                    addressLine: address.addressLine,
                    addressNumber: address.addressNumber,
                    reference: address.reference,
                    countryISO: "PER",
                    postalCode: address.postalCode,
                };
                el.selectedDates = [];
                el.selectedDate = null;
                el.scheadule = [];
                el.selectedDeliveryDate = null;
                el.selectedDeliveryWindow = null;
                el.selectedDeliverySla = null;
                el.selectedDeliveryScheadule = null;
                el.deliveryScheadule = [];
            })
        }

        this.setState({
            stores: storesArray
        });
    }

    getShippingName = (key) => {
        let shippingNames = {
            'delivery': 'Entrega a domicilio',
            'pickup-in-point': 'Retiro en tienda'
        }

        return shippingNames[key];
    }

    onChangeShippingMethod = (value, store, availability) => {
        if (!availability)
            return;

        let stores = this.state.stores;

        stores.forEach((el, i) => {
            if (el.store === store.store)
                el.shippingMethod = value
        });

        this.setState({ stores: stores });
    }

    renderShippingProductsV2 = (store) => {
        const { selectedStore, checkout } = this.state;

        checkout.externalCart.forEach((el, i) => {
            let cart = JSON.parse(el.externalJsonCart);
            if (cart.items[0].seller === store.store) {
                store.shippingMethods = [];

                if (cart.logisticsInfo == undefined) {
                    store.shippingMethods = cart.shippingData.logisticsInfo[0].deliveryChannels
                } else {
                    store.shippingMethods = cart.logisticsInfo[0].deliveryChannels
                }

                store.shippingMethods = store.shippingMethods.sort();

                store.products.forEach((i, j) => {
                    let _item = cart.items.find((ci, k) => {
                        return i.id === ci.id;
                    })

                    if (_item) {
                        i.requestIndex = _item.requestIndex;
                    }
                })
            }
        })

        let deliveryExpressSla = null;
        let firstDeliveryTime = '';

        store.products.forEach((p, j) => {
            let externalCart = checkout.externalCart;
            externalCart.forEach((c, k) => {
                if (store.store === c.externalSellerId) {
                    let cart = JSON.parse(c.externalJsonCart);
                    let lInfo = [];

                    if (cart.logisticsInfo == undefined)
                        lInfo = cart.shippingData.logisticsInfo;
                    else
                        lInfo = cart.logisticsInfo;

                    store._logisticInfo = lInfo;
                    lInfo.forEach((item, l) => {
                        if (p.requestIndex === item.itemIndex || p.id === item.itemId) {
                            let pickupSlas = [];
                            let deliverySlas = [];
                            if (item.slas && item.slas.length > 0) {
                                pickupSlas = item.slas.filter((_el, i) => { return _el.deliveryChannel === "pickup-in-point" });
                                deliverySlas = item.slas.filter((_el, i) => { return _el.deliveryChannel === "delivery" });
                                deliveryExpressSla = deliverySlas.find((_el, i) => { return _el.id === "Envío Express" });
                                // deliveryExpressSla = {
                                //     availableDeliveryWindows: [],
                                //     deliveryChannel: "delivery",
                                //     deliveryIds: [],
                                //     deliveryWindow: null,
                                //     id: "Envío Express",
                                //     listPrice: 900,
                                //     lockTTL: null,
                                //     name: "Envío Express",
                                //     pickupDistance: null,
                                //     pickupPointId: null,
                                //     pickupStoreInfo: { isPickupStore: false, friendlyName: null, address: null, additionalInfo: null, dockId: null },
                                //     polygonName: "",
                                //     price: 900,
                                //     shippingEstimate: "0bd",
                                //     shippingEstimateDate: null,
                                //     tax: 0,
                                //     transitTime: "0bd",
                                // }
                                store.deliveryExpressSla = deliveryExpressSla;

                                store.availablePickup = pickupSlas.length > 0;
                                store.availableDelivery = deliverySlas.length > 0;
                                store.shippindMethodsAvailability = {
                                    "pickup-in-point": pickupSlas.length > 0,
                                    "delivery": deliverySlas.length > 0
                                }

                                if (!store.shippingMethod || store.shippingMethod === "") {
                                    if (store.availableDelivery) {
                                        store.shippingMethod = "delivery"
                                    } else {
                                        store.shippingMethod = "pickup-in-point"
                                    }
                                }

                                if (deliveryExpressSla) {
                                    store.shippingMethods.unshift({
                                        id: 'express'
                                    });
                                    store.shippindMethodsAvailability['express'] = true;
                                }

                                if (deliverySlas.length > 0) {
                                    let sla = deliverySlas.find((_el) => { return _el.id === "Despacho a Domicilio" })
                                    if (sla) {
                                        if (sla.availableDeliveryWindows.length > 0) {
                                            firstDeliveryTime = moment(sla.availableDeliveryWindows[0].startDateUtc).format("D [de] MMMM");
                                        }
                                    }
                                }
                            } else {
                                store.shippindMethodsAvailability = {
                                    "pickup-in-point": false,
                                    "delivery": false,
                                    "express": false
                                }
                                store.shippingMethod = "delivery"
                            }
                            if (!item.slas || item.slas.length === 0) {

                                p.outOfStock = true;
                            }
                        }
                    })
                }
            })
        });

        let shippingCost = 0
        checkout.externalCart.forEach((el, i) => {
            let cart = JSON.parse(el.externalJsonCart);
            if (cart.items[0].seller === store.store) {
                let totals = cart.totals ? cart.totals : cart.totalizers;
                if (totals && totals.length > 0) {
                    let shipping = totals.find((t, j) => {
                        return t.id === "Shipping";
                    });

                    if (shipping) {
                        if (shipping.value > 0) {
                            shippingCost = shipping.value;
                        }
                    }
                }
            }
        });

        let shippingMethods = "";
        let today = moment().format("[Hoy] D [de] MMMM");

        if (store && store.shippingMethods.length > 0) {
            shippingMethods = (
                <React.Fragment>
                    <div className="store-shipping__methods">
                        {
                            <React.Fragment>
                                {
                                    store.shippingMethods.map((sm, j) => {
                                        return (
                                            <React.Fragment key={store.store + sm.id}>
                                                {
                                                    (store.shippindMethodsAvailability && store.shippindMethodsAvailability[sm.id]) &&
                                                    <div onClick={(e) => { this.onChangeShippingMethod(sm.id, store, store.shippindMethodsAvailability[sm.id]) }}
                                                        className={'store-shipping__methods__item ' + (store.shippingMethod === sm.id ? ' selected' : '') + (!store.shippindMethodsAvailability[sm.id] ? ' disabled' : '')}>
                                                        {
                                                            (sm.id === 'express') &&
                                                            <React.Fragment>
                                                                <h5>
                                                                    <label>Express</label>
                                                                    {today}
                                                                </h5>
                                                                <span className="price">{this.formatMoney(deliveryExpressSla.listPrice / 100)}</span>
                                                                <span>Disponible si lo compras antes de las <b className="text-danger">12:00 pm</b></span>
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            (sm.id === 'delivery') &&
                                                            <React.Fragment>
                                                                <h5>{firstDeliveryTime}</h5>
                                                                <span className="price">{this.formatMoney(shippingCost / 100)}</span>
                                                                <span>O puedes programar una fecha de entrega</span>
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            (sm.id === 'pickup-in-point') &&
                                                            <React.Fragment>
                                                                <h5>Retirar en tienda</h5>
                                                                <span className="price">GRATIS</span>
                                                                <span>Elige la tienda más cercana</span>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                }
                                            </React.Fragment>

                                        )
                                    })
                                }
                            </React.Fragment>

                        }
                    </div>
                    <div id={'container-' + store.store}>
                        {(store.shippingMethod === 'delivery') &&
                            this.renderProductDelivery(store)
                        }
                        {(store.shippingMethod === 'pickup-in-point') && (
                            <React.Fragment>
                                {
                                    (store.selectedSla) &&
                                    this.renderProductPickup(store)
                                }
                                {
                                    (!store.selectedSla) &&
                                    <React.Fragment>
                                        {this.showOpenStoresModal(store)}
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )}
                    </div>
                </React.Fragment>
            )
        }

        return (<React.Fragment>{shippingMethods}</React.Fragment>);
    }

    renderAlternativePickupPerson = (store) => {
        return (
            <React.Fragment>
                <div className="alternative-pickup-person">
                    {
                        (store.alternativePickupPersonFullName && store.alternativePickupPersonFullName !== '') &&
                        <React.Fragment>
                            <span>{store.alternativePickupPersonFullName} ({store.alternativePickupPersonIdentificationNumber})</span>
                            <a role="button" onClick={(e) => { this.handleEditAlternativePickupPerson(store); }}>Editar</a>
                            <a role="button" onClick={(e) => { this.handleRemoveAlternativePickupPerson(store); }}>Eliminar</a>
                        </React.Fragment>
                    }
                    {
                        (!store.alternativePickupPersonFullName || store.alternativePickupPersonFullName === '') &&
                        <a role="button" onClick={(e) => { this.handleAddAlternativePickupPerson(store); }}>¿Deseas que alguien recoja la compra por ti?</a>
                    }
                </div>
            </React.Fragment>
        );
    }

    handleEditAlternativePickupPerson = (store) => {
        this.setState({
            alternativePickupPersonFullName: store.alternativePickupPersonFullName,
            alternativePickupPersonIdentificationNumber: store.alternativePickupPersonIdentificationNumber,
            alternativePickupPersonIdentificationType: store.alternativePickupPersonIdentificationType,
            alternativePickupPersonPhone: store.alternativePickupPersonPhone,
        });

        this.toggleModal("#alternativePickupPersonModal", "show");
    }

    handleRemoveAlternativePickupPerson = (store) => {
        const { selectedStore } = this.state;
        let stores = this.state.stores;

        stores.forEach((el, i) => {
            if (selectedStore.store === el.store) {
                el.alternativePickupPersonFullName = null;
                el.alternativePickupPersonIdentificationNumber = null;
                el.alternativePickupPersonIdentificationType = null;
                el.alternativePickupPersonPhone = null;
            }
        });

        this.setState({
            stores: stores
        });
    }

    handleAddAlternativePickupPerson = (store) => {
        this.setState({ selectedStore: store });
        this.toggleModal('#alternativePickupPersonModal', 'show');
    }

    handleSaveAlternativePickupPerson = () => {
        const { selectedStore } = this.state;
        let stores = this.state.stores;

        if (!this.validState()) {
            return;
        }

        stores.forEach((el, i) => {
            if (selectedStore.store === el.store) {
                el.alternativePickupPersonFullName = this.state.alternativePickupPersonFullName;
                el.alternativePickupPersonIdentificationNumber = this.state.alternativePickupPersonIdentificationNumber;
                el.alternativePickupPersonIdentificationType = this.state.alternativePickupPersonIdentificationType;
                el.alternativePickupPersonPhone = this.state.alternativePickupPersonPhone;
            }
        });

        this.setState({
            stores: stores,
            alternativePickupPersonFullName: '',
            alternativePickupPersonIdentificationNumber: '',
            alternativePickupPersonIdentificationType: '1',
            alternativePickupPersonPhone: ''
        });

        this.toggleModal("#alternativePickupPersonModal", "hide");
    }

    toggleModal = (modal, action) => {
        $(modal).modal(action);
    }

    getStoreShippingCost = (store, shippingMethod) => {
        const { checkout, showPrices } = this.state;
        let cost = 0;
        let priceText = "";
        let slas = true;

        checkout.externalCart.forEach((el, i) => {
            let cart = JSON.parse(el.externalJsonCart);
            let productsIds = store.products.map(p => p.requestIndex);
            if (store.store === '1') {
                let products = store.products.map((p, j) => { return p.id; });
                let purchaseConditions = cart.purchaseConditions;

                if (purchaseConditions) {
                    let items = purchaseConditions.itemPurchaseConditions;
                    items.forEach(item => {
                        if (products.indexOf(item.id) > -1) {
                            let sla = item.slas ? item.slas[0] : null;
                            if (sla) {
                                cost += sla.listPrice;
                            } else {
                                slas = false;
                            }
                        }
                    });
                }
            } else {
                let logisticInfo = this.getStoreLogisticInfo(store); //store._logisticInfo.filter(sli => productsIds.indexOf(sli.itemIndex) > -1);

                logisticInfo.forEach((li) => {
                    if (li.slas && li.slas.length > 0) {
                        let liSla = li.slas.find((sla) => {
                            return sla.deliveryChannel === 'delivery';
                        })

                        if (liSla) {
                            cost += liSla.price;
                        }
                    } else {
                        slas = false;
                    }
                })
            }
        });

        if (!showPrices) {
            priceText = "Gratis";
        }

        if (shippingMethod === 'pickup-in-point') {
            priceText = "Gratis";
        }

        if (cost === 0) {
            priceText = "Gratis";
        }

        if (priceText !== "Gratis") {
            priceText = this.formatMoney(cost / 100);
        }

        return (
            <React.Fragment>
                {
                    (slas) &&
                    <React.Fragment>
                        <div className="shipping-cost">
                            {priceText}
                            <span className="d-none shippingCost">{cost / 100}</span>
                        </div>
                    </React.Fragment>
                }
                {/* {
                        (!slas) &&
                        <span>La tienda no cuenta con métodos de envío para tu dirección seleccionada.</span>
                    } */}
            </React.Fragment>
        );
    }

    hasAvailableSlas = (store) => {
        const { checkout } = this.state;

        let slas = true;

        checkout.externalCart.forEach((el, i) => {
            let cart = JSON.parse(el.externalJsonCart);
            // if (cart.items[0].seller === store.store) {
            if (store.store === '1') {
                let products = store.products.map((p, j) => { return p.id; });
                let purchaseConditions = cart.purchaseConditions;

                if (purchaseConditions) {
                    let items = purchaseConditions.itemPurchaseConditions;
                    items.forEach(item => {
                        if (products.indexOf(item.id) > -1) {
                            let sla = item.slas ? item.slas[0] : null;
                            if (sla) {
                                // cost += sla.listPrice;
                            } else {
                                slas = false;
                            }
                        }
                    });
                }
            } else {
                let logisticInfo = this.getStoreLogisticInfo(store) //store._logisticInfo

                logisticInfo.forEach((li) => {
                    if (li.slas && li.slas.length > 0) {
                        let liSla = li.slas.find((sla) => {
                            return sla.deliveryChannel === 'delivery';
                        })

                        if (liSla) {
                            // cost += liSla.price;
                        }
                    } else {
                        slas = false;
                    }
                })
            }
            // }
        });

        return slas;
    }

    getStoreLogisticInfo = (store) => {
        const { checkout } = this.state;

        let externalCart = checkout.externalCart.map((el, i) => {
            el.shippingData = JSON.parse(el.externalJsonShipping);
            return el;
        })

        let logisticInfo = externalCart[0].shippingData ? externalCart[0].shippingData.logisticsInfo : externalCart[0].logisticsInfo;

        if (!logisticInfo)
            logisticInfo = externalCart[0].shippingData.shippingData.logisticsInfo;

        let productsIdx = store.products.map(el => el.requestIndex);

        let storeLogisticInfo = logisticInfo.filter(el => productsIdx.indexOf(el.itemIndex) > -1);

        console.log(store);

        return storeLogisticInfo;
    }

    renderShippingProducts = (store) => {
        const { selectedStore, checkout } = this.state;

        checkout.externalCart.forEach((el, i) => {
            let cart = JSON.parse(el.externalJsonCart);
            // if (cart.items[0].seller === store.store) {
            //     store.shippingMethods = [];

            //     if (cart.logisticsInfo == undefined) {
            //         store.shippingMethods = cart.shippingData.logisticsInfo[0].deliveryChannels
            //     } else {
            //         store.shippingMethods = cart.logisticsInfo[0].deliveryChannels
            //     }

            //     store.shippingMethods = store.shippingMethods.sort();

            store.products.forEach((i, j) => {
                let _item = cart.items.find((ci, k) => {
                    return i.id === ci.id;
                })

                if (_item) {
                    i.requestIndex = _item.requestIndex;
                }
            })
            // }
        })

        store.products.forEach((p, j) => {
            // let externalCart = checkout.externalCart;
            // externalCart.forEach((c, k) => {
            //     if (store.store === c.externalSellerId) {
            //         let cart = JSON.parse(c.externalJsonCart);
            //         let lInfo = [];

            //         if (cart.logisticsInfo == undefined)
            //             lInfo = cart.shippingData.logisticsInfo;
            //         else
            //             lInfo = cart.logisticsInfo;

            store._logisticInfo = this.getStoreLogisticInfo(store);
            store._logisticInfo.forEach((item, l) => {
                if (p.requestIndex === item.itemIndex || p.id === item.itemId) {
                    let pickupSlas = [];
                    let deliverySlas = [];
                    if (item.slas && item.slas.length > 0) {
                        pickupSlas = item.slas.filter((_el, i) => { return _el.deliveryChannel === "pickup-in-point" });
                        deliverySlas = item.slas.filter((_el, i) => { return _el.deliveryChannel === "delivery" });

                        store.availablePickup = pickupSlas.length > 0;
                        store.availableDelivery = deliverySlas.length > 0;
                        store.shippindMethodsAvailability = {
                            "pickup-in-point": pickupSlas.length > 0,
                            "delivery": deliverySlas.length > 0
                        }

                        if (!store.shippingMethod || store.shippingMethod === "") {
                            if (store.availableDelivery) {
                                store.shippingMethod = "delivery"
                            } else {
                                store.shippingMethod = "pickup-in-point"
                            }
                        }
                    } else {
                        store.shippindMethodsAvailability = {
                            "pickup-in-point": false,
                            "delivery": false
                        }
                        store.shippingMethod = "delivery"
                    }
                    if (!item.slas || item.slas.length === 0) {

                        p.outOfStock = true;
                    }
                }
            })
            //     }
            // })
        })

        let shippingMethods = "";

        let logisticInfo = store._logisticInfo;
        let deliveryDate = '';
        let pickupDate = '';
        let pickupLimitDate = '';
        let pickupSlas = [];

        if (logisticInfo && logisticInfo.length > 0) {
            logisticInfo.forEach(li => {
                pickupSlas = pickupSlas.concat(li.slas.filter((_el, i) => { return _el.deliveryChannel === "pickup-in-point" }));
                let deliverySla = li.slas.find((_el, i) => { return _el.deliveryChannel === "delivery" });

                if (deliverySla) {
                    deliveryDate = deliverySla.availableDeliveryWindows && deliverySla.availableDeliveryWindows.length > 0 ?
                        moment(deliverySla.availableDeliveryWindows[0].startDateUtc).format('dddd D [de] MMMM') : ''
                }
            });
        }

        if (pickupSlas.length > 0) {
            let pickupDates = [];
            pickupSlas.forEach(pickupoStore => {
                if (pickupoStore.availableDeliveryWindows && pickupoStore.availableDeliveryWindows.length > 0) {
                    pickupDates.push(pickupoStore.availableDeliveryWindows[0].startDateUtc);
                }
            });

            pickupDates = pickupDates.sort((a, b) => {
                return new Date(a) - new Date(b);
            });

            if (pickupDates.length > 0) {
                pickupDate = moment(pickupDates[0]).format('dddd D [de] MMMM');
                pickupLimitDate = moment(pickupDates[0]).add(2, 'days').format('dddd D [de] MMMM');
            }
        }

        let hasSlas = this.hasAvailableSlas(store);

        if (!hasSlas) {
            return (
                <React.Fragment>
                    <div className="section__stores__item__shipping__warning">
                        <IconWarning></IconWarning>
                        La tienda no cuenta con métodos de envío para tu dirección seleccionada.
                    </div>
                </React.Fragment>
            );
        }

        if (store && store.shippingMethods.length > 0) {
            shippingMethods = (
                <React.Fragment>
                    <div className="section__stores__item__shipping__methods">
                        {
                            store.shippingMethods.map((sm, j) => {
                                return (
                                    <React.Fragment key={store.store + sm.id}>
                                        {
                                            (store.shippindMethodsAvailability && store.shippindMethodsAvailability[sm.id]) &&
                                            <div onClick={(e) => { this.onChangeShippingMethod(sm.id, store, store.shippindMethodsAvailability[sm.id]) }}
                                                className={'section__stores__item__shipping__methods__item ' + (store.shippingMethod === sm.id ? ' selected' : '') + (!store.shippindMethodsAvailability[sm.id] ? ' disabled' : '')}>
                                                {
                                                    this.getStoreShippingCost(store, sm.id)
                                                }
                                                {
                                                    (sm.id === 'delivery') && <img className="shipping__methods__item__icon" src="/delivery.png" />
                                                }
                                                {
                                                    (sm.id === 'pickup-in-point') && <img className="shipping__methods__item__icon" src="/store.png" />
                                                }
                                                <div>
                                                    <div className="section__stores__item__shipping__methods__item__name">{this.getShippingName(sm.id)}</div>
                                                    {
                                                        (sm.id === 'delivery') &&
                                                        <div className="section__stores__item__shipping__methods__item__description">
                                                            Disponible desde el {deliveryDate}
                                                        </div>
                                                    }
                                                    {
                                                        (sm.id === 'pickup-in-point') &&
                                                        <div className="section__stores__item__shipping__methods__item__description">
                                                            Recojo disponible desde el {pickupDate}
                                                        </div>
                                                    }
                                                </div>
                                                <div className="w-100"></div>
                                                {(store.shippingMethod === 'delivery' && sm.id === 'delivery') &&
                                                    <div id={'container-' + store.store} className="section__stores__item__shipping__methods__item__picker ">
                                                        {this.renderProductDelivery(store)}
                                                    </div>
                                                }
                                                {(store.shippingMethod === 'pickup-in-point' && sm.id === 'pickup-in-point') && (
                                                    <div id={'container-' + store.store} className="section__stores__item__shipping__methods__item__picker ">
                                                        <div className="section__stores__item__shipping__methods__item__picker__limit">
                                                            <IconHelp></IconHelp>
                                                            <span>
                                                                Tienes hasta el {pickupLimitDate} para para recoger tu pedido; luego de este plazo, el pedido se anulará.
                                                            </span>
                                                        </div>
                                                        {
                                                            (store.selectedSla) &&
                                                            <React.Fragment>
                                                                {this.renderProductPickup(store)}
                                                                {this.renderAlternativePickupPerson(store)}
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            (!store.selectedSla) &&
                                                            <React.Fragment>
                                                                {this.showOpenStoresModal(store)}
                                                                {this.renderAlternativePickupPerson(store)}
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </React.Fragment>

                                )
                            })
                        }
                    </div>

                </React.Fragment>
            )
        }

        return (<React.Fragment>{shippingMethods}</React.Fragment>);
    }

    getStoreShippingData = (storeId) => {
        const { checkout } = this.state;

        let externalCart = checkout.externalCart.map((el, i) => {
            el.shippingData = JSON.parse(el.externalJsonCart);
            return el;
        })

        let itemSD = externalCart.filter((store, idx) => {
            return store.externalSellerId == storeId;
        })

        if (itemSD && itemSD.length > 0) {
            return itemSD[0];
        }
        return null;
    }

    renderProductDelivery = (item) => {
        const { checkout, address } = this.state;
        let slas = [];
        let sla = [];
        let deliveryWindows = [];

        if (checkout) {
            // var itemSD = this.getStoreShippingData(item.store);

            // if (!itemSD)
            //     return;

            let li = this.getStoreLogisticInfo(item); //itemSD.shippingData.shippingData ? itemSD.shippingData.shippingData.logisticsInfo : itemSD.shippingData.logisticsInfo;
            li.forEach((el, i) => {
                item.products.forEach((p, j) => {
                    if (el.itemIndex == p.requestIndex || el.id === p.itemId) {
                        slas.push(el);
                    }
                })
            })

            console.log(li)
        }

        sla = slas[0].slas.filter((s, i) => {
            return s.deliveryChannel == "delivery";
        });

        if (sla.length > 0) {
            sla = sla[0];
            sla.availableDeliveryWindows.forEach((dw, i) => {
                deliveryWindows.push(new Date(dw.startDateUtc));
            })
        }

        let template = (
            <React.Fragment>
                {(deliveryWindows.length > 0) && (
                    <React.Fragment>
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="form-group date-picker">
                                    <label>Fecha</label>
                                    <DatePicker
                                        id={'datepicker-' + item.store}
                                        portalId="root-portal"
                                        className="store-input"
                                        selected={typeof item.selectedDeliveryDate === "string" ? new Date(item.selectedDeliveryDate) : item.selectedDeliveryDate}
                                        placeholderText={'Seleccionar fecha de entrega'}
                                        locale="es"
                                        dateFormat="dd/MM/yyyy"
                                        includeDates={deliveryWindows}
                                        onChange={(e) => { this.handleChangeDeliveryDate(e, item, sla.availableDeliveryWindows) }}
                                    />
                                    <label className="form-icon p-0" htmlFor={'datepicker-' + item.store} role="button">
                                        <i className="far fa-calendar-alt  main-text"></i>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                {(item.deliveryScheadule && item.deliveryScheadule.length > 0) && (
                                    <React.Fragment>
                                        <div className="form-group mb-0">
                                            <label>Horario de env&iacute;o</label>
                                            {
                                                (item.deliveryScheadule.length === 1) &&
                                                <div>
                                                    <p className="m-0">
                                                        {/* Tu pedido llegará entre el rango de horas.
                                                        <br /> */}
                                                        <b>{item.deliveryScheadule[0].label}</b>
                                                    </p>
                                                </div>
                                            }
                                            {
                                                (item.deliveryScheadule.length > 1) &&
                                                <select className="form-control store-input" name="deliveryTime" value={item.deliveryDeliveryWindowId} onChange={(e) => this.handleChangeDeliveryTime(e, item)}>
                                                    <option value="0">Seleccionar</option>
                                                    {item.deliveryScheadule.map((hour) => {
                                                        return (
                                                            <option key={hour.id} value={hour.id}>{hour.label}</option>
                                                        )
                                                    })}
                                                </select>
                                            }
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {(deliveryWindows.length == 0 && sla.shippingEstimate) && (
                    <div className="card shipping-days">
                        <div className="card-body">
                            <i className="far fa-dot-circle mr-2 text-main"></i>
                            {this.formatEstimate(sla.shippingEstimate)}
                        </div>
                    </div>
                )}
            </React.Fragment>
        );

        return <React.Fragment>{template}</React.Fragment>
    }

    formatEstimate = (e) => {
        let estimate = "";
        if (!e) return "";

        if (e.indexOf("bd") > -1) {
            estimate = e.substring(0, e.indexOf("bd")) + " días hábiles";
        } else if (e.indexOf("d") > -1) {
            estimate = e.substring(0, e.indexOf("d")) + " días";
        } else if (e.indexOf("h") > -1) {
            estimate = e.substring(0, e.indexOf("h")) + " horas";
        }

        return estimate;
    }

    showOpenStoresModal = (store) => {
        let slas = this.getStoreSlas(store);

        slas = slas.filter((el) => { return el.deliveryChannel !== 'delivery' })

        return (
            <React.Fragment>
                <div className="form-group">
                    <button className="btn btn-main" onClick={(e) => { this.showStoresList(store) }}>Elegir tienda</button>
                </div>
            </React.Fragment>
        )
    }

    renderProductPickup = (store) => {
        let template = "";
        if (store.selectedSla) {
            template = (
                <React.Fragment>
                    <div className="pickup-store">
                        <div className="store-address-card">
                            <div className="store-address-card__info">
                                <p className="store-address-card__info__name">
                                    Tienda: {store.selectedSla.pickupStoreInfo.friendlyName}
                                </p>
                                <p>{store.selectedSla.pickupStoreInfo.address.street} {store.selectedSla.pickupStoreInfo.address.number} {store.selectedSla.pickupStoreInfo.address.complement}</p>
                            </div>
                            <button type="button" className="btn btn-outline-main" onClick={(e) => { this.showStoresList(store) }}>Cambiar tienda</button>
                        </div>
                        {store.selectedDates.length > 0 &&
                            <div className="row justify-content-between">
                                <div className="col-md-6 col-12">
                                    <div className="form-group date-picker">
                                        <label>Fecha de recojo</label>
                                        <DatePicker
                                            locale="es"
                                            dateFormat="dd/MM/yyyy"
                                            selected={store.selectedDate ? new Date(store.selectedDate) : null}
                                            includeDates={store.selectedDates}
                                            placeholderText="Seleccionar fecha de recojo"
                                            onChange={(e) => { this.handleChangePickupDate(e, store) }}
                                        />
                                        <i className="far fa-calendar-alt form-icon main-text"></i>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    {store.scheadule.length > 0 && (
                                        <React.Fragment>
                                            <div className="form-group mb-0">
                                                <label>Horario de recojo</label>
                                                {
                                                    (store.scheadule.length === 1) &&
                                                    <div>
                                                        <p className="m-0">
                                                            <b>{store.scheadule[0].label}</b>
                                                        </p>
                                                    </div>
                                                }
                                                {
                                                    (store.scheadule.length > 1) &&
                                                    <select className="form-control" name="deliveryTime" value={store.selectedScheadule} onChange={(e) => this.handleChangePickupTime(e, store)}>
                                                        <option value="0">Seleccionar</option>
                                                        {store.scheadule.map((hour) => {
                                                            return (
                                                                <option key={hour.id} value={hour.id}>{hour.label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                }
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                </React.Fragment>
            )
        }

        return <React.Fragment>{template}</React.Fragment>;
    }

    handleChangeDeliveryDate = (date, item, dws) => {
        const { stores } = this.state;

        let s = stores;
        let stringDate = "";
        let deliveryWindows = []
        let hours = [];

        if (date) {
            let datePicker = $("#container-" + item.store).find(".date-picker");
            datePicker.removeClass("invalid");
            datePicker.find("input").removeClass("invalid");
        }

        date = new Date(date);
        stringDate = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
        deliveryWindows = dws;

        for (var i = 0; i < deliveryWindows.length; i++) {
            let startDate = new Date(deliveryWindows[i].startDateUtc);
            let endDate = new Date(deliveryWindows[i].endDateUtc);
            let sDate = startDate.getFullYear() + '-' + startDate.getMonth() + '-' + startDate.getDate();
            if (stringDate === sDate) {
                let from = startDate.getHours() + 5;
                let to = endDate.getHours() + 5;
                let price = ""
                if (deliveryWindows[i].price == 0) {
                    price = "Gratis";
                } else {
                    price = "S/" + deliveryWindows[i].price;
                }

                hours.push({
                    id: "schdl-" + i,
                    value: deliveryWindows[i].startDateUtc,
                    label: "Desde " + from + ":00 hasta " + to + ":00",
                    data: deliveryWindows[i]
                })
            }
        }

        item.selectedDeliveryDate = new Date(date);
        item.deliveryScheadule = hours;

        item.deliveryDeliveryWindow = hours[0].data;
        item.deliveryDeliveryWindowId = hours[0].id;

        stores.forEach((el, i) => {
            if (el.store === item.store)
                el = item
        });
        this.setState({ stores: s });
    }

    handleChangeDeliveryTime = (e, store) => {
        const { stores } = this.state;
        let date = e.target.value;
        let scheadule = store.deliveryScheadule;
        let _stores = stores;

        if (date && date !== "0" && date !== "") {
            $(e.target).closest('.form-control').removeClass("not-valid");
            $(e.target).removeClass("is-invalid");
        } else {
            // $(e.target).closest('.form-control').addClass("not-valid");
            // $(e.target).addClass("is-invalid");
            return;
        }

        scheadule.forEach((el, i) => {
            if (el.id == date) {
                store.deliveryDeliveryWindow = el.data;
                store.deliveryDeliveryWindowId = el.id;
            }
        });

        _stores.forEach((el, j) => {
            if (el.store === store.store) {
                el = store
            }
        });

        this.setState({ stores: _stores });
    }

    handleChangePickupDate = (date, store) => {
        const { stores } = this.state;

        let _stores = stores;
        let stringDate = "";
        let deliveryWindows = []
        let hours = [];

        date = new Date(date);
        stringDate = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
        deliveryWindows = store.selectedSla.availableDeliveryWindows;

        if (date) {
            let datePicker = $("#container-" + store.store).find(".date-picker");
            datePicker.removeClass("invalid");
            datePicker.find("input").removeClass("invalid");
        }

        for (var i = 0; i < deliveryWindows.length; i++) {
            let startDate = new Date(deliveryWindows[i].startDateUtc);
            let endDate = new Date(deliveryWindows[i].endDateUtc);
            let sDate = startDate.getFullYear() + '-' + startDate.getMonth() + '-' + startDate.getDate();
            if (stringDate === sDate) {
                let from = startDate.getHours() + 5;
                let to = endDate.getHours() + 5;
                let price = ""

                if (deliveryWindows[i].price == 0) {
                    price = "Gratis";
                } else {
                    price = "S/" + deliveryWindows[i].price;
                }

                hours.push({
                    id: "schdl-" + i,
                    value: deliveryWindows[i].startDateUtc,
                    label: "Desde " + from + ":00 hasta " + to + ":00     ", // + price,
                    data: deliveryWindows[i]
                })
            }
        }

        store.selectedDate = new Date(date);
        store.scheadule = hours;

        store.deliveryWindow = hours[0].data;
        store.selectedScheadule = hours[0].id;

        _stores.forEach((el, i) => {
            if (el.store === store.store)
                el = store
        });

        this.setState({ stores: _stores });
    }

    handleChangePickupTime = (e, store) => {
        const { stores } = this.state;
        let date = e.target.value;
        let _stores = stores;

        store.scheadule.forEach((el, i) => {
            if (el.id == date) {
                store.deliveryWindow = el.data
            }
        });

        _stores.forEach((el, j) => {
            if (el.store === store.store) {
                el = store
            }
        });


        this.setState({ stores: _stores });
    }

    showStoresList = (item) => {
        this.setState({ selectedStore: item });
        $("#storesModal").modal("show");
    }

    hideStoresList = () => {
        $("#storesModal").modal("hide");
    }

    getStoreSlas = (store) => {
        const { checkout } = this.state;

        checkout.externalCart.forEach((el, i) => {
            let cart = JSON.parse(el.externalJsonCart);
            if (cart.items[0].seller === store.store) {
                store.shippingMethods = [];

                if (cart.logisticsInfo == undefined) {
                    store.shippingMethods = cart.shippingData.logisticsInfo[0].deliveryChannels;
                } else {
                    store.shippingMethods = cart.logisticsInfo[0].deliveryChannels;
                }

                store.products.forEach((i, j) => {
                    let _item = cart.items.find((ci, k) => {
                        return i.id === ci.id;
                    })

                    if (_item) {
                        i.requestIndex = _item.requestIndex;
                        if (cart.logisticsInfo == undefined) {
                            i.logisticInfo = cart.shippingData.logisticsInfo.find((li) => {
                                return i.requestIndex === li.itemIndex || i.id === li.itemId;
                            })
                        } else {
                            i.logisticInfo = cart.logisticsInfo.find((li) => {
                                return i.requestIndex === li.itemIndex || i.id === li.itemId;
                            })
                        }

                    }
                })
            }
        })

        let slas = [];
        store.products.forEach((el, i) => {
            if (el.logisticInfo)
                el.logisticInfo.slas.forEach((s, j) => {
                    let exist = slas.find((x) => {
                        return x.id === s.id;
                    })

                    if (!exist) {
                        slas.push(s);
                    }
                })
        })
        return slas;
    }

    renderStores = () => {
        const { checkout, selectedStore } = this.state;

        if (!selectedStore)
            return "";

        let stores = "";
        let slas = [];

        if (checkout.externalCart) {

            slas = [];
            if (selectedStore)
                slas = this.getStoreSlas(selectedStore);


            stores = slas.map((store, index) => {
                console.log(store);
                if (store.pickupStoreInfo.address)
                    return (
                        <li className="list-group-item" key={'s-' + index}>
                            <div className="store-info">
                                <div className="store-info__detail">
                                    <h4>{store.pickupStoreInfo.friendlyName}</h4>
                                    <p>{store.pickupStoreInfo.address.street} {store.pickupStoreInfo.address.number} {store.pickupStoreInfo.address.complement}</p>
                                </div>
                                <button className="btn btn-outline-main" onClick={(e) => { this.selectStore(store) }}>Elegir tienda</button>
                            </div>
                        </li>
                    )
            })
        }

        slas = slas.filter((el) => { return el.deliveryChannel !== 'delivery' })

        return (
            <React.Fragment>
                {/* <div className="checkout__navigation">
                    <a role="button" onClick={this.hideStoresList}>
                        <IconBack></IconBack>
                        <span>Regresar</span>
                    </a>
                </div> */}
                <div className="card">
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <h4>Disponibles {slas.length}</h4>
                        </li>
                    </ul>
                    <ul className="list-group list-group-flush">
                        {stores}
                    </ul>
                </div>
                <div className="info">
                    <IconHelp></IconHelp>
                    Para todas las tiendas tendrás 72 horas a partir de la fecha que elegiste para recoger tu pedido. Luego de este plazo, tu pedido se anulará.
                </div>
            </React.Fragment>
        );
    }

    selectStore = (store) => {
        const { storesModal } = this.state;

        storesModal.selectedStore = store;
        storesModal.defaultLat = store.pickupStoreInfo.address.geoCoordinates[1];
        storesModal.defaultLong = store.pickupStoreInfo.address.geoCoordinates[0];

        this.setState({ storesModal: storesModal });
        this.savePickupStore();
    }

    savePickupStore = () => {
        const { stores, storesModal, selectedStore } = this.state;
        let ss = selectedStore;
        let _stores = stores;
        let selectedDates = [];

        if (storesModal.selectedStore.availableDeliveryWindows.length > 0) {
            storesModal.selectedStore.availableDeliveryWindows.forEach((dw, i) => {
                selectedDates.push(new Date(dw.startDateUtc));
            });
        }

        let store = storesModal.selectedStore;
        let storeAddress = null;
        if (store) {
            storeAddress = {
                name: store.pickupStoreInfo.friendlyName,
                addressLine1: store.pickupStoreInfo.address.street,
                addressLine2: store.pickupStoreInfo.address.number,
                department: store.pickupStoreInfo.address.state,
                province: store.pickupStoreInfo.address.city,
                district: store.pickupStoreInfo.address.neighborhood,
                postalCode: store.pickupStoreInfo.address.postalCode,
            }
        }

        _stores.forEach((el, i) => {
            if (el.store == ss.store) {
                el = ss;
                el.selectedSla = storesModal.selectedStore;
                el.selectedDates = selectedDates;
                el.selectedDate = null;
                el.scheadule = [];
                el.selectedDeliveryDate = null;
                el.selectedDeliveryWindow = null;
                el.selectedDeliverySla = null;
                el.selectedDeliveryScheadule = null;
                el.deliveryScheadule = [];
                el.address = storeAddress

                if (selectedDates && selectedDates.length > 0)
                    this.handleChangePickupDate(selectedDates[0], el);
            }
        })

        ss.selectedSla = storesModal.selectedStore;
        this.setState({ selectedStore: ss, stores: _stores });

        $("#storesModal").modal("hide");
    }

    saveShippingInfo = async (e) => {
        const { stores, checkout, address, user } = this.state;

        let shippingAvailability = true;
        stores.forEach((el, i) => {
            if (!el.shippindMethodsAvailability['delivery'] && !el.shippindMethodsAvailability['pickup-in-point']) {
                shippingAvailability = false;
            }
        });

        if (!shippingAvailability) {
            $("#shippingUnavailabeModal").modal("show");
            return;
        }

        let _stores = stores;
        let _address = address;
        let addresses = [];
        let items = [];
        let department = "";
        let province = "";
        let district = "";
        let isValid = true;
        let message = ""

        stores.forEach((el, i) => {
            let datePicker = $("#container-" + el.store).find(".date-picker");
            let deliveryWindow = $("#container-" + el.store).find("[name=deliveryTime]");
            if (datePicker.length > 0) {
                if (el.shippingMethod === "delivery") {
                    if (!el.selectedDeliveryDate) {
                        datePicker.addClass("invalid");
                        datePicker.find("input").addClass("form-control invalid");
                        isValid = false;
                    }
                } else {
                    if (!el.selectedDate) {
                        datePicker.addClass("invalid");
                        datePicker.find("input").addClass("form-control invalid");
                        isValid = false;
                    }
                }
            }

            if (deliveryWindow.length > 0) {
                if (!deliveryWindow.val() || deliveryWindow.val() === "0") {
                    deliveryWindow.closest('.form-control').addClass("invalid");
                    deliveryWindow.addClass("form-control invalid");
                    isValid = false;
                }
            }

            if (!el.selectedSla && el.shippingMethod === "pickup-in-point") {
                $("#stores-list-" + el.store).addClass("invalid");
                message = "Por favor seleccione una tienda, pare recoger el producto.";
                isValid = false;
            }
        });

        if (!isValid) {
            return;
        }

        stores.forEach((el, i) => {

            checkout.externalCart.forEach((_el, i) => {
                let cart = JSON.parse(_el.externalJsonCart);
                if (cart.items[0].seller === el.store) {
                    el.shippingMethods = [];

                    if (cart.logisticsInfo == undefined) {
                        el.shippingMethods = cart.shippingData.logisticsInfo[0].deliveryChannels;
                    } else {
                        el.shippingMethods = cart.logisticsInfo[0].deliveryChannels;
                    }

                    el.products.forEach((i, j) => {
                        let _item = cart.items.find((ci, k) => {
                            return i.id === ci.id;
                        })

                        if (_item) {
                            i.requestIndex = _item.requestIndex;

                            if (cart.logisticsInfo == undefined) {
                                i.logisticInfo = cart.shippingData.logisticsInfo.find((li) => {
                                    return i.requestIndex === li.itemIndex || i.id === li.itemId;
                                })
                            } else {
                                i.logisticInfo = cart.logisticsInfo.find((li) => {
                                    return i.requestIndex === li.itemIndex || i.id === li.itemId;
                                })
                            }
                        }
                    })
                }
            })

            el.products.forEach((p, j) => {
                let item = null;
                let address = null;
                let addressType = "";

                if (p.logisticInfo.slas.length > 0) {
                    if (el.shippingMethod === "delivery") {
                        let sla = p.logisticInfo.slas.filter((s, k) => {
                            return s.deliveryChannel === "delivery";
                        });

                        sla = sla.length > 0 ? sla[0] : null;
                        el.selectedDeliverySla = sla;

                        item = {
                            "addressId": `addressd-${i}-${j}`,
                            "productId": p.id,
                            "itemIndex": p.requestIndex || j,
                            "selectedDeliveryChannel": el.shippingMethod,
                            "selectedSLAId": el.selectedDeliverySla.id,
                            "price": el.selectedDeliverySla.price / 100,
                            "deliveryWindow": el.deliveryDeliveryWindow,
                            "sellerId": el.store,
                            "shippingEstimate": el.selectedDeliverySla.shippingEstimate,
                            "shippingEstimateDate": el.selectedDeliverySla.shippingEstimateDate,
                        }

                        address = {
                            "addressId": `addressd-${i}-${j}`,
                            "addressType": "residential",
                            "firstName": user.firstName,
                            "lastName": user.lastName,
                            "phone": user.phone,
                            "cellPhone": user.phone,
                            "addressLine": _address.addressLine,
                            "addressNumber": _address.addressNumber,
                            "reference": _address.reference,
                            "countryISO": "PER",
                            "postalCode": _address.postalCode,
                            "department": _address.department,
                            "province": _address.province,
                            "district": _address.district,
                            selectedAddressId: _address.addressId,
                        }
                    } else if (el.shippingMethod === "pickup-in-point") {
                        item = {
                            "addressId": `addressw-${i}-${j}`,
                            "productId": p.id,
                            "itemIndex": p.requestIndex || j,
                            "selectedDeliveryChannel": el.shippingMethod,
                            "selectedSLAId": el.selectedSla.id,
                            "price": el.selectedSla.price / 100,
                            "deliveryWindow": el.selectedDeliveryWindow,
                            "sellerId": el.store,
                            "storeInfo": el.selectedSla.pickupStoreInfo.address,
                            "shippingEstimateDate": el.selectedSla.shippingEstimateDate,
                            "shippingEstimate": el.selectedSla.shippingEstimate,
                        }

                        address = {
                            "addressId": `addressw-${i}-${j}`,
                            "addressType": "search",
                            "firstName": user.firstName,
                            "lastName": user.lastName,
                            "phone": user.phone,
                            "cellPhone": user.phone,
                            "storeAddressId": el.selectedSla.pickupStoreInfo.address.addressId,
                            "addressLine": el.selectedSla.pickupStoreInfo.address.street,
                            "addressNumber": el.selectedSla.pickupStoreInfo.address.number,
                            "reference": el.selectedSla.pickupStoreInfo.address.reference,
                            "countryISO": el.selectedSla.pickupStoreInfo.address.country,
                            "postalCode": el.selectedSla.pickupStoreInfo.address.postalCode,
                            "department": el.selectedSla.pickupStoreInfo.address.state,
                            "province": el.selectedSla.pickupStoreInfo.address.city,
                            "district": el.selectedSla.pickupStoreInfo.address.neighborhood,
                            "geoCoordinates": el.selectedSla.pickupStoreInfo.address.geoCoordinates,
                            "address": el.address,
                            "alternativePickupPersonFullName": el.alternativePickupPersonFullName,
                            "alternativePickupPersonIdentificationNumber": el.alternativePickupPersonIdentificationNumber,
                            "alternativePickupPersonIdentificationType": el.alternativePickupPersonIdentificationType,
                            "alternativePickupPersonPhone": el.alternativePickupPersonPhone,
                            selectedAddressId: _address.addressId,
                        }
                    }

                    addresses.push(address);
                    items.push(item);
                }
            })
        })

        let model = {
            postalCode: address.postalCode,
            items: items,
            address: addresses,
        }

        this.props.handleSubmitShipping(model, stores);

        // await shippingPut(get.draft.draftId, model, 2)

        // shipping.editing = false;
        // shipping.isValid = true;
        // this.setState({ shipping: shipping });

        // this.saveDataLayerEvent({
        // 	'event': 'virtualPage',
        // 	'pagePath': '/Checkout/IBK/3/Metodo-de-pago',
        // 	'pageName': 'Checkout - Interbank - Método de pago'
        // });
        // this.saveDataLayerStepEvent(3, 'Metodo de pago');
    }

    formatShippingDate = (store) => {
        let date = "";
        if (store.shippingMethod === "delivery") {
            if (store.deliveryDeliveryWindow) {
                return moment(store.deliveryDeliveryWindow.startDateUtc).format('dddd D [de] MMMM');
            } else if (store.selectedDeliverySla) {
                return this.formatEstimate(store.selectedDeliverySla.shippingEstimate);
            }
        } else if (store.shippingMethod === "pickup-in-point") {
            if (store.deliveryWindow) {
                return moment(store.deliveryWindow.startDateUtc).format('dddd D [de] MMMM');
            } else if (store.selectedSla) {
                return this.formatEstimate(store.selectedSla.shippingEstimate);
            }
        }
    }

    formatShippingTime = (store) => {
        let date = "";
        if (store.shippingMethod === "delivery") {
            if (store.deliveryDeliveryWindow) {
                return moment(store.deliveryDeliveryWindow.startDateUtc).add(5, 'hours').format('HH:mm A');
            } else if (store.selectedDeliverySla) {
                return '';
            }
        } else if (store.shippingMethod === "pickup-in-point") {
            if (store.deliveryWindow) {
                return 'Desde ' + moment(store.deliveryWindow.startDateUtc).add(5, 'hours').format('hh:mm A') + ' hasta ' + moment(store.deliveryWindow.endDateUtc).add(5, 'hours').format('hh:mm A');
            } else if (store.selectedSla) {
                return '';
            }
        }
    }

    showEstimatedDate = (date) => {
        if (date && date !== "") {
            if (date.indexOf("bd") > -1) {
                date = date.replace("bd", "");

                return "En " + date + " días hábiles";
            }

            return date;
        }

        return "";
    }

    renderShippingInformation = (store) => {
        const { address, handleEditShipping } = this.state;

        let shippingInfo = (
            <div className="shipping-summary">
                {
                    (store.shippingMethod === 'pickup-in-point') &&
                    <React.Fragment>
                        <div className="text-right">
                            <button className="btn btn-edit btn-outline-main" onClick={(e) => { handleEditShipping() }}>Editar</button>
                        </div>
                        <h4>Recojo en la tienda</h4>
                        <p>{store.selectedSla.pickupStoreInfo.friendlyName}, {store.selectedSla.pickupStoreInfo.address.street} {store.selectedSla.pickupStoreInfo.address.number} {store.selectedSla.pickupStoreInfo.address.complement}</p>
                        <h4 className="mt-3">Fecha de recojo</h4>
                        <p>
                            {this.formatShippingDate(store)}
                        </p>
                    </React.Fragment>
                }
                {
                    (store.shippingMethod === 'delivery') &&
                    <React.Fragment>
                        <div className="text-right">
                            <button className="btn btn-edit btn-outline-main" onClick={(e) => { handleEditShipping() }}>Editar</button>
                        </div>
                        <h4>Entrega a domicilio a</h4>
                        <p>
                            {address.addressLine} {address.addressNumber}, {address.district}, {address.province}, {address.department}
                        </p>
                        <h4 className="mt-3">Fecha de entrega</h4>
                        <p>
                            {this.formatShippingDate(store)}
                        </p>
                    </React.Fragment>
                }
            </div>
        );

        return shippingInfo;
    }

    deleteCartItem = (product) => {
        const { handleDeleteCartItem } = this.props;

        handleDeleteCartItem(product);
    }

    getProductAvailability = (store, product) => {
        const { checkout } = this.state;

        let externalCart = checkout.externalCart;
        let availability = "";

        if (externalCart && externalCart.length > 0) {
            externalCart.forEach((el, i) => {
                let cart = JSON.parse(el.externalJsonShipping);

                if (!cart)
                    cart = JSON.parse(el.externalJsonCart);

                if (cart) {
                    let messages = cart.messages;
                    let items = cart.items;
                    items.forEach((p, j) => {
                        let hasMessage = messages.find((m) => {
                            return m.fields.skuName === p.skuName || m.fields.itemIndex == p.requestIndex;
                        });

                        if (product.id === p.id && hasMessage && hasMessage.code === 'cannotBeDelivered') {
                            availability = (
                                <React.Fragment>
                                    <div className="w-100"></div>
                                    <div className="unavailable-product-message">
                                        Este producto no se encuentra disponible para la dirección de envío. <a role="button" onClick={(e) => { this.deleteCartItem(p); }}>Eliminar</a>.
                                    </div>
                                </React.Fragment>
                            )
                        }
                    })
                }
            })
        }

        return availability;
    }

    handleChangeValue = (field, event) => {
        const { alternativePickupPersonIdentificationType } = this.state;
        let value = event.target.value

        if (field === 'alternativePickupPersonPhone' || (field === 'alternativePickupPersonIdentificationNumber' && alternativePickupPersonIdentificationType === '1')) {
            var re = /^[0-9]*$/;
            if (!re.test(value))
                return;
        }

        if (field === 'alternativePickupPersonFullName') {
            let reLetters = /^[a-zA-Z\s]*$/;
            if (!reLetters.test(value))
                return;
        }

        if (!value || value === "") {
            this.toggleFieldError(field, true)
        } else {
            this.toggleFieldError(field, false)
        }

        this.setState({
            [field]: value
        });
    }

    setDocumentNumberLength = (event) => {
        let value = event.target.value
        let length = 8;

        if (value == '2') {
            length = 9;
        }

        this.setState({
            documentNumberLength: length
        })
    }

    validState = () => {
        let state = this.state;
        let valid = true;
        let errors = {};
        let requiredFields = ["alternativePickupPersonFullName", "alternativePickupPersonIdentificationNumber", "alternativePickupPersonIdentificationType", "alternativePickupPersonPhone"];

        for (let field in state) {
            if (requiredFields.indexOf(field) > -1 && (!state[field] || state[field] === "")) {
                errors[field] = true;
                valid = false;
            }
            if (field === 'alternativePickupPersonPhone') {
                if (state[field].length > 11 || state[field].length < 9) {
                    errors[field] = true;
                    valid = false;
                }
            }
            if (field === 'alternativePickupPersonIdentificationNumber') {
                if (state[field].length !== state.documentNumberLength) {
                    errors[field] = true;
                    valid = false;
                }
            }
        }

        this.setState({ errors });
        return valid;
    }

    toggleFieldError = (name, value) => {
        const { errors } = this.state;
        let _errors = errors;

        _errors[name] = value;
        this.setState({ errors: _errors });
    }

    saveShipping = () => {
        const { stores, checkout, address, user } = this.state;

        let shippingAvailability = true;

        stores.forEach((el, i) => {
            if (!el.shippindMethodsAvailability['delivery'] && !el.shippindMethodsAvailability['pickup-in-point']) {
                shippingAvailability = false;
            }
        });

        // if (!shippingAvailability) {
        //     $("#shippingUnavailabeModal").modal("show");
        //     return;
        // }

        let _stores = stores;
        let _address = address;
        let addresses = [];
        let items = [];
        let department = "";
        let province = "";
        let district = "";
        let isValid = true;
        let message = ""

        stores.forEach((el, i) => {
            let datePicker = $("#container-" + el.store).find(".date-picker");
            let deliveryWindow = $("#container-" + el.store).find("[name=deliveryTime]");
            if (datePicker.length > 0) {
                if (el.shippingMethod === "delivery") {
                    if (!el.selectedDeliveryDate) {
                        datePicker.addClass("invalid");
                        datePicker.find("input").addClass("form-control invalid");
                        isValid = false;
                    }
                } else {
                    if (!el.selectedDate) {
                        datePicker.addClass("invalid");
                        datePicker.find("input").addClass("form-control invalid");
                        isValid = false;
                    }
                }
            }

            if (deliveryWindow.length > 0) {
                if (!deliveryWindow.val() || deliveryWindow.val() === "0") {
                    deliveryWindow.closest('.form-control').addClass("invalid");
                    deliveryWindow.addClass("form-control invalid");
                    isValid = false;
                }
            }

            if (!el.selectedSla && el.shippingMethod === "pickup-in-point") {
                $("#stores-list-" + el.store).addClass("invalid");
                message = "Por favor seleccione una tienda, pare recoger el producto.";
                isValid = false;
            }
        });

        // if (!isValid) {
        //     return;
        // }

        stores.forEach((el, i) => {

            checkout.externalCart.forEach((_el, i) => {
                let cart = JSON.parse(_el.externalJsonCart);
                if (cart.items[0].seller === el.store) {
                    el.shippingMethods = [];

                    if (cart.logisticsInfo == undefined) {
                        el.shippingMethods = cart.shippingData.logisticsInfo[0].deliveryChannels;
                    } else {
                        el.shippingMethods = cart.logisticsInfo[0].deliveryChannels;
                    }

                    el.products.forEach((i, j) => {
                        let _item = cart.items.find((ci, k) => {
                            return i.id === ci.id;
                        })

                        if (_item) {
                            i.requestIndex = _item.requestIndex;

                            if (cart.logisticsInfo == undefined) {
                                i.logisticInfo = cart.shippingData.logisticsInfo.find((li) => {
                                    return i.requestIndex === li.itemIndex || i.id === li.itemId;
                                })
                            } else {
                                i.logisticInfo = cart.logisticsInfo.find((li) => {
                                    return i.requestIndex === li.itemIndex || i.id === li.itemId;
                                })
                            }
                        }
                    })
                }
            })

            el.products.forEach((p, j) => {
                let item = null;
                let address = null;
                let addressType = "";

                if (p.logisticInfo.slas.length > 0) {
                    if (el.shippingMethod === "delivery") {
                        let sla = p.logisticInfo.slas.filter((s, k) => {
                            return s.deliveryChannel === "delivery";
                        });

                        sla = sla.length > 0 ? sla[0] : null;
                        el.selectedDeliverySla = sla;

                        item = {
                            "addressId": `addressd-${i}-${j}`,
                            "productId": p.id,
                            "itemIndex": p.requestIndex || j,
                            "selectedDeliveryChannel": el.shippingMethod,
                            "selectedSLAId": el.selectedDeliverySla.id,
                            "price": el.selectedDeliverySla.price / 100,
                            "deliveryWindow": el.deliveryDeliveryWindow,
                            "sellerId": el.store,
                            "shippingEstimate": el.selectedDeliverySla.shippingEstimate,
                            "shippingEstimateDate": el.selectedDeliverySla.shippingEstimateDate,
                        }

                        address = {
                            "addressId": `addressd-${i}-${j}`,
                            "addressType": "residential",
                            "firstName": user.firstName,
                            "lastName": user.lastName,
                            "phone": user.phone,
                            "cellPhone": user.phone,
                            "addressLine": _address.addressLine,
                            "addressNumber": _address.addressNumber,
                            "reference": _address.reference,
                            "countryISO": "PER",
                            "postalCode": _address.postalCode,
                            "department": _address.department,
                            "province": _address.province,
                            "district": _address.district,
                            selectedAddressId: _address.addressId,
                        }
                    } else if (el.shippingMethod === "pickup-in-point") {
                        item = {
                            "addressId": `addressw-${i}-${j}`,
                            "productId": p.id,
                            "itemIndex": p.requestIndex || j,
                            "selectedDeliveryChannel": el.shippingMethod,
                            "selectedSLAId": el.selectedSla.id,
                            "price": el.selectedSla.price / 100,
                            "deliveryWindow": el.selectedDeliveryWindow,
                            "sellerId": el.store,
                            "storeInfo": el.selectedSla.pickupStoreInfo.address,
                            "shippingEstimateDate": el.selectedSla.shippingEstimateDate,
                            "shippingEstimate": el.selectedSla.shippingEstimate,
                        }

                        address = {
                            "addressId": `addressw-${i}-${j}`,
                            "addressType": "search",
                            "firstName": user.firstName,
                            "lastName": user.lastName,
                            "phone": user.phone,
                            "cellPhone": user.phone,
                            "storeAddressId": el.selectedSla.pickupStoreInfo.address.addressId,
                            "addressLine": el.selectedSla.pickupStoreInfo.address.street,
                            "addressNumber": el.selectedSla.pickupStoreInfo.address.number,
                            "reference": el.selectedSla.pickupStoreInfo.address.reference,
                            "countryISO": el.selectedSla.pickupStoreInfo.address.country,
                            "postalCode": el.selectedSla.pickupStoreInfo.address.postalCode,
                            "department": el.selectedSla.pickupStoreInfo.address.state,
                            "province": el.selectedSla.pickupStoreInfo.address.city,
                            "district": el.selectedSla.pickupStoreInfo.address.neighborhood,
                            "geoCoordinates": el.selectedSla.pickupStoreInfo.address.geoCoordinates,
                            "address": el.address,
                            "alternativePickupPersonFullName": el.alternativePickupPersonFullName,
                            "alternativePickupPersonIdentificationNumber": el.alternativePickupPersonIdentificationNumber,
                            "alternativePickupPersonIdentificationType": el.alternativePickupPersonIdentificationType,
                            "alternativePickupPersonPhone": el.alternativePickupPersonPhone,
                            selectedAddressId: _address.addressId,
                        }
                    }

                    addresses.push(address);
                    items.push(item);
                }
            })
        })

        let model = {
            postalCode: address.postalCode,
            items: items,
            address: addresses,
            shippingAvailability,
            isValid
        }

        return model;
    }

    handleSubmitShipping = async () => {
        const { stores } = this.state;
        console.log(this.state);
        let model = this.saveShipping();
        let checkoutId = this.getCheckoutId();

        if (!model.shippingAvailability) {
            $("#shippingUnavailabeModal").modal("show");
            return;
        }

        if (!model.isValid) {
            return;
        }

        this.setState({
            submitting: true
        });

        let result = await saveShippingInfo(checkoutId, model);

        if (result.error) {
            this.setState({
                submitting: false
            });
            return;
        }

        saveDataLayerEvent({
            'event': 'virtualEvent',
            'category': 'Checkout - Metodo de entrega',
            'action': 'Seleccionar boton',
            'label': 'Continuar - Tipo de envio'
        });

        window.localStorage.setItem("__stores__", JSON.stringify(stores));
        window.localStorage.setItem("__shippingItems__", JSON.stringify(model));

        await this.loadCheckout();

        let totalIbk = await this.getIbkTotal();
        let installments = await this.getInstallments();

        let total = await this.getDiscountByBin("411111");

        let shippingInfo = stores.map((si) => {
            return {
                deliveryDeliveryWindow: si.deliveryDeliveryWindow,
                deliveryDeliveryWindowId: si.deliveryDeliveryWindowId,
                deliveryScheadule: si.deliveryScheadule,
                scheadule: si.scheadule,
                selectedDate: si.selectedDate,
                selectedDates: si.selectedDates,
                selectedDeliveryDate: si.selectedDeliveryDate,
                selectedDeliveryScheadule: si.selectedDeliveryScheadule,
                selectedDeliverySla: si.selectedDeliverySla,
                selectedDeliveryWindow: si.selectedDeliveryWindow,
                shippingMethod: si.shippingMethod,
                store: si.store
            }
        });

        let _state = { ...this.state };
        _state.checkout = null;
        _state.stores = null;

        localStorage.setItem("__totalIbk__", totalIbk);
        localStorage.setItem("__total__", total);
        localStorage.setItem("__installments__", JSON.stringify(installments));
        localStorage.setItem("__shippingState__", JSON.stringify(_state));
        localStorage.setItem("__shippingInfo__", JSON.stringify(shippingInfo));

        const urlParams = new URLSearchParams(window.location.search);
        let version = urlParams.get('version');

        if (version && version !== '')
            version = "version=" + version;

        window.location.href = `/${this.getCheckoutId()}/payment?${version}`;
    }

    getDiscountByBin = async (bin) => {
        const { checkout } = this.state;

        let discountModel = {
            bin: bin,
            installments: 1,
            referenceValue: checkout.total,
            paymentSystem: 1,
            customerId: null
        };

        let discount = await getDiscount(checkout.draftId, discountModel);
        let total = 0;
        if (discount.data) {
            total = discount.data.total;
        }

        return total;
    }

    getIbkTotal = async () => {
        let ibkTotal = await this.getDiscountByBin("998422");
        return ibkTotal;
    }

    getInstallments = async () => {
        let installmentsResponse = await getInstallments(this.getCheckoutId());

        if (installmentsResponse.error)
            return [];

        return installmentsResponse.data
    }

    render() {
        const { stores, showPrices, errors, documentNumberLength, selectedStore, checkout, user, address, submitting } = this.state;
        const { currentForm, completed, loaders } = this.props;

        return (
            <div className="v3">
                <div className="header">
                    <a href="https://shopstar.pe">
                        <img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
                    </a>
                </div>
                <div className="container">
                    <div className="visible-mobile mt-4">
                        {
                            (checkout) &&
                            <Summary checkout={checkout}></Summary>
                        }
                    </div>
                    <div className="stepper">
                        <div className="stepper__item active completed">
                            <span>1</span>
                            <div className="name">Datos de cliente</div>
                        </div>
                        <div className="stepper__item active">
                            <span>2</span>
                            <div className="name">Forma de entrega</div>
                        </div>
                        <div className="stepper__item">
                            <span>3</span>
                            <div className="name">Forma de pago</div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="section">
                                <h3 className="section__title">
                                    Datos de cliente
                                    <a href={`/${this.getCheckoutId()}/info`}>Editar</a>
                                </h3>
                                {
                                    (user) &&
                                    <React.Fragment>
                                        <p>
                                            <span>Nombre:</span>
                                            <span>{user.firstName}</span>
                                        </p>
                                        <p>
                                            <span>Apellido:</span>
                                            <span>{user.lastName}</span>
                                        </p>
                                        <p>
                                            <span>Celular:</span>
                                            <span>{user.phone}</span>
                                        </p>
                                        <p>
                                            <span>Correo:</span>
                                            <span>{user.email}</span>
                                        </p>
                                    </React.Fragment>
                                }
                                <div className="section__separator"></div>
                                <h3 className="section__title">Tu dirección</h3>
                                <div className="section__address">
                                    {
                                        (address) &&
                                        <React.Fragment>
                                            <p>
                                                Dirección: <b>{address.addressLine} {address.addressNumber}, {address.district}, {address.province}, {address.department}</b>
                                            </p>
                                            <p className="mb-0">
                                                Referencia: <b>{address.reference}</b>
                                            </p>
                                            <a href={`/${this.getCheckoutId()}/info`}>Cambiar</a>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className="section__info bg">
                                    <IconHelp></IconHelp>
                                    Usamos tu dirección como punto de envío de productos y comprobantes de pagos.
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="section">
                                <h3 className="section__title">Forma de entrega</h3>
                                <div className="section__stores">
                                    <div className="section__stores__item">
                                        {
                                            stores.map((store, i) => {
                                                return (
                                                    <React.Fragment key={'store-' + i}>
                                                        <div className="section__stores__item__products">
                                                            <div className="section__stores__item__title">
                                                                Productos {(store.storeName && store.storeName !== '') ? store.storeName : store.storeInfo.name}
                                                            </div>
                                                            {/* <div className="section__stores__item__subtitle">
                                                                <span>{store.products.length} producto{store.products.length > 1 ? 's' : ''}</span>
                                                            </div> */}
                                                            {
                                                                store.products.map((product, j) => {
                                                                    return (
                                                                        <div className="section__stores__item__products__item" key={'product-' + j}>
                                                                            <div className="section__stores__item__products__item__image">
                                                                                <img src={product.productInfo.productImage} alt="" />
                                                                            </div>
                                                                            <div className="section__stores__item__products__item__info">
                                                                                <div className="name">{product.productInfo.productName}</div>
                                                                                {
                                                                                    (product.sellingPrice > 0 && showPrices) &&
                                                                                    <React.Fragment>
                                                                                        <div className="quantity">
                                                                                            Cantidad {product.productInfo.quantity}
                                                                                        </div>
                                                                                        <div className="price">
                                                                                            {
                                                                                                (product.listPrice > product.sellingPrice) &&
                                                                                                <span className="base">{this.formatMoney(product.listPrice / 100)}</span>
                                                                                            }
                                                                                            <span className="special">{this.formatMoney(product.sellingPrice / 100)}</span>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                }
                                                                            </div>
                                                                            <React.Fragment>
                                                                                {/* {this.getProductAvailability(store, product)} */}
                                                                            </React.Fragment>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <div className="section__stores__item__shipping">
                                                            <div className="section__stores__item__title">
                                                                Forma de entrega seleccionada
                                                            </div>
                                                            {this.renderShippingProducts(store)}
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="section__form">
                                    <div className="form-group">
                                        <button disabled={submitting} type="button" className="btn btn-main btn-submit w-100" onClick={this.handleSubmitShipping}>Ir a forma de pago</button>
                                    </div>
                                </div>
                                <div className="section__separator"></div>
                                <h3 className="section__title">Forma de pago</h3>
                                <h4 className="section__subtitle">Esperando que se complete la información.</h4>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="hidden-mobile">
                                {
                                    (checkout) &&
                                    <Summary checkout={checkout}></Summary>
                                }
                            </div>
                            {/* <div className="section mt-3">
                                <div className="section__title">Información guardada</div>
                            </div>
                            {
                                (user) &&
                                <User user={user} checkoutId={this.getCheckoutId()}></User>
                            } */}
                        </div>
                    </div>
                </div>
                <div className="container">
                    <Contact></Contact>
                </div>

                <div className="modal fade pickup-points-modal-2" id="storesModal" role="dialog" aria-hidden="true" data-backdrop="static">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                Elige una tienda disponible
                                <a className="close" role="button" onClick={(e) => { this.toggleModal("#storesModal", "hide") }}>
                                    <IconClose></IconClose>
                                </a>
                            </div>
                            <div className="modal-body">
                                {this.renderStores()}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade payment-modal" id="shippingUnavailabeModal" role="dialog" aria-labelledby="milesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <i className="far fa-times-circle fa-2x mb-3"></i>
                                <h4>Las tiendas de las que deseas comprar no tienen habilitadas opciones de env&iacute;o y recojo para la direcci&oacute;n ingresada</h4>
                                {/* <p className="text-center">
                                    Por favor intentalo con una nueva direcci&oacute;n
                                </p> */}
                                <button className="btn btn-link" type="button" data-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade alternative-pickup-person-modal pr-0" id="alternativePickupPersonModal" role="dialog" aria-labelledby="milesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header visible-mobile">
                                <a className="close" role="button" onClick={(e) => { this.toggleModal("#alternativePickupPersonModal", "hide") }}>
                                    <IconClose></IconClose>
                                </a>
                            </div>
                            <div className="modal-body">
                                <div className="close-container">
                                    <a className="close" role="button" onClick={(e) => { this.toggleModal("#alternativePickupPersonModal", "hide") }}>
                                        <IconClose></IconClose>
                                    </a>
                                </div>
                                <h3>Datos de la persona que autorizas a recoger tu compra</h3>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className={'form-group ' + (errors.alternativePickupPersonFullName ? 'invalid' : '')}>
                                            <label>Nombre completo</label>
                                            <input type="text" className="form-control"
                                                placeholder="Ingresa el nombre y apellido"
                                                value={this.state.alternativePickupPersonFullName}
                                                onChange={(e) => this.handleChangeValue('alternativePickupPersonFullName', e)} />
                                            <span className="input-error">
                                                {
                                                    errors.alternativePickupPersonFullName &&
                                                    <React.Fragment>Campo obligatorio.</React.Fragment>
                                                }
                                            </span>
                                        </div>
                                        <div className={'form-group ' + (errors.alternativePickupPersonPhone ? 'invalid' : '')}>
                                            <label>Celular</label>
                                            <input type="text" className="form-control"
                                                placeholder="Ingresa el celular"
                                                maxLength="11"
                                                value={this.state.alternativePickupPersonPhone}
                                                onChange={(e) => this.handleChangeValue('alternativePickupPersonPhone', e)} />
                                            <span className="input-error">
                                                {
                                                    (errors.alternativePickupPersonPhone && this.state.alternativePickupPersonPhone.length > 0) &&
                                                    <React.Fragment>Ingresa un celular v&aacute;lido.</React.Fragment>
                                                }
                                                {
                                                    (errors.alternativePickupPersonPhone && (!this.state.alternativePickupPersonPhone || this.state.alternativePickupPersonPhone.length === 0)) &&
                                                    <React.Fragment>Campo obligatorio.</React.Fragment>
                                                }
                                            </span>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className={'col-md-4 col-4 ' + (errors.alternativePickupPersonIdentificationType ? 'invalid' : '')}>
                                                            <label>Tipo</label>
                                                            <select className="form-control" value={this.state.alternativePickupPersonIdentificationType}
                                                                onChange={(e) => { this.handleChangeValue('alternativePickupPersonIdentificationType', e); this.setDocumentNumberLength(e) }}>
                                                                <option value="1">DNI</option>
                                                                <option value="2">CE</option>
                                                            </select>
                                                        </div>
                                                        <div className={'col-md-8 col-8 ' + (errors.alternativePickupPersonIdentificationNumber ? 'invalid' : '')}>
                                                            <label>N&uacute;mero de documento</label>
                                                            <input type="text" className="form-control"
                                                                placeholder="Ingresa el n&uacute;mero"
                                                                maxLength={documentNumberLength}
                                                                value={this.state.alternativePickupPersonIdentificationNumber}
                                                                onChange={(e) => this.handleChangeValue('alternativePickupPersonIdentificationNumber', e)} />
                                                            <span className="input-error">
                                                                {
                                                                    (errors.alternativePickupPersonIdentificationNumber && this.state.alternativePickupPersonIdentificationNumber.length > 0 && this.state.alternativePickupPersonIdentificationNumber.length !== documentNumberLength) &&
                                                                    <React.Fragment>Ingresa un documento v&aacute;lido.</React.Fragment>
                                                                }
                                                                {
                                                                    (errors.alternativePickupPersonIdentificationNumber && (!this.state.alternativePickupPersonIdentificationNumber || this.state.alternativePickupPersonIdentificationNumber.length === 0)) &&
                                                                    <React.Fragment>Campo obligatorio.</React.Fragment>
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="alert">
                                            <div className="alert-icon">
                                                <IconInfo></IconInfo>
                                            </div>
                                            <div className="alert-content">
                                                Esta persona será la única que podrá retirar tu compra.
                                            </div>
                                        </div>
                                        <div className="mt-4 visible-desktop">
                                            <button className="btn btn-main" type="button" onClick={(e) => { this.handleSaveAlternativePickupPerson(); }}>Guardar</button>
                                        </div>
                                    </div>
                                    {
                                        (selectedStore !== null) &&
                                        <React.Fragment>
                                            <div className="col-md-6 col-12">
                                                <h4>Productos</h4>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="item">
                                                            <div className="icon">
                                                                <img src="/product.png" alt="" />
                                                            </div>
                                                            <div className="item__detail">
                                                                {
                                                                    selectedStore.products.map((product, i) => {
                                                                        return (
                                                                            <React.Fragment key={'alternative-person-' + product.productId}>
                                                                                <div className="product-info">
                                                                                    <p>{product.name}</p>
                                                                                    <span>Cantidad: {product.quantity}</span>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (selectedStore.selectedSla) &&
                                                    <React.Fragment>
                                                        <h4>Punto de recojo</h4>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="item">
                                                                    <div className="icon">
                                                                        <img src="/map-marker.png" alt="" />
                                                                    </div>
                                                                    <div className="item__detail">
                                                                        <p>{selectedStore.selectedSla.pickupStoreInfo.friendlyName}</p>
                                                                        <span>{selectedStore.selectedSla.pickupStoreInfo.address.street} {selectedStore.selectedSla.pickupStoreInfo.address.number} {selectedStore.selectedSla.pickupStoreInfo.address.complement}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h4>Reserva de producto</h4>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="item">
                                                                    <div className="icon">
                                                                        <img src="/flag.png" alt="" />
                                                                    </div>
                                                                    <div className="item__detail">
                                                                        <p>Retirar el {this.formatShippingDate(selectedStore)}</p>
                                                                        <p className="scheadule">{this.formatShippingTime(selectedStore)}</p>
                                                                        <span>Recuerda que tendrás 72 horas a partir de la fecha seleccionada para recoger el pedido; luego de este plazo, el pedido se anulará.</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                            <div className="modal-footer visible-mobile">
                                <button className="btn btn-main" type="button" onClick={(e) => { this.handleSaveAlternativePickupPerson(); }}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckoutShippingView;
