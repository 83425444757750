import React from "react";
import { ReactComponent as IconHelp } from './../../../assets/help.svg';
import * as moment from "moment";
import 'moment/locale/es';

class Shipping extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        user: null,
        checkoutId: ''
    }

    componentDidMount = () => {
        console.log("User Info");
        moment.locale('es');
    }

    maskProp = (prop) => {
        return prop;
    }

    formatDate = (date) => {
        return moment(date).format("D [de] MMMM YYYY");
    }

    formatDeliveryWindowTime = (window) => {
        if (!window.startDateUtc) return "";
        return moment(window.startDateUtc).add(5, 'hours').format('hh:mm A') + ' y ' + moment(window.endDateUtc).add(5, 'hours').format('hh:mm A')
    }

    formatEstimate = (e) => {
        let estimate = "";
        if (!e) return "";

        if (e.indexOf("bd") > -1) {
            estimate = e.substring(0, e.indexOf("bd")) + " días hábiles";
        } else if (e.indexOf("d") > -1) {
            estimate = e.substring(0, e.indexOf("d")) + " días";
        } else if (e.indexOf("h") > -1) {
            estimate = e.substring(0, e.indexOf("h")) + " horas";
        }

        return estimate;
    }

    render() {
        console.log(this.state)
        const { address, checkoutId, stores, user } = this.props;

        return (
            <React.Fragment>
                {
                    stores.map((store, i) => {
                        return (
                            <div className="saved-info" key={'store-' + i}>
                                <h4>{store.products[0].storeInfo.name}</h4>
                                <div className="saved-info__products">
                                    {
                                        store.products.map((product, j) => {
                                            return (
                                                <div className="saved-info__products__item" key={'product-' + j}>
                                                    <div className="saved-info__products__item__image">
                                                        <img src={product.imageUrl} alt="" />
                                                    </div>
                                                    <div className="saved-info__products__item__info">
                                                        <div className="name">{product.name}</div>
                                                        <div className="quantity">Cantidad: {product.quantity}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    (store.shippingMethod === 'delivery') &&
                                    (
                                        <React.Fragment>
                                            <p>
                                                <span>Fecha de envío:
                                                    &nbsp;
                                                    {
                                                        (store.deliveryDeliveryWindow) &&
                                                        this.formatDate(store.selectedDeliveryDate) + ', entre' + this.formatDeliveryWindowTime(store.deliveryDeliveryWindow)
                                                    }
                                                    {
                                                        (!store.deliveryDeliveryWindow) &&
                                                        this.formatEstimate(store.selectedDeliverySla.shippingEstimate)
                                                    }
                                                    .</span>
                                            </p>
                                            <p>
                                                <span>Dirección de envío: {address.addressLine} {address.addressNumber}, {address.district}, {address.province}, {address.department}</span>
                                            </p>
                                            <p>
                                                <span>Celular de contacto: {user.phone}</span>
                                            </p>
                                        </React.Fragment>
                                    )
                                }
                                {
                                    (store.shippingMethod === 'pickup-in-point') &&
                                    (
                                        <React.Fragment>
                                            <p>
                                                <span>Fecha de recojo:
                                                    &nbsp;
                                                    {
                                                        (store.deliveryWindow) &&
                                                        this.formatDate(store.selectedDate) + ', entre' + this.formatDeliveryWindowTime(store.deliveryWindow)
                                                    }
                                                    {
                                                        (!store.deliveryWindow) &&
                                                        this.formatEstimate(store.selectedSla.shippingEstimate)
                                                    }
                                                    .</span>
                                            </p>
                                            <p>
                                                <span>Dirección de recojo: {store.selectedSla.pickupStoreInfo.friendlyName}, {store.selectedSla.pickupStoreInfo.address.street} {store.selectedSla.pickupStoreInfo.address.number}, {store.selectedSla.pickupStoreInfo.address.state}, {store.selectedSla.pickupStoreInfo.address.city}, {store.selectedSla.pickupStoreInfo.address.neighborhood}</span>
                                            </p>
                                            <p>
                                                <span>Celular de contacto: {user.phone}</span>
                                            </p>
                                        </React.Fragment>
                                    )
                                }
                                <p>
                                    <a href={`/${checkoutId}/shipping`}>Editar m&eacute;todo de env&iacute;o</a>
                                </p>
                            </div>
                        )
                    })
                }
            </React.Fragment>
        )
    }
}

export default Shipping;