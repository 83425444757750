import {
	TRANSACTION_POST_BEGIN,
	TRANSACTION_POST_SUCCES,
	TRANSACTION_POST_FAILURE,
	AUTHORIZE_POST_BEGIN,
	AUTHORIZE_POST_SUCCES,
	AUTHORIZE_POST_FAILURE,
	SEND_EMBLUE_DATA_BEGIN,
	SEND_EMBLUE_DATA_SUCCESS,
	SEND_EMBLUE_DATA_FAILURE,
	INVALIDATE_ORDER_BEGIN,
	INVALIDATE_ORDER_SUCCESS,
	INVALIDATE_ORDER_FAILURE,
	GET_MILES_TOKEN_BEGIN,
	GET_MILES_TOKEN_SUCCESS,
	GET_MILES_TOKEN_FAILURE,
} from '../constants/ActionTypes';
import { api } from '../../../api/api';
import * as $ from 'jquery';
import { Util } from '@microsoft/applicationinsights-web';

const apiUrl = api;
const headers = {
	'Access-Control-Allow-Origin': '*',
	'Content-Type': 'application/json',
	'Accept': 'application/json',
}

function handleErrors(response) {
	return response;
}

// Transaction Post

export const transactionPost = (model) => {
	var url = `${apiUrl}transactions/v4/orders`;
	var checkoutId = model.checkOutId;

	// if (model.capture) {
	// 	url = `${apiUrl}transactions/v4/orders`;
	// }

	return dispatch => {
		dispatch(transactionPostBegin());
		return fetch(url, {
			method: 'post',
			headers: headers,
			body: JSON.stringify(model)
		})
			.then(res => res.json())
			.then(json => {

				model.payment = null;
				model.miles = null;

				var log = {
					url: url,
					checkoutId: checkoutId,
					request: model
				}

				if (json && json.orders) {
					dispatch(transactionPostSuccess(json));
					log.response = json;
				} else if (typeof json === "string") {
					dispatch(transactionPostFailure({
						status: 400,
						statusText: json
					}));
					log.response = json;
				} else if (json.status === 500) {
					dispatch(transactionPostFailure(json));
					log.response = json;
				} else {
					dispatch(transactionPostFailure({
						status: 400,
						statusText: "Algo ha ido mal",
						error: json
					}));
					log.response = json;
				}

				$("#trackEvent").attr("data", JSON.stringify(log));
				$("#trackEvent").trigger('click');
			})
	}
}

// Actions

export const transactionPostBegin = () => ({
	type: TRANSACTION_POST_BEGIN
})

export const transactionPostSuccess = (transaction) => ({
	type: TRANSACTION_POST_SUCCES,
	payload: transaction
})

export const transactionPostFailure = (error) => ({
	type: TRANSACTION_POST_FAILURE,
	payload: error
})

// Transaction Authorize

export const authorizePayments = (model) => {
	var url = `${apiUrl}transactions/v2/orders/authorize-capture`;
	var checkoutId = model.checkOutId;

	if(model.gateway === 'payu') {
		url = `${apiUrl}transactions/v3/orders/authorize`;
	}
	return dispatch => {
		dispatch(authorizePostBegin());
		return fetch(url, {
			method: 'post',
			headers: headers,
			body: JSON.stringify(model)
		})
			.then(res => res.json())
			.then(json => {

				model.payments = null;

				var log = {
					url: url,
					checkoutId: checkoutId,
					request: model
				}

				if (json && json.orderGroup) {
					dispatch(authorizePostSuccess(json));
					log.response = json;
				} else if (typeof json === "string") {
					dispatch(transactionPostFailure({
						status: 400,
						statusText: json
					}));
					log.response = json;
				} else if (json.status === 500) {
					dispatch(transactionPostFailure(json));
					log.response = json;
				}

				$("#trackEvent").attr("data", JSON.stringify(log));
				$("#trackEvent").trigger('click');
			})
	}
}

// Actions

export const authorizePostBegin = () => ({
	type: AUTHORIZE_POST_BEGIN
})

export const authorizePostSuccess = (transaction) => ({
	type: AUTHORIZE_POST_SUCCES,
	payload: transaction
})

export const authorizePostFailure = (error) => ({
	type: AUTHORIZE_POST_FAILURE,
	payload: error
})

// Send Emblue Data

export const sendEmblueData = (model) => {
	let url = `https://track.embluemail.com/contacts/event`;
	let headers = {
		'Authorization': 'Basic YTNiZjcwZGQyYTU5NDI0NzkxYTllZDEzM2ZlZjY5MjE=',
		'Content-Type': 'application/json'
	}

	return dispatch => {
		dispatch(sendEmblueDataBegin());

		return fetch(url, {
			method: 'post',
			headers: headers,
			body: JSON.stringify(model)
		})
			.then(handleErrors)
			.then(json => {
				dispatch(sendEmblueDataSuccess(json));
				return json;
			})
			.catch(error => dispatch(sendEmblueDataFailure(error)));
	}
}

// Actions

export const sendEmblueDataBegin = () => ({
	type: SEND_EMBLUE_DATA_BEGIN
})

export const sendEmblueDataSuccess = (transaction) => ({
	type: SEND_EMBLUE_DATA_SUCCESS,
	payload: transaction
})

export const sendEmblueDataFailure = (error) => ({
	type: SEND_EMBLUE_DATA_FAILURE,
	payload: error
})

// Invalidate Order

export const invalidateOrder = (model) => {
	let url = `${apiUrl}transactions/invalidate`;

	return dispatch => {
		dispatch(invalidateOrderBegin());

		return fetch(url, {
			method: 'post',
			headers: headers,
			body: JSON.stringify(model)
		})
			.then(handleErrors)
			.then(json => {
				dispatch(invalidateOrderSuccess(json));
				return json;
			})
			.catch(error => dispatch(invalidateOrderFailure(error)));
	}
}

// Actions

export const invalidateOrderBegin = () => ({
	type: INVALIDATE_ORDER_BEGIN
})

export const invalidateOrderSuccess = (transaction) => ({
	type: INVALIDATE_ORDER_SUCCESS,
	payload: transaction
})

export const invalidateOrderFailure = (error) => ({
	type: INVALIDATE_ORDER_FAILURE,
	payload: error
})

// Get Miles token

export const getMilesToken = (model) => {
	let url = `${apiUrl}miles/getToken`;

	return dispatch => {
		dispatch(getMilesTokenBegin());

		return fetch(url, {
			method: 'post',
			headers: headers,
			body: JSON.stringify({})
		})
			.then(handleErrors)
			.then(res => res.json())
			.then(json => {
				dispatch(getMilesTokenSuccess(json));
				return json;
			})
			.catch(error => dispatch(getMilesTokenFailure(error)));
	}
}

// Actions

export const getMilesTokenBegin = () => ({
	type: GET_MILES_TOKEN_BEGIN
})

export const getMilesTokenSuccess = (transaction) => ({
	type: GET_MILES_TOKEN_SUCCESS,
	payload: transaction
})

export const getMilesTokenFailure = (error) => ({
	type: GET_MILES_TOKEN_FAILURE,
	payload: error
})