import React from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
// import Autocomplete from 'react-google-autocomplete';

// import valid from 'card-validator';
import * as $ from 'jquery';
// import 'bootstrap';
import "./CheckoutV2.scss";
import {
    generateCheckoutLink,
    getCheckoutPromotion,
    startCheckout,
    validateEmail,
    getCheckout,
    updateUser,
    saveAddress,
    saveShippingInfo,
    getDiscount,
    deleteCartItem,
    getIpInfo,
    getProductInfo,
    getProductSimulationV2,
    getProductSimulationPlin
} from '../../services/Service';
import Navigation from './components/navigation/Navigation';
import EmailForm from './components/email-form/EmailForm';
import UserForm from './components/user-form/UserForm';
import AddressForm from './components/address-form/AddressForm';
import Summary from './components/summary/Summary';
import Stores from './components/stores/Stores';
import PaymentForm from './components/payment/PaymentForm';
import Contact from './components/contact/Contact';

// import { DISTRICTS, PROVINCES, DEPARTMENTS } from './../../data/ubigeos/ubigeos';
// import { IBK_BINES } from './../../data/bines/bines';
import { KEYS } from './../../constants/index';
// import { Kushki } from '@kushki/js';

class CheckoutV2View extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        loadCompleted: false,
        start: null,
        checkout: null,
        ibkPrices: null,
        plinPrices: null,
        totalIbk: 0,
        totalCheckout: 0,
        email: "",
        identificationNumber: "",
        user: null,
        address: null,
        shippingItems: null,
        shipping: null,
        gateway: null,
        form: 'email',
        lastForm: '',
        completedForm: {
            user: false,
            address: false,
            shipping: false
        },
        stores: [],
        loaders: {
            start: false,
            email: false,
            user: false,
            address: false,
            shipping: false,
            payment: false,
        },
        miles: null,
        promo: null,
        disabledPromo: false,
        enableBenefits: false,
        dataLayerEventItems: [],
        promoInfo: null,
        error: ""
    }

    toggleLoader = (name, value) => {
        const { loaders } = this.state;
        let _loaders = loaders;

        _loaders[name] = value;
        this.setState({ loaders: _loaders });
    }

    componentDidMount = async () => {
        await this.getPromoInfo();
        await this.getIpInfo();
    }

    getIpInfo = async () => {
        let result = await getIpInfo();

        let data = result.data.trim().split('\n').reduce(function (obj, pair) {
            pair = pair.split('=');
            return obj[pair[0]] = pair[1], obj;
        }, {});
        if (data && data.ip) {
            window.localStorage.setItem("__ipAddress__", data.ip);
        }
    }

    getPromoInfo = async () => {
        const { match } = this.props;

        let promoId = match.params.promotionId;

        if (!promoId) {
            this.setState({
                loadCompleted: true
            });

            return;
        }

        let promotionResult = await getCheckoutPromotion(promoId);

        if (promotionResult.status === 200) {
            let promo = promotionResult.data;
            let promoParams = JSON.parse(promo.jsonBanner);
            let disabledPromo = promoParams.disabled;

            promo.id = promoId;

            this.setState({
                promo: promo,
                disabledPromo: disabledPromo,
                loadCompleted: true
            });
        }
    }

    startCheckout = async (email, identificationNumber) => {
        const { match } = this.props;

        let id = match.params.id;
        let promoId = match.params.promotionId;
        let linkId = "00000000-0000-0000-0000-000000000000";

        this.toggleLoader('start', true);

        if (promoId) {
            let model = {
                email: email,
                linkId: promoId,
                identificationNumber: identificationNumber
            };
            let checkoutResult = await generateCheckoutLink(model)
            if (checkoutResult.status === 200) {
                id = checkoutResult.data.id;
            } else {
                $("#promoErrorModal").modal("show");
                this.toggleLoader('start', false);
                return;
            }

            linkId = promoId;
        }

        let result = await startCheckout(id, email, identificationNumber, linkId);

        this.toggleLoader('start', false);

        if (result.status === 200) {
            // let ibKModel = {
            //     bin: '411111',
            //     installments: 1,
            //     referenceValue: result.data.total,
            //     paymentSystem: 1,
            //     customerId: window.localStorage.getItem("__customerId__")
            // }

            // await getDiscount(result.data.draftId, ibKModel);

            this.setState({
                start: result.data,
                stores: result.data.items
            });
        } else if (result.status === 400) {
            $("#startErrorModal").modal("show");
            this.setState({
                error: result.data
            });
        }
    }

    loadCheckout = async () => {
        const { start } = this.state;

        let checkout = await getCheckout(start.draftId);
        if (checkout.data) {
            this.setState({
                checkout: checkout.data,
            });
        }
    }

    setMiles = (miles, type) => {
        miles.type = type;

        this.setState({ miles: miles })
    }

    roundPrice(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }

    handleSubmitEmail = async (email, identificationNumber) => {
        await this.startCheckout(email, identificationNumber);

        const { start, completedForm } = this.state;

        let items = [];
        let draftId = "";

        if (start) {
            start.items.forEach((el, i) => {
                items.push({
                    productId: el.productId,
                    quantity: el.quantity,
                    seller: el.sellerName
                });
            });

            draftId = start.draftId;
        } else {
            this.toggleLoader('email', false);
            return;
        }

        let model = {
            email: email,
            draftId: draftId,
            items: items
        }

        this.toggleLoader('email', true);
        let response = await validateEmail(model);

        if (response.status !== 200) {
            this.toggleLoader('email', false);
            return;
        }
        let gateway = {
            name: response.data.gateway,
            sessionId: response.data.sessionId
        }
        let enableBenefits = response.data.enableBenefits;

        let checkout = await getCheckout(draftId);
        if (checkout.data) {
            let form = 'user';
            if (checkout.data.customerIdentificationNumber && checkout.data.customerIdentificationNumber !== "") {
                completedForm.user = true;
                form = 'address';

                this.trackUserId(checkout.data.userSession.identificationNumber);
            }

            let cart = checkout.data.externalCart;
            let ibkPrices = [];
            let plinPrices = [];
            for (let store of cart) {
                let json = JSON.parse(store.externalJsonCart);
                if (json) {
                    let items = [];
                    for (let item of json.items) {

                        let model = {
                            productId: item.id,
                            quantity: item.quantity,
                            seller: store.externalSellerId
                        }

                        items.push(model);
                    }

                    let productResponse = await getProductSimulationV2(items);

                    if (productResponse.data) {
                        let ibkPrice = -1;

                        if (productResponse.data.items && productResponse.data.items.length > 0) {
                            json.items.forEach(item => {
                                let simulation = productResponse.data.items.find(s => s.id === item.id);

                                if (simulation) {
                                    ibkPrices.push({
                                        id: simulation.id,
                                        productId: item.productId,
                                        price: this.roundPrice(parseFloat(simulation.sellingPrice / 100)),
                                        totalIbk: this.roundPrice(parseFloat(simulation.sellingPrice / 100) * item.quantity)
                                    });
                                }
                            });
                        }
                    }
                    // Price plin
                    let productResponsePlin = await getProductSimulationPlin(items);
                    if (productResponsePlin.data) {
                        if (productResponsePlin.data.items && productResponsePlin.data.items.length > 0) {
                            json.items.forEach(item => {
                                let simulations = productResponsePlin.data.items.filter(s => s.id === item.id);

                                if (simulations) {
                                    let sum = simulations.reduce((accumulator, currentValue) => {
                                        return accumulator + currentValue.sellingPrice * currentValue.quantity;
                                    }, 0);
                                    plinPrices.push({
                                        id: item.id,
                                        productId: item.productId,
                                        price: this.roundPrice(parseFloat(sum / 100)),
                                        // totalPlin: this.roundPrice(parseFloat(simulation.sellingPrice / 100) * item.quantity)
                                    });
                                }
                            });
                        }
                    }
                }
            };

            console.log("Plin => ", plinPrices);

            this.setState({
                checkout: checkout.data,
                ibkPrices: ibkPrices,
                plinPrices: plinPrices,
                gateway: gateway,
                enableBenefits: enableBenefits,
                form: form,
                email: email,
                completedForm: completedForm,
                user: checkout.data.userSession,
                identificationNumber: identificationNumber
            });
            this.toggleLoader('email', false);

            this.saveDataLayerEvent({
                'event': 'virtualPage',
                'pagePath': '/Checkout/IBK/1/Datos-personales',
                'pageName': 'Checkout - Interbank - Datos personales'
            });

            this.saveDataLayerStepEvent(1, 'Datos Personales');
        }
    }

    handleSubmitUser = async (user) => {
        const { checkout, completedForm, lastForm } = this.state;
    
        try {
         
            this.toggleLoader('user', true);
    
          
            this.saveDataLayerEvent({
                'event': 'virtualEvent',
                'category': 'Checkout - Datos personales',
                'action': 'Seleccionar boton',
                'label': 'Continuar'
            });
    
        
            let checkoutResult = await updateUser(checkout.draftId, user);
     
            this.toggleLoader('user', false);
    
            if (checkoutResult.data) {
                let form = 'address';
                completedForm.user = true;
    
                if (lastForm !== '') {
                    form = lastForm;
                }
    
              
                this.setState({
                    checkout: checkoutResult.data,
                    form: form,
                    completedForm: completedForm,
                    user: user
                });
    
          
                this.saveDataLayerEvent({
                    'event': 'virtualPage',
                    'pagePath': '/Checkout/IBK/2/Metodo-de-entrega',
                    'pageName': 'Checkout - Interbank - Método de entrega'
                });
    
           
                this.saveDataLayerStepEvent(2, 'Metodo de entrega');
    
           
                this.trackUserId(user.identificationNumber);
            } else {
                this.setState({ errorMessage: 'Error: Could not update user data.' });
            }
        } catch (error) {
       
            this.toggleLoader('user', false);
            console.error('Error updating user:', error);
            if (error.response && error.response.status === 403) {
                return {
                    status: 403,
                    message: "No podemo procesar tus datos"
                }
            } else {
                return {
                    status: 403,
                    message: "Ocurrió un error inesperado, intente nuevamente."
                };
            }
    
        }
    };
    

    handleEditUser = () => {
        const { completedForm, form } = this.state;
        let _completed = completedForm;

        _completed.user = false;
        this.setState({
            form: 'user',
            lastForm: form,
            completedForm: _completed
        });
    }

    handleSubmitAddress = async (model, address) => {
        const { start, completedForm, lastForm } = this.state;

        this.toggleLoader('address', true);
        let result = await saveAddress(start.draftId, model);

        if (result.error)
            return;

        this.saveDataLayerEvent({
            'event': 'virtualEvent',
            'category': 'Checkout - Metodo de entrega',
            'action': 'Seleccionar boton',
            'label': 'Continuar - Direccion de envio'
        });

        let checkout = await getCheckout(start.draftId);
        this.toggleLoader('address', false);
        if (checkout.data) {
            let form = 'shipping';
            completedForm.address = true;

            if (lastForm !== '' && lastForm !== 'address') {
                form = lastForm;
            }
            this.setState({
                checkout: checkout.data,
                form: form,
                completedForm: completedForm,
                address: address
            });
        }
    }

    handleEditAddress = () => {
        const { completedForm, form } = this.state;
        let _completed = completedForm;

        _completed.address = false;
        this.setState({
            form: 'address',
            lastForm: form,
            completedForm: _completed
        });
    }

    handleSubmitShipping = async (info, stores) => {
        const { start, completedForm, lastForm, checkout } = this.state;

        this.toggleLoader('shipping', true);
        let result = await saveShippingInfo(start.draftId, info);

        if (result.error)
            return;

        this.saveDataLayerEvent({
            'event': 'virtualEvent',
            'category': 'Checkout - Metodo de entrega',
            'action': 'Seleccionar boton',
            'label': 'Continuar - Tipo de envio'
        });

        let bin = "998422";
        let discountModel = {
            bin: bin,
            installments: 1,
            referenceValue: checkout.total,
            paymentSystem: 1,
            customerId: window.localStorage.getItem("__customerId__")
        }

        let discount = await getDiscount(checkout.draftId, discountModel);

        let checkoutResult = await getCheckout(start.draftId);

        if (checkoutResult.data) {
            let totalCheckout = this.state.totalCheckout;
            if (totalCheckout === 0)
                totalCheckout = checkoutResult.data.total;

            completedForm.shipping = true;

            this.saveDataLayerEvent({
                'event': 'virtualPage',
                'pagePath': '/Checkout/IBK/3/Metodo-de-pago',
                'pageName': 'Checkout - Interbank - Método de pago'
            });
            this.saveDataLayerStepEvent(3, 'Metodo de pago');

            // let bin = "998422";
            // let discountModel = {
            //     bin: bin,
            //     installments: 1,
            //     referenceValue: checkoutResult.data.total,
            //     paymentSystem: 1,
            //     customerId: window.localStorage.getItem("__customerId__")
            // }

            // let discount = await getDiscount(checkoutResult.data.draftId, discountModel);
            // let totalIbk = 0;
            // if (discount.data) {
            //     totalIbk = discount.data.total;
            // }

            let form = 'payment';

            if (lastForm !== '' && lastForm !== 'address' && lastForm !== 'shipping') {
                form = lastForm;
            }

            this.toggleLoader('shipping', false);
            this.setState({
                checkout: checkoutResult.data,
                totalCheckout: totalCheckout,
                // totalIbk: totalIbk,
                form: form,
                completedForm: completedForm,
                stores: stores,
                shippingItems: info
            });
        }
    }

    handleEditShipping = () => {
        const { completedForm, form } = this.state;

        completedForm.shipping = false;
        this.setState({
            form: 'shipping',
            lastForm: form,
            completedForm
        })
    }

    handleSubmitPayment = async (model) => {
        console.log(model);
    }

    handleDeleteCartItem = async (product) => {
        const { checkout } = this.state;

        let model = {
            id: product.id,
            index: product.index,
            qty: 0,
            seller: product.seller
        }

        $("#deletingItemModal").modal("show");

        let result = await deleteCartItem(checkout.draftId, product.id, model);
        if (result.data) {
            this.loadCheckout();
            window.location.reload();
        } else {
            $("#deletingItemModal").modal("hide");
        }
    }

    trackUserId = (userId) => {
        let satellite = window['_satellite'];

        if (!satellite)
            return;

        satellite.track('trackUserID', { 'userID': userId });
    }

    redirectInterbankLogin = () => {
        const { match } = this.props;
        let state = this.state
        let checkout = state.checkout;

        state.checkout = null;
        let customer = window.localStorage.getItem("__customerId__")
        if (customer)
            return;

        window.localStorage.setItem("__state__", JSON.stringify(state));
        window.localStorage.setItem("__checkout__", JSON.stringify(checkout));
        window.localStorage.setItem("__checkoutId__", checkout.draftId);
        window.localStorage.setItem("__orderFormId__", match.params.id);
        window.location.href = `${KEYS.interbankLoginPage}&redirect_uri=${KEYS.redirectUri}`;
    }

    saveDataLayerEvent = (event) => {
        if (window["dataLayer"]) {
            window["dataLayer"].push(event);
        }
    }

    saveDataLayerStepEvent = (step, stepName) => {
        const { checkout } = this.state;
        const { dataLayerEventItems } = this.state;

        let items = [];
        let checkoutItems = checkout.items;

        if (dataLayerEventItems.length === 0 && checkout) {
            let externalCart = checkout.externalCart;
            externalCart.forEach((el) => {
                let cart = JSON.parse(el.externalJsonCart);
                let cartItems = cart.items;
                cartItems.forEach((item) => {
                    let brandName = item.additionalInfo ? item.additionalInfo.brandName : '';
                    let categoryName = "";
                    let categoryTree = item.productCategories;
                    let price = item.price / 100;
                    let miles = price * 100 / 3;
                    let checkoutItem = checkoutItems.find((p, j) => { return item.id === p.productId });

                    for (let k in categoryTree) {
                        categoryName = categoryTree[k];
                    }

                    items.push({
                        id: item.id,
                        name: item.name,
                        price: price,
                        brand: brandName,
                        category: categoryName,
                        variant: '(not available)',
                        quantity: item.quantity,
                        metric1: checkoutItem ? this.calculateProductMiles(price, checkoutItem.exclusivePrice) : 0,
                        metric2: checkoutItem ? checkoutItem.exclusivePrice : price,
                        dimension13: cart.sellers ? cart.sellers[0].name : '',
                        dimension14: '(not available)',
                    })
                });
            });
        } else {
            items = dataLayerEventItems;
        }

        this.setState({ dataLayerEventItems: items });

        let event = {
            'event': 'checkout',
            'category': 'Ecommerce',
            'action': 'Checkout - ' + stepName,
            'label': 'Pago de clientes Interbank',
            'ecommerce': {
                'currencyCode': 'PEN',
                'checkout': {
                    'actionField': { 'step': step },
                    'products': items
                }
            }
        }

        this.saveDataLayerEvent(event);
    }

    calculateProductMiles = (price, exclusivePrice) => {
        let miles = price * 33.33333;
        if (exclusivePrice && exclusivePrice < price && exclusivePrice > 0)
            miles = exclusivePrice * 33.33333;

        return Math.ceil(miles);
    }

    renderPromoErrorMessage = () => {
        const { match } = this.props;

        let promoId = match.params.promotionId;

        if (!promoId)
            return '';

        let error = <React.Fragment>
            <h4>Lo sentimos</h4>
            <p className="text-center">
                Los datos que has ingresado no estan afiliado a la promocion.
            </p>
        </React.Fragment>

        let promos = ['094B9CEE-14EB-4AE0-8C66-6ACF33B54350', '5DDE7CF5-BDA0-471C-959B-11F43B693EBB', '21E8E156-DB58-4745-BD66-E3FCA9BDC3B8'];

        if (promos.indexOf(promoId) > -1) {
            error = <React.Fragment>
                <p className="text-center">
                    Gracias por ser cliente Interbank. Este beneficio era limitado por lo que el stock ya se agotó, pronto tendremos más novedades para ti.
                </p>
            </React.Fragment>
        }

        return error;
    }

    render() {
        const { loadCompleted, form, email, identificationNumber, completedForm, checkout, ibkPrices, start, user, address, stores, shippingItems, gateway, loaders, miles, promo, disabledPromo, enableBenefits, totalIbk, totalCheckout, error, plinPrices } = this.state;
        const { match } = this.props;

        return (
            <div className="v2">
                <div className={"checkout " + form}>
                    <div className="checkout__header">
                        <div className="d-flex justify-content-between">
                            <a href="https://shopstar.pe">
                                <img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
                            </a>
                            <span>Ll&aacute;manos al (01) 679-7700</span>
                        </div>
                    </div>
                    {/* <div className="checkout__prompt">
                        Nuestro equipo está realizando mejoras en la plataforma, por lo que la <b>forma de pago con Millas Benefit no estará disponible</b> desde las 9:00 pm del viernes 20 de enero hasta el s&aacute;bado 21 de enero a las 11:00 am. Pero, no te preocupes, podrás pagar con tarjeta de crédito y débito.
                    </div> */}
                    <div className="container">
                        <div className="row justify-content-around">
                            <div className="col-md-6 col-12">
                                <Navigation></Navigation>
                            </div>
                            <div className="col-md-5 col-12"></div>
                        </div>
                    </div>
                    <div className="checkout__body">
                        {
                            (form === 'email' && loadCompleted) &&
                            <React.Fragment>
                                <EmailForm
                                    loaders={loaders}
                                    match={match}
                                    promo={promo}
                                    disabledPromo={disabledPromo}
                                    handleSubmitEmail={this.handleSubmitEmail}></EmailForm>

                                <div className="modal fade payment-modal" id="startErrorModal" role="dialog" aria-labelledby="startErrorModal" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <i className="far fa-times-circle fa-2x mb-3"></i>
                                                <h4>Tenemos un problema con tus productos</h4>
                                                <p className="text-center">
                                                    {error}
                                                </p>
                                                <a href="https://shopstar.pe/checkout/#/cart" className="btn btn-main">
                                                    <span>Regresar al carrito</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal fade payment-modal" id="promoErrorModal" role="dialog" aria-labelledby="promoErrorModal" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                </button>
                                                <i className="far fa-times-circle fa-2x mb-3"></i>
                                                {this.renderPromoErrorMessage()}
                                                <a href="https://shopstar.pe/" className="btn btn-main">
                                                    <span>Ir a Shopstar</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        {
                            (form !== 'email' && form !== '') &&
                            <React.Fragment>
                                <div className="container">
                                    <div className="row justify-content-around">
                                        <div className="col-md-6 col-12">
                                            <div className="checkout__body__info-text">
                                                <h5>Finaliza tu compra</h5>
                                                <p>
                                                    Podrás encontrar el resumen de tu compra al final de esta página.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-12"></div>
                                    </div>
                                    <div className="row justify-content-around">
                                        <div className="col-12 col-md-6">
                                            <UserForm
                                                loaders={loaders}
                                                promo={promo}
                                                email={email}
                                                identificationNumber={identificationNumber}
                                                checkout={checkout}
                                                currentForm={form}
                                                completed={completedForm.user}
                                                handleSubmitUser={this.handleSubmitUser}
                                                handleEditUser={this.handleEditUser}></UserForm>
                                            <AddressForm
                                                loaders={loaders}
                                                start={start}
                                                checkout={checkout}
                                                user={user}
                                                currentForm={form}
                                                completed={completedForm.address}
                                                handleSubmitAddress={this.handleSubmitAddress}
                                                handleEditAddress={this.handleEditAddress}></AddressForm>
                                            <Stores
                                                loaders={loaders}
                                                promo={promo}
                                                checkout={checkout}
                                                currentForm={form}
                                                address={address}
                                                user={user}
                                                completed={completedForm.shipping}
                                                handleSubmitShipping={this.handleSubmitShipping}
                                                handleEditAddress={this.handleEditAddress}
                                                handleEditShipping={this.handleEditShipping}
                                                handleDeleteCartItem={this.handleDeleteCartItem}></Stores>
                                            <PaymentForm
                                                loaders={loaders}
                                                promo={promo}
                                                gateway={gateway}
                                                stores={stores}
                                                shippingItems={shippingItems}
                                                checkout={checkout}
                                                start={start}
                                                totalIbk={totalIbk}
                                                totalCheckout={totalCheckout}
                                                currentForm={form}
                                                user={user}
                                                address={address}
                                                enableBenefits={enableBenefits}
                                                ibkPrices={ibkPrices}
                                                handleSubmitPayment={this.handleSubmitPayment}
                                                redirectInterbankLogin={this.redirectInterbankLogin}
                                                saveDataLayerEvent={this.saveDataLayerEvent}
                                                loadCheckout={this.loadCheckout}
                                                setMiles={this.setMiles}></PaymentForm>
                                        </div>
                                        <div className="col-12 col-md-5 lateral-summary">
                                            <Summary
                                                checkout={checkout}
                                                start={start}
                                                promo={promo}
                                                totalCheckout={totalCheckout}
                                                miles={miles}
                                                ibkPrices={ibkPrices}
                                                plinPrices={plinPrices}
                                                form={form}
                                                handleDeleteCartItem={this.handleDeleteCartItem}></Summary>
                                        </div>
                                    </div>
                                </div>
                                <div className="container">
                                    <div className="row justify-content-around">
                                        <div className="col-12 col-md-6">
                                            <Contact></Contact>
                                        </div>
                                        <div className="col-12 col-md-5"></div>
                                    </div>
                                </div>
                            </React.Fragment>

                        }
                    </div>
                </div>

                <div className="modal fade payment-modal" id="deletingItemModal" role="dialog" aria-labelledby="deletingItemModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <i className="far fa-times-circle fa-2x mb-3"></i>
                                <h4>Eliminando producto...</h4>
                                <p className="text-center">
                                    Por favor espera unos segundos.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckoutV2View;
