import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import * as $ from 'jquery';
import "./CheckoutInfo.scss";
import "./../Checkout.scss";
import {
    generateCheckoutLink,
    getCheckoutPromotion,
    startCheckout,
    validateEmail,
    getCheckout,
    updateUser,
    saveAddress,
    saveShippingInfo,
    getDiscount,
    deleteCartItem,
    getIpInfo,
} from '../../../services/Service';
import {
    saveDataLayerEvent,
    saveDataLayerStepEvent,
    saveSatelliteEvent
} from '../../../services/TrackerService';

import Summary from './../shared/Summary'
import Contact from './../shared/Contact'

import { ReactComponent as IconHelp } from './../../../assets/help.svg';
import { ReactComponent as IconAdd } from './../../../assets/add.svg';
import { DISTRICTS, PROVINCES, DEPARTMENTS } from './../../../data/ubigeos/ubigeos';
import { KEYS } from './../../../constants/index';
import { textChangeRangeIsUnchanged } from "typescript";

class CheckoutInfoView extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        //User
        email: "",
        firstName: "",
        lastName: "",
        identificationNumber: "",
        identificationType: "1",
        phone: "",
        documentNumberLength: 8,
        disabledEmail: true,
        disabledIdentification: true,
        //Address
        addressType: "residential",
        addressId: "",
        department: "0",
        province: "0",
        district: "0",
        addressLine: "",
        addressNumber: "",
        flatNumber: "",
        reference: "",
        postalCode: "",
        addressName: "",
        deliveryDate: new Date(),
        pickupDate: new Date(),
        countryISO: "PER",
        ubigeos: {
            departments: DEPARTMENTS,
            provinces: [],
            districts: []
        },
        selectedAddressId: "",
        addressView: "form",
        //Common
        checkout: null,
        errors: {},
        saving: false,
        addressesTypes: ['Casa', 'Oficina', 'Depa', 'Otro']
    }

    componentDidMount = async () => {
        await this.loadCheckout();

        this.loadUser();
        this.loadAddresses();
    }

    getCheckoutId = () => {
        const { match } = this.props;

        let id = match.params.id;
        return id;
    }

    loadCheckout = async () => {
        const { match } = this.props;

        let id = this.getCheckoutId();

        let checkout = await getCheckout(id);
        if (checkout.data) {
            this.setState({
                checkout: checkout.data,
            });
        }
    }

    loadUser = () => {
        const { checkout, email, identificationNumber } = this.state;
        let user = {
            ...this.state
        };
        let disabledIdentification = true;
        let documentNumberLength = 8;

        user.email = checkout.customerEmail || "";
        user.firstName = checkout.customerFirstName || "";
        user.lastName = checkout.customerLastName || "";
        user.phone = checkout.customerPhone || "";
        user.identificationType = checkout.customerIdentificationType && checkout.customerIdentificationType === 'dni' ? '1' : "1";
        user.identificationNumber = checkout.customerIdentificationNumber || "";

        if (user.email.indexOf("*") > -1 && !checkout.customerIdentificationNumber) {
            user.email = email;
            user.identificationNumber = identificationNumber;
            //Todo: Select
            // if(identificationNumber && identificationNumber !== '') {
            //     if(identificationNumber.length === 8) {
            //         user.identificationType = '1';
            //     } else {
            //         user.identificationType = '2';
            //         documentNumberLength = 9
            //     }
            // }
        }

        if (user.email.indexOf("*")) {
            user.email = checkout.userSession.email;
        }

        if (!user.identificationNumber || user.identificationNumber === '') {
            disabledIdentification = false;
        }

        console.log(user.identificationNumber);

        this.setState({
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            identificationType: user.identificationType,
            identificationNumber: user.identificationNumber,
            phone: user.phone,
            disabledIdentification: disabledIdentification,
            documentNumberLength: documentNumberLength
        })

        if (checkout.customerIdentificationNumber && checkout.customerIdentificationNumber !== "") {
            this.fsIdentify();
        }
    }

    loadAddresses = () => {
        const { checkout } = this.state;
        let addressView = "form";

        if (checkout.additionalAddress && checkout.additionalAddress.length > 0) {
            addressView = "list";
        }

        this.setState({ addressView });
    }

    fsIdentify = () => {
        let user = this.state;

        if (window['_fs_namespace'] && window['_fs_namespace']['identify']) {
            window['_fs_namespace']['identify'](user.identificationNumber, {
                displayName: user.firstName + ' ' + user.lastName,
                email: user.email
            });
        }
    }

    toggleFieldError = (name, value) => {
        const { errors } = this.state;
        let _errors = errors;

        _errors[name] = value;
        this.setState({ errors: _errors });
    }

    setDocumentNumberLength = (event) => {
        let value = event.target.value
        let length = 8;

        if (value == '2') {
            length = 9;
        }

        this.setState({
            documentNumberLength: length
        })
    }

    handleChangeValue = (field, event) => {
        let value = event.target.value

        this.setState({
            [field]: value
        });
    }

    handleSubmitUser = () => {
        const user = this.state;
        const { handleSubmitUser } = this.props;

        let valid = this.validState();

        if (!valid)
            return;

        handleSubmitUser(user);
    }

    handleEditUser = () => {
        const { handleEditUser } = this.props;
        const { email } = this.state;

        if (email.indexOf("*") > -1) {
            this.setState({
                email: "",
                firstName: "",
                lastName: "",
                identificationNumber: "",
                identificationType: "1",
                phone: "",
            })
        }

        this.setState({
            disabledEmail: false,
            disabledIdentification: false
        });

        handleEditUser();
    }

    validState = () => {
        let state = this.state;
        let valid = true;
        let errors = {};
        let requiredAddressFields = ["department", "province", "district", "addressLine", "addressNumber"];
        let requiredUserFields = ["email", "firstName", "lastName", "identificationNumber", "identificationType", "phone"];

        for (let field in state) {
            if (requiredUserFields.indexOf(field) > -1 && (!state[field] || state[field] === "")) {
                errors[field] = true;
                valid = false;
            }
            if (field === 'phone') {
                if (state[field].length > 11 || state[field].length < 9) {
                    errors[field] = true;
                    valid = false;
                }
            }
            if (field === 'identificationNumber') {
                if (state[field].length !== state.documentNumberLength) {
                    errors[field] = true;
                    valid = false;
                }
            }
            if (field === 'email') {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(state[field])) {
                    errors[field] = true;
                    valid = false;
                }
            }
        }

        if (state.addressView === 'list') {
            if (!state.addressId || state.addressId === '') {
                valid = false;
                errors['selectedAddress'] = true;
            }
        } else {
            for (let field in state) {
                if (requiredAddressFields.indexOf(field) > -1 && (!state[field] || state[field] === "" || state[field] === "0")) {
                    errors[field] = true;
                    valid = false;
                }
            }
        }

        this.setState({ errors });
        return valid;
    }

    toggleFieldError = (name, value) => {
        const { errors } = this.state;
        let _errors = errors;

        _errors[name] = value;
        this.setState({ errors: _errors });
    }

    selectAddress = (address, index) => {
        let { errors } = this.state;

        errors.selectedAddress = false;

        this.setState({
            addressId: address.addressId,
            department: address.department,
            province: address.province,
            district: address.district,
            addressLine: address.addressLine,
            addressNumber: address.addressNumber,
            flatNumber: address.flatNumber,
            reference: address.reference,
            postalCode: address.stateCode,
            selectedAddressId: "address-" + index,
            errors: errors
        });
    }

    newAddress = () => {
        this.setState({
            addressId: "",
            department: "0",
            province: "0",
            district: "0",
            addressLine: "",
            addressNumber: "",
            flatNumber: "",
            reference: "",
            postalCode: "",
            addressView: "form"
        });
    }

    selectAdditionalAddress = () => {
        const { checkout } = this.state;
        let address = checkout.additionalAddress[0];

        this.setState({
            addressId: address.addressId,
            department: address.department,
            province: address.province,
            district: address.district,
            addressLine: address.addressLine,
            addressNumber: address.addressNumber,
            flatNumber: address.flatNumber,
            reference: address.reference,
            postalCode: address.stateCode,
            selectedAddressId: "address-0",
            addressView: "list"
        })
    }

    handleChangeValue = (field, event) => {
        const { identificationType } = this.state;

        let value = event.target.value
        let requiredFields = ["email", "firstName", "lastName", "identificationNumber", "identificationType", "phone"];

        if (field === 'phone' || (field === 'identificationNumber' && identificationType == '1')) {

            var re = /^[0-9]*$/;
            if (!re.test(value))
                return;
        }

        if (field === 'firstName' || field === 'lastName') {
            let reLetters = /^[a-zA-Z\s]*$/;
            if (!reLetters.test(value))
                return;
        }

        if (!value || value === "") {
            this.toggleFieldError(field, true)
        } else {
            this.toggleFieldError(field, false)
        }

        if (requiredFields.indexOf(field) > -1 && (!value || value === "")) {
            this.toggleFieldError(field, true)
        } else {
            this.toggleFieldError(field, false)
        }

        this.setState({
            [field]: value
        });
    }

    newGuid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    loadProvinces = (e) => {
        const { ubigeos, department } = this.state;

        let provinces = PROVINCES[e.target.value];

        if (provinces && provinces.length > 0) {
            ubigeos.provinces = provinces;

            this.setState({ ubigeos: ubigeos });
        }
    }

    loadDistricts = (e) => {
        const { ubigeos, province } = this.state;

        let districts = DISTRICTS[e.target.value];

        if (districts && districts.length > 0) {
            ubigeos.districts = districts;

            this.setState({ ubigeos: ubigeos });
        }
    }

    setPostalCode = (e) => {
        const { ubigeos } = this.state;
        let value = e.target.value;
        let district = ubigeos.districts.filter((e, i) => { return e.id == value })[0];

        this.setState({
            postalCode: district.ubigeo
        });
    }

    handleChangeAddressTypeValue = () => {

    }

    saveAddress = async () => {
        const { checkout } = this.state;
        const state = this.state;

        let items = checkout.items;
        let model = {
            postalCode: state.postalCode,
            address: []
        };

        items.forEach((el, i) => {
            model.address.push({
                addressId: this.newGuid(),
                selectedAddressId: state.addressId,
                addressType: i === 0 ? "residential" : "search",
                firstName: state.firstName,
                lastName: state.lastName,
                phone: state.phone,
                cellPhone: state.phone,
                addressLine: state.addressLine,
                addressNumber: state.addressNumber,
                reference: state.reference,
                countryISO: "PER",
                postalCode: state.postalCode,
                department: state.department,
                province: state.province,
                district: state.district,
                geoCoordinates: []
            })
        })

        let result = await saveAddress(this.getCheckoutId(), model);

        if (result.error)
            return;

        saveDataLayerEvent({
            'event': 'virtualEvent',
            'category': 'Checkout - Metodo de entrega',
            'action': 'Seleccionar boton',
            'label': 'Continuar - Direccion de envio'
        });

        let address = {
            addressId: state.addressId,
            addressLine: state.addressLine,
            addressNumber: state.addressNumber,
            reference: state.reference,
            countryISO: "PER",
            postalCode: state.postalCode,
            department: state.department,
            province: state.province,
            district: state.district,
            addressType: "residential"
        }



        return address;

        // let checkout = await getCheckout(start.draftId);
        // this.toggleLoader('address', false);
        // if (checkout.data) {
        //     let form = 'shipping';
        //     completedForm.address = true;

        //     if (lastForm !== '' && lastForm !== 'address') {
        //         form = lastForm;
        //     }
        //     this.setState({
        //         checkout: checkout.data,
        //         form: form,
        //         completedForm: completedForm,
        //         address: address
        //     });
        // }
    }

    saveUser = async () => {
        const state = this.state;

        let model = {
            email: state.email,
            firstName: state.firstName,
            lastName: state.lastName,
            identificationNumber: state.identificationNumber,
            identificationType: state.identificationType,
            phone: state.phone,
        }

        saveDataLayerEvent({
            'event': 'virtualEvent',
            'category': 'Checkout - Datos personales',
            'action': 'Seleccionar boton',
            'label': 'Continuar'
        });

        if (state.firstName.indexOf('*') < 0) {
            let checkoutResult = await updateUser(this.getCheckoutId(), model);
        }

        saveDataLayerEvent({
            'event': 'virtualPage',
            'pagePath': '/Checkout/IBK/2/Metodo-de-entrega',
            'pageName': 'Checkout - Interbank - Método de entrega'
        });

        saveDataLayerStepEvent(state.checkout, 2, 'Metodo de entrega');
        saveSatelliteEvent(state.identificationNumber);

        return model;
    }

    handleSubmitInfo = async () => {
        let valid = this.validState();

        if (!valid)
            return;

        this.setState({
            submitting: true
        });

        let user = await this.saveUser();
        let address = await this.saveAddress();

        if (!user || !address)
            return;

        window.localStorage.setItem("__address__", JSON.stringify(address));
        window.localStorage.setItem("__user__", JSON.stringify(user));

        const urlParams = new URLSearchParams(window.location.search);
        let version = urlParams.get('version');

        if (version && version !== '')
            version = "version=" + version;

        window.location.href = `/${this.getCheckoutId()}/shipping?${version}`
    }



    render() {
        const { errors, documentNumberLength, ubigeos, selectedAddressId, addressesTypes, addressView, checkout, submitting } = this.state;

        return (
            <div className="v3">
                <div className="header">
                    <a href="https://shopstar.pe">
                        <img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
                    </a>
                </div>
                <div className="container">
                    <div className="visible-mobile mt-4">
                        {
                            (checkout) &&
                            <Summary checkout={checkout} showShippingCostMessage={true}></Summary>
                        }
                    </div>
                    <div className="stepper">
                        <div className="stepper__item active">
                            <span>1</span>
                            <div className="name">Datos de cliente</div>
                        </div>
                        <div className="stepper__item">
                            <span>2</span>
                            <div className="name">Forma de entrega</div>
                        </div>
                        <div className="stepper__item">
                            <span>3</span>
                            <div className="name">Forma de pago</div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="section">
                                <h3 className="section__title">Datos del cliente:</h3>
                                <div className="section__form">
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-md-12 col-12">
                                                <div className={'form-group ' + (errors.firstName ? 'invalid' : '')}>
                                                    <label>Nombre</label>
                                                    <input type="text" className="form-control"
                                                        placeholder="Ingresa tu nombre"
                                                        value={this.state.firstName}
                                                        onChange={(e) => this.handleChangeValue('firstName', e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <div className={'form-group ' + (errors.lastName ? 'invalid' : '')}>
                                                    <label>Apellido</label>
                                                    <input type="text" className="form-control"
                                                        placeholder="Ingresa tu apellido"
                                                        value={this.state.lastName}
                                                        onChange={(e) => this.handleChangeValue('lastName', e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-12">
                                                <div className={'form-group ' + (errors.email ? 'invalid' : '')}>
                                                    <label>Correo electrónico</label>
                                                    <input type="text" className="form-control"
                                                        placeholder="correo@shopstar.com.pe"
                                                        value={this.state.email}
                                                        readOnly={this.state.disabledEmail}
                                                        onChange={(e) => this.handleChangeValue('email', e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <div className={'form-group ' + (errors.phone ? 'invalid' : '')}>
                                                    <label>Celular</label>
                                                    <input type="text" className="form-control"
                                                        placeholder="Ingresa tu n&uacute;mero de celular"
                                                        maxLength="11"
                                                        value={this.state.phone}
                                                        onChange={(e) => this.handleChangeValue('phone', e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>Documento de identidad</label>
                                                    <div className="row">
                                                        <div className={'col-md-4 col-6 ' + (errors.identificationType ? 'invalid' : '')}>
                                                            <select className="form-control" value={this.state.identificationType}
                                                                disabled={this.state.disabledIdentification}
                                                                onChange={(e) => { this.handleChangeValue('identificationType', e); this.setDocumentNumberLength(e) }}>
                                                                <option value="1">DNI</option>
                                                                <option value="2">CE</option>
                                                            </select>
                                                        </div>
                                                        <div className={'col-md-8 col-6 ' + (errors.identificationNumber ? 'invalid' : '')}>
                                                            <input type="text" className="form-control"
                                                                placeholder="N&uacute;mero de documento"
                                                                maxLength={documentNumberLength}
                                                                value={this.state.identificationNumber}
                                                                // readOnly={promo ? true : false}
                                                                onChange={(e) => this.handleChangeValue('identificationNumber', e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                </div>
                                {/* </div>
                            <div className="section"> */}
                                <div className="section__separator"></div>
                                <h3 className="section__title">Tu dirección</h3>
                                <div className="section__info">
                                    <IconHelp></IconHelp>
                                    Usamos tu dirección como punto de envío de productos y comprobantes de pagos.
                                </div>
                                {
                                    (addressView === "form") &&
                                    <div className="section__form">
                                        <div className="row">
                                            <div className="col-md-12 col-12">
                                                <div className={'form-group ' + (errors.department ? 'invalid' : '')}>
                                                    <label>Departamento</label>
                                                    <select className="form-control" required name="department" value={this.state.department} onChange={(e) => { this.handleChangeValue('department', e); this.loadProvinces(e) }}>
                                                        <option value="0" disabled>Seleccionar</option>
                                                        {
                                                            ubigeos.departments.map(d => {
                                                                return (
                                                                    <option key={'d-' + d.id} value={d.id}>{d.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <div className={'form-group ' + (errors.province ? 'invalid' : '')}>
                                                    <label>Provincia</label>
                                                    <select className="form-control" required name="province" value={this.state.province} onChange={(e) => { this.handleChangeValue('province', e); this.loadDistricts(e) }}>
                                                        <option value="0">Seleccionar</option>
                                                        {
                                                            ubigeos.provinces.map(d => {
                                                                return (
                                                                    <option key={'p-' + d.id} value={d.id}>{d.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <div className={'form-group ' + (errors.district ? 'invalid' : '')}>
                                                    <label>Distrito</label>
                                                    {/* this.getAddress() */}
                                                    <select className="form-control" required name="district" value={this.state.district} onChange={(e) => { this.handleChangeValue('district', e); this.setPostalCode(e) }}>
                                                        <option value="0">Seleccionar</option>
                                                        {
                                                            ubigeos.districts.map(d => {
                                                                return (
                                                                    <option key={'ds-' + d.id} value={d.id}>{d.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className={'form-group ' + (errors.addressLine ? 'invalid' : '')}>
                                                    <label>Direcci&oacute;n</label>
                                                    <input type="text" className="form-control"
                                                        placeholder="Calle/Oficina/Jiron"
                                                        value={this.state.addressLine}
                                                        onChange={(e) => this.handleChangeValue('addressLine', e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className={'form-group ' + (errors.addressNumber ? 'invalid' : '')}>
                                                    <label>N&uacute;mero</label>
                                                    <input type="text" className="form-control"
                                                        placeholder="N&uacute;mero"
                                                        value={this.state.addressNumber}
                                                        onChange={(e) => this.handleChangeValue('addressNumber', e)} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className={'form-group ' + (errors.flatNumber ? 'invalid' : '')}>
                                                    <label>Piso y/o departamento</label>
                                                    <input type="text" className="form-control"
                                                        placeholder="Piso y/o Dpto."
                                                        value={this.state.flatNumber}
                                                        onChange={(e) => this.handleChangeValue('flatNumber', e)} />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className={'form-group'}>
                                                    <label>Referencia</label>
                                                    <input type="text" className="form-control"
                                                        placeholder="Referencia"
                                                        value={this.state.reference}
                                                        onChange={(e) => this.handleChangeValue('reference', e)} />
                                                </div>
                                            </div>
                                            {/* <div className="col-12">
                                                <label>Guardar dirección como</label>
                                                <div className="form-group radio-button">
                                                    {
                                                        addressesTypes.map(el => {
                                                            return (
                                                                <div className="form-check" key={el}>
                                                                    <input className="form-check-input" type="radio" name="addressType" id={'address' + el} value={el} onChange={(e) => this.handleChangeAddressTypeValue(e)} />
                                                                    <label className="form-check-label" htmlFor={'address' + el}>
                                                                        {el}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className={'form-group'}>
                                                    <label>Nombre de dirección</label>
                                                    <input type="text" className="form-control"
                                                        placeholder="Referencia"
                                                        value={this.state.addressName}
                                                        onChange={(e) => this.handleChangeValue('addressName', e)} />
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="section__action-link">
                                            <a role="button" onClick={(e) => { this.selectAdditionalAddress() }}>
                                                Elegir una direcci&oacute;n
                                            </a>
                                        </div>
                                    </div>
                                }
                                {
                                    (checkout && addressView === "list") &&
                                    <React.Fragment>
                                        <div className="section__address-list">
                                            {
                                                checkout.additionalAddress.map((a, i) => {
                                                    return (
                                                        <React.Fragment key={"address-" + i}>
                                                            {
                                                                (i < 3) &&
                                                                <div className={"section__address-list__item " + (selectedAddressId === "address-" + i ? "selected" : "") + (errors.selectedAddress ? 'error' : '')} id={"address-" + i} onClick={(e) => { this.selectAddress(a, i) }}>
                                                                    <div className="address-name">
                                                                        {
                                                                            (!a.addressName || a.addressName === '') &&
                                                                            <span>Sin nombre</span>
                                                                        }
                                                                        {
                                                                            (a.addressName && a.addressName !== '') &&
                                                                            <span>{a.addressName}</span>
                                                                        }
                                                                    </div>
                                                                    <p>
                                                                        Dirección: <b>{a.addressLine} {a.addressNumber}, {a.district}, {a.province}, {a.department}</b>
                                                                    </p>
                                                                    <p>
                                                                        Referencia: <b>{a.reference}</b>
                                                                    </p>
                                                                    <p>
                                                                        Última vez utilizada:: <b>18 de mayo de 2022</b>
                                                                    </p>
                                                                    {/* <p className="actions">
                                                                        <a role="button" onClick={(e) => { this.editAddress(a) }}>Editar</a>
                                                                        <a role="button" onClick={(e) => { this.deleteAddress(a) }}>Eliminar</a>
                                                                    </p> */}
                                                                </div>
                                                            }
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="section__action-link">
                                            <a role="button" onClick={(e) => { this.newAddress() }}>
                                                <IconAdd></IconAdd>
                                                Ingresa una nueva dirección
                                            </a>
                                        </div>
                                    </React.Fragment>
                                }
                                <div className="section__form">
                                    <div className="form-group">
                                        <button disabled={submitting} type="button" className="btn btn-main btn-submit" onClick={this.handleSubmitInfo}>Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="section">
                                <h3 className="section__title">Forma de entrega</h3>
                                <h4 className="section__subtitle">Esperando que se complete la información.</h4>
                                <div className="section__separator"></div>
                                <h3 className="section__title">Forma de pago</h3>
                                <h4 className="section__subtitle">Esperando que se complete la información.</h4>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="hidden-mobile">
                                {
                                    (checkout) &&
                                    <Summary checkout={checkout} showShippingCostMessage={true}></Summary>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <Contact></Contact>
                </div>
            </div>
        )
    }
}

export default CheckoutInfoView;
