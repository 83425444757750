import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import * as $ from 'jquery';
import "./../Checkout.scss";
import {
    generateCheckoutLink,
    getCheckoutPromotion,
    startCheckout,
    validateEmail,
    getCheckout,
    updateUser,
    saveAddress,
    saveShippingInfo,
    getDiscount,
    deleteCartItem,
    getIpInfo,
} from '../../../services/Service';
import {
    saveDataLayerEvent,
    saveDataLayerStepEvent,
    saveSatelliteEvent
} from '../../../services/TrackerService';

import Summary from './../shared/Summary'
import Contact from './../shared/Contact'

import { ReactComponent as IconHelp } from './../../../assets/help.svg';
import { ReactComponent as IconAdd } from './../../../assets/add.svg';
import { DISTRICTS, PROVINCES, DEPARTMENTS } from './../../../data/ubigeos/ubigeos';
import { KEYS } from './../../../constants/index';
import { textChangeRangeIsUnchanged } from "typescript";

class CheckoutInfoView extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        start: null,
        stores: null,
        email: '',
        identificationNumber: '',
        promotionCheckout: false,
        promoBackground: null,
        promo: null,
        promoText: {
            main: 'Ingresa tu correo',
            description: 'Solo usaremos tu correo para identificarte.'
        },
        errors: {},
        submitting: false,
        disabledPromo: false,
    }

    componentDidMount = async () => {
        window.localStorage.removeItem("__stores__");
        window.localStorage.removeItem("__shippingState__");
        window.localStorage.removeItem("__shippingItems__");
        window.localStorage.removeItem("__user__");
        window.localStorage.removeItem("__installments__");
        window.localStorage.removeItem("__address__");
        window.localStorage.removeItem("__totalIbk__");
        window.localStorage.removeItem("__checkoutId__");
        window.localStorage.removeItem("__promo__");
        window.localStorage.removeItem("__ipAddress__");
        window.localStorage.removeItem("__total__");
        window.localStorage.removeItem("__gateway__");


        await this.getPromoInfo();

        this.validatePromotion();
        this.getEmail();
    }

    validatePromotion = () => {
        const { match, promo } = this.props;

        let promoId = match.params.promotionId;

        if (promo) {
            let promoParams = JSON.parse(promo.jsonBanner);
            let imageSize = 'desktop';
            let width = window.innerWidth;
            if (width < 480) {
                imageSize = 'mobile'
            }

            this.setState({
                promotionCheckout: true,
                promoBackground: {
                    backgroundImage: `url(https://ibkshopstarsasprod.blob.core.windows.net/images/promos/${promoParams.backgroundImage.image}-${imageSize}.JPG)`
                },
                promoText: {
                    main: promoParams.title,
                    description: promoParams.description
                }
            })
        }
    }

    getPromoInfo = async () => {
        const { match } = this.props;

        let promoId = match.params.promotionId;

        if (!promoId) {
            this.setState({
                loadCompleted: true
            });

            return;
        }

        let promotionResult = await getCheckoutPromotion(promoId);

        if (promotionResult.status === 200) {
            let promo = promotionResult.data;
            let promoParams = JSON.parse(promo.jsonBanner);
            let disabledPromo = promoParams.disabled;
            this.setState({
                promo: promo,
                disabledPromo: disabledPromo,
                loadCompleted: true
            });
        }
    }

    getEmail = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');
        if (email && email != 'null')
            this.setState({ email });
    }

    toggleFieldError = (name, value) => {
        const { errors } = this.state;
        let _errors = errors;

        _errors[name] = value;
        this.setState({ errors: _errors });
    }

    handleChangeEmail = (event) => {
        this.toggleFieldError('email', false);

        let email = event.target.value;

        email = email.toLowerCase();
        email = email.replace(/\s/g, '');

        this.setState({
            email: email
        });
    }

    handleChangeIdentificationNumber = (event) => {
        let value = event.target.value;

        var re = /^[0-9]*$/;
        if (!re.test(value))
            return;

        if (value.length > 9)
            return;

        if (!value || value === "") {
            this.toggleFieldError('identificationNumber', true)
        } else {
            this.toggleFieldError('identificationNumber', false)
        }

        this.setState({
            identificationNumber: value
        });
    }

    handleSubmitEmail = (event) => {
        event.preventDefault();
        const { email, identificationNumber, promotionCheckout } = this.state;

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (email === '' || !re.test(email)) {
            this.toggleFieldError('email', true);
            return;
        }

        if (promotionCheckout && (identificationNumber === '' || identificationNumber.length < 8)) {
            this.toggleFieldError('identificationNumber', true);
            return;
        }

        this.sendEmail(email, identificationNumber);
    }

    startCheckout = async (email, identificationNumber) => {
        const { match } = this.props;

        let id = match.params.id;
        let promoId = match.params.promotionId;
        let linkId = "00000000-0000-0000-0000-000000000000";

        // this.toggleLoader('start', true);

        if (promoId) {
            let model = {
                email: email,
                linkId: promoId,
                identificationNumber: identificationNumber
            };
            let checkoutResult = await generateCheckoutLink(model)
            if (checkoutResult.status === 200) {
                id = checkoutResult.data.id;
            } else {
                $("#promoErrorModal").modal("show");
                // this.toggleLoader('start', false);
                return;
            }

            linkId = promoId;
        }

        let result = await startCheckout(id, email, identificationNumber, linkId);

        // this.toggleLoader('start', false);

        if (result.status === 200) {
            // let ibKModel = {
            //     bin: '411111',
            //     installments: 1,
            //     referenceValue: result.data.total,
            //     paymentSystem: 1,
            //     customerId: window.localStorage.getItem("__customerId__")
            // }

            // await getDiscount(result.data.draftId, ibKModel);

            this.setState({
                start: result.data,
                stores: result.data.items
            });
        } else if (result.status === 400) {
            $("#startErrorModal").modal("show");
            this.setState({
                error: result.data
            });
        }
    }

    sendEmail = async (email, identificationNumber) => {
        this.setState({
            submitting: true
        });

        await this.startCheckout(email, identificationNumber);

        const { start, promo } = this.state;

        let items = [];
        let draftId = "";

        if (start) {
            start.items.forEach((el, i) => {
                items.push({
                    productId: el.productId,
                    quantity: el.quantity,
                    seller: el.sellerName
                });
            });

            draftId = start.draftId;
        } else {
            this.setState({
                submitting: false
            });
            return;
        }

        let model = {
            email: email,
            draftId: draftId,
            items: items
        }

        let response = await validateEmail(model);

        if (response.status !== 200) {
            this.setState({
                submitting: false
            });
            return;
        }

        let gateway = {
            name: response.data.gateway,
            sessionId: response.data.sessionId
        }
        let enableBenefits = response.data.enableBenefits;

        saveDataLayerEvent({
            'event': 'virtualPage',
            'pagePath': '/Checkout/IBK/1/Datos-personales',
            'pageName': 'Checkout - Interbank - Datos personales'
        });

        // saveDataLayerStepEvent(1, 'Datos Personales');

        window.localStorage.setItem("__promo__", JSON.stringify(promo));
        window.localStorage.setItem("__gateway__", JSON.stringify(gateway));
        window.localStorage.setItem("__benefits__", JSON.stringify(enableBenefits));

        const urlParams = new URLSearchParams(window.location.search);
        let version = urlParams.get('version');

        if (version && version !== '') 
        version = "version=" + version;

        window.location.href = `/${draftId}/info?${version}`
    }



    render() {
        const { errors, promoBackground, promotionCheckout, promoText, disabledPromo, submitting, error } = this.state;

        return (
            <div className="v3">
                <div className="header">
                    <a href="https://shopstar.pe">
                        <img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
                    </a>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section" style={promoBackground}>
                                <div className="section__form email-form">
                                    {
                                        (!disabledPromo) &&
                                        <form className="border-bottom-0" onSubmit={(e) => { this.handleSubmitEmail(e) }}>
                                            <h2 className={'section__title' + promotionCheckout ? '' : ''} dangerouslySetInnerHTML={{ __html: promoText.main }}></h2>
                                            <h5 className={'section__subtitle' + promotionCheckout ? '' : ''} dangerouslySetInnerHTML={{ __html: promoText.description }}></h5>
                                            {
                                                (promotionCheckout) &&
                                                <div className={'form-group ' + (errors.identificationNumber ? 'invalid' : '')}>
                                                    <label htmlFor="identificationType">N&uacute;mero de documento</label>
                                                    <input id="identificationType" value={this.state.identificationNumber} onChange={this.handleChangeIdentificationNumber} type="text" className="form-control" placeholder="Ingresa tu n&uacute;mero de documento" />
                                                </div>
                                            }
                                            <div className={'form-group ' + (errors.email ? 'invalid' : '')}>
                                                <label htmlFor="userEmail">Correo electrónico</label>
                                                <input id="userEmail" value={this.state.email} onChange={this.handleChangeEmail} type="text" className="form-control" placeholder="correo@shopstar.pe" />
                                            </div>
                                            <div className="form-group">
                                                <button disabled={submitting} type="submit" className="btn btn-main w-100 btn-submit">
                                                    Continuar
                                                </button>
                                            </div>
                                        </form>
                                    }

                                    {
                                        (disabledPromo) &&
                                        <form className="border-bottom-0">
                                            <h2 className={'text-center'}>¡Hola!</h2>
                                            <h5 className={'text-center'}>Lamentablemente se agotó el stock que teníamos completado para esta campaña, pero pronto tendremos más novedades y sorpresas para ti.</h5>
                                            <h5 className="text-center">Gracias por ser cliente Interbank</h5>
                                        </form>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade payment-modal" id="startErrorModal" role="dialog" aria-labelledby="startErrorModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <i className="far fa-times-circle fa-2x mb-3"></i>
                                <h4>Tenemos un problema con tus productos</h4>
                                <p className="text-center">
                                    {error}
                                </p>
                                <a href="https://shopstar.pe/checkout/#/cart" className="btn btn-main">
                                    <span>Regresar al carrito</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade payment-modal" id="promoErrorModal" role="dialog" aria-labelledby="promoErrorModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <i className="far fa-times-circle fa-2x mb-3"></i>
                                <h4>Lo sentimos</h4>
                                <p className="text-center">
                                    Los datos que has ingresado no estan afiliado a la promocion.
                                </p>
                                <a href="https://shopstar.pe/" className="btn btn-main">
                                    <span>Ir a Shopstar</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckoutInfoView;
