import React from 'react';
import { connect } from 'react-redux';
import CheckoutShippingView from './CheckoutShippingView';
import { withRouter } from 'react-router';

function CheckoutShippingContainer (
    {
        match
    }
) {
    return <CheckoutShippingView
        match={match}
    ></CheckoutShippingView>
}

function mapStateToProps(state) {
	return {
		
	}
}

const mapDispatchToProps = {
	
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(CheckoutShippingContainer));
