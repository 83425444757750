export const saveDataLayerEvent = async (event) => {
    if (window["dataLayer"]) {
        window["dataLayer"].push(event);
    }
}

let calculateProductMiles = (price, exclusivePrice) => {
    let miles = price * 33.33333;
    if (exclusivePrice && exclusivePrice < price && exclusivePrice > 0)
        miles = exclusivePrice * 33.33333;

    return Math.ceil(miles);
}

export const saveDataLayerStepEvent = (checkout, step, stepName) => {
    let items = [];
    let checkoutItems = checkout.items;

    let externalCart = checkout.externalCart;
    externalCart.forEach((el) => {
        let cart = JSON.parse(el.externalJsonCart);
        let cartItems = cart.items;
        cartItems.forEach((item) => {
            let brandName = item.additionalInfo ? item.additionalInfo.brandName : '';
            let categoryName = "";
            let categoryTree = item.productCategories;
            let price = item.price / 100;
            let miles = price * 100 / 3;
            let checkoutItem = checkoutItems.find((p, j) => { return item.id === p.productId });

            for (let k in categoryTree) {
                categoryName = categoryTree[k];
            }

            items.push({
                id: item.id,
                name: item.name,
                price: price,
                brand: brandName,
                category: categoryName,
                variant: '(not available)',
                quantity: item.quantity,
                metric1: checkoutItem ? calculateProductMiles(price, checkoutItem.exclusivePrice) : 0,
                metric2: checkoutItem ? checkoutItem.exclusivePrice : price,
                dimension13: cart.sellers ? cart.sellers[0].name : '',
                dimension14: '(not available)',
            })
        });
    });

    let event = {
        'event': 'checkout',
        'category': 'Ecommerce',
        'action': 'Checkout - ' + stepName,
        'label': 'Pago de clientes Interbank',
        'ecommerce': {
            'currencyCode': 'PEN',
            'checkout': {
                'actionField': { 'step': step },
                'products': items
            }
        }
    }

    if (window["dataLayer"]) {
        window["dataLayer"].push(event);
    }
}

export const saveSatelliteEvent = (userId) => {
    let satellite = window['_satellite'];

    if (!satellite)
        return;

    satellite.track('trackUserID', { 'userID': userId });
}

