import React from 'react';
import { connect } from 'react-redux';
import CheckoutV2View from './CheckoutV2View';
import { withRouter } from 'react-router';

function CheckoutV2Container (
    {
        match
    }
) {
    return <CheckoutV2View
        match={match}
    ></CheckoutV2View>
}

function mapStateToProps(state) {
	return {
		
	}
}

const mapDispatchToProps = {
	
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(CheckoutV2Container));
