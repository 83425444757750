import {
	TRANSACTION_POST_BEGIN,
	TRANSACTION_POST_SUCCES,
	TRANSACTION_POST_FAILURE,
	AUTHORIZE_POST_BEGIN,
	AUTHORIZE_POST_SUCCES,
	AUTHORIZE_POST_FAILURE,
	SEND_EMBLUE_DATA_BEGIN,
	SEND_EMBLUE_DATA_SUCCESS,
	SEND_EMBLUE_DATA_FAILURE,
	INVALIDATE_ORDER_BEGIN,
	INVALIDATE_ORDER_SUCCESS,
	INVALIDATE_ORDER_FAILURE,
	GET_MILES_TOKEN_BEGIN,
	GET_MILES_TOKEN_SUCCESS,
	GET_MILES_TOKEN_FAILURE,
} from '../constants/ActionTypes';
import * as $ from 'jquery';

const defaultTransactionState = {
	transaction: null,
	loading: false,
	error: null
}

export const transactionPost = (state = defaultTransactionState, { type, payload }) => {
	switch (type) {
		case TRANSACTION_POST_BEGIN: {			
			return {
				...state,
				loading: true,
				error: null
			};
		}
		case TRANSACTION_POST_SUCCES: {
			return { 
				...state,
				loading: false,
				transaction: payload
			};
		}
		case TRANSACTION_POST_FAILURE: {
			$("#loadingPaymentModal").modal("hide");
			$("#errorPaymentModal").modal("show");
			return {
				...state,
				loading: false,
				error: payload
			};
		}
		default: {
			return state;
		}
	}
}

export const authorizePost = (state = defaultTransactionState, { type, payload }) => {
	switch (type) {
		case AUTHORIZE_POST_BEGIN: {			
			return {
				...state,
				loading: true,
				error: null
			};
		}
		case AUTHORIZE_POST_SUCCES: {
			window.localStorage.removeItem("__state__");
			window.localStorage.removeItem("__get__");
			window.localStorage.removeItem("__customerId__");
			window.location.href = "/checkout/exito/" + payload.orderGroup
			return { 
				...state,
				loading: false,
				transaction: payload
			};
		}
		case AUTHORIZE_POST_FAILURE: {
			$("#loadingPaymentModal").modal("hide");
			$("#errorPaymentModal").modal("show");
			return {
				...state,
				loading: false,
				error: payload
			};
		}
		default: {
			return state;
		}
	}
}


const defaultEmblue = {
	data: null,
	loading: false,
	error: null
}

export const embluePost = (state = defaultEmblue, { type, payload }) => {
	switch (type) {
		case SEND_EMBLUE_DATA_BEGIN: {
			return {
				...state,
				data: null,
				loading: true,
				error: null,
			}
		}
		case SEND_EMBLUE_DATA_SUCCESS: {
			return {
				...state,
				data: payload,
				loading: false,
				error: null,
			}
		}
		case SEND_EMBLUE_DATA_FAILURE: {
			return {
				...state,
				loading: false,
				data: null,
				error: payload,
			}
		}
		default: {
			return state;
		}
	}
}

const defaultInvalidateOrder = {
	data: null,
	loading: false,
	error: null
}

export const invalidatePost = (state = defaultInvalidateOrder, { type, payload }) => {
	switch (type) {
		case INVALIDATE_ORDER_BEGIN: {
			return {
				...state,
				data: null,
				loading: true,
				error: null,
			}
		}
		case INVALIDATE_ORDER_SUCCESS: {
			return {
				...state,
				data: payload,
				loading: false,
				error: null,
			}
		}
		case INVALIDATE_ORDER_FAILURE: {
			return {
				...state,
				loading: false,
				data: null,
				error: payload,
			}
		}
		default: {
			return state;
		}
	}
}

const defaultMilesToken = {
	data: null,
	loading: false,
	error: null
}

export const milesToken = (state = defaultMilesToken, { type, payload }) => {
	switch (type) {
		case GET_MILES_TOKEN_BEGIN: {
			return {
				...state,
				data: null,
				loading: true,
				error: null,
			}
		}
		case GET_MILES_TOKEN_SUCCESS: {
			return {
				...state,
				data: payload,
				loading: false,
				error: null,
			}
		}
		case GET_MILES_TOKEN_FAILURE: {
			return {
				...state,
				loading: false,
				data: null,
				error: payload,
			}
		}
		default: {
			return state;
		}
	}
}
