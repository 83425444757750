import React from "react";
import { ReactComponent as IconHelp } from './../../../assets/help.svg';

class User extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        user: null,
        checkoutId: ''
    }

    componentDidMount = () => {
        console.log("User Info")
    }

    maskProp = (prop) => {
        return prop;
    }

    render() {
        const { user, checkoutId } = this.props;

        return (
            <div className="saved-info">
                <h4>Tu información de contacto</h4>
                <p>
                    <span>Nombre:</span>
                    <span>{user.firstName}</span>
                </p>
                <p>
                    <span>Apellido:</span>
                    <span>{user.lastName}</span>
                </p>
                <p>
                    <span>Celular:</span>
                    <span>{user.phone}</span>
                </p>
                <p>
                    <span>Correo:</span>
                    <span>{user.email}</span>
                </p>
                <p>
                    <a href={`/${checkoutId}/info`}>Editar contacto</a>
                </p>
                <p className="info">
                    <IconHelp></IconHelp>
                    <span>Tus datos son guardados de forma secreta.</span>
                </p>
            </div>
        )
    }
}

export default User;