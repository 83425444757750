import React from "react";
import { ReactComponent as IconPhone } from './../../../assets/phone.svg';
import { ReactComponent as IconEmail } from './../../../assets/mail.svg';

class Contact extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <div className="contact">
                    <div className="contact__icon">
                        <img src="/customer-service.png" alt="" />
                    </div>
                    <div className="contact__info">
                        <h4>
                            ¿Necesitas ayuda con tu compra?
                    </h4>
                        <p>
                            Conversa con nosotros de lunes a viernes de 9:00 am a 9:00 pm y sábado de 9:00 am a 6:00 pm.
                    </p>
                        <ul className="contact__info__channels">
                            <li>
                                <IconPhone></IconPhone>
                            Llámanos al <a href="tel:016797700">(01) 679-7700</a>
                            </li>
                            {/* <li>
                                <IconWhatsapp></IconWhatsapp>
                            Escríbenos por <a href="">Whatsapp</a>
                            </li> */}
                            <li>
                                <IconEmail></IconEmail>
                            Escríbenos a <a href="mailto:hola@shopstar.com.pe">hola@shopstar.com.pe</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="copyright">
                    &copy; Copyright 2021 Shopstar. Todos los derechos reservados.
                </div>
            </React.Fragment>
        )
    }
}

export default Contact;