import React from 'react';
import { connect } from 'react-redux';
import CheckoutEmailView from './CheckoutEmailView';
import { withRouter } from 'react-router';

function CheckoutEmailContainer (
    {
        match
    }
) {
    return <CheckoutEmailView
        match={match}
    ></CheckoutEmailView>
}

function mapStateToProps(state) {
	return {
		
	}
}

const mapDispatchToProps = {
	
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(CheckoutEmailContainer));
