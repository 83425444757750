import React from 'react';
import { connect } from 'react-redux';
import { getOrder } from './actions';
import CheckoutSuccessView from './CheckoutSuccessView';

function CheckoutSuccessContainer({
	getOrder,
	order,
	match
}
) {
	return (
		<CheckoutSuccessView
			match={match}
			getOrder={getOrder}
			order={order}
		/>
	)
}

function mapStateToProps(state) {
	return {
		order: state.order
	}
}

const mapDispatchToProps = {
	getOrder
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CheckoutSuccessContainer);
