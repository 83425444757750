import React from "react";
import { readConfigFile } from "typescript";
import { ReactComponent as IconLabel } from '../../../../assets/label.svg';
import { ReactComponent as LogoIbk } from '../../../../assets/ibk-logo.svg';
import {
    getDiscount,
} from '../../../../services/Service';
import * as $ from 'jquery';

class Summary extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        showPrices: true,
        totalIbk: 0
    }

    componentDidMount = () => {
        this.loadPromo();
        this.getIbkTotal();
    }

    componentWillReceiveProps = () => { }

    loadPromo = () => {
        const { promo } = this.props;
        if (promo) {
            this.setState({
                showPrices: promo.showPrices
            })
        }
    }

    getIbkTotal = async () => {
        // const { checkout } = this.props;

        // let totalIbk = 0;
        // let bin = "998422";
        // let discountModel = {
        //     bin: bin,
        //     installments: 1,
        //     referenceValue: checkout.total,
        //     paymentSystem: 1,
        //     customerId: window.localStorage.getItem("__customerId__")
        // }

        // let discount = await getDiscount(checkout.draftId, discountModel);
        // if (discount.data) {
        //     totalIbk = discount.data.total;
        // }

        // console.log(totalIbk);

        // this.setState({
        //     totalIbk: totalIbk
        // });
    }

    getItemQuantity = (item) => {
        const { checkout } = this.props;

        let externalCart = checkout.externalCart.map((el, i) => {
            el.shippingData = JSON.parse(el.externalJsonCart);
            return el;
        })
        let quantity = 0;

        if (externalCart) {
            externalCart.forEach((cart, i) => {
                cart.shippingData.items.forEach((el, i) => {
                    if (item.productId === el.id)
                        quantity = el.quantity;
                })
            })
        }

        return quantity;
    }

    renderCart = () => {
        const { checkout, start, ibkPrices, plinPrices } = this.props;
        console.log("🚀 ~ file: Summary.js:84 ~ Summary ~ ibkPrices:", ibkPrices)
        const { showPrices } = this.state;

        let cart = "";
        let items = [];

        if (!checkout) {
            items = start ? start.items : [];
        } else {
            items = checkout ? checkout.items : [];
        }

        if (start.loading) {
            return (
                <tr key={'00'}>
                    <td colSpan="3" className="text-center main-text">
                        <i className="fas fa-spinner fa-pulse d-block"></i>
                        Cargando items
                    </td>
                </tr>
            )
        }


        if (items) {
            let shippingItems = (checkout && checkout.shippingData) ? checkout.shippingData.items : [];
            items.forEach((el, k) => {
                let i = shippingItems.filter((x, j) => {
                    return el.productId == x.id
                });

                if (i.length > 0)
                    i = i[0];

                el.availability = i.availability;
            })

            if (checkout && checkout.externalCart) {
                items.forEach((p, j) => {
                    let externalCart = checkout.externalCart;
                    if (ibkPrices) {
                        let ibkPrice = ibkPrices.find((ibk) => {
                            return p.productId === ibk.id
                        });

                        if (ibkPrice)
                            p.ibkPrice = ibkPrice.price;
                    }
                    if (plinPrices) {
                        let productPlinPrice = plinPrices.find((plin) => {
                            return p.productId == plin.id
                        });
                        console.log("🚀 ~ Summary ~ productPlinPrice ~ productPlinPrice:", productPlinPrice)
                        if (productPlinPrice) {
                            p.plinPrice = productPlinPrice.price;
                            // p.plinPrice = productPlinPrices.reduce((accumulator, currentValue) => {
                            //     return accumulator + currentValue.sellingPrice * currentValue.quantity;
                            //   }, 0);
                        }
                    }

                    externalCart.forEach((c, k) => {
                        let cart = JSON.parse(c.externalJsonCart);
                        let index = 0;
                        let cartItems = cart.items;
                        let lInfo = cart.shippingData ? cart.shippingData.logisticsInfo : cart.logisticsInfo;

                        cartItems.forEach((cItem, m) => {
                            if (p.productId === cItem.id) {
                                index = cItem.requestIndex
                            }
                        })

                        lInfo.forEach((item, l) => {
                            if (index === item.itemIndex) {

                                if (!item.slas || item.slas.length === 0) {
                                    p.outOfStock = true;
                                }
                            }
                        })
                    })
                })
            }

            cart = items.map((item) => {
                return (
                    <React.Fragment key={item.draftItemId}>
                        <div className="checkout__body__summary__product">
                            <div className="checkout__body__summary__product__image">
                                <img src={item.productImage} alt="" />
                            </div>
                            <div className="checkout__body__summary__product__info">
                                <div className="name">{item.productName}</div>
                                <div className="seller">{item.sellerName}</div>
                                <div className="quantity">Cantidad: {this.getItemQuantity(item)}</div>
                                {
                                    (item.total > 0 && showPrices) &&
                                    <React.Fragment>
                                        <div className="price">
                                            {
                                                (item.basePrice > item.price) &&
                                                <React.Fragment>
                                                    <del>{this.formatMoney(item.basePrice * this.getItemQuantity(item))}</del>
                                                    <span>&nbsp;&nbsp;</span>
                                                </React.Fragment>
                                            }
                                            {
                                                this.formatMoney(item.price * this.getItemQuantity(item))
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                                {
                                    (item.ibkPrice > -1 && showPrices && item.ibkPrice < item.price) &&
                                    <React.Fragment>
                                        <div className="ibk-price">
                                            <img src="https://mercury.vteximg.com.br/arquivos/ibk-checkout-price.png" alt="" />
                                            {
                                                (item.ibkPrice > 0) &&
                                                this.formatMoney(item.ibkPrice * item.quantity)
                                            }
                                            {
                                                (item.ibkPrice === 0) &&
                                                <span>Gratis</span>
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                                 {
                                    (item.plinPrice > -1 && showPrices && item.plinPrice / item.quantity < item.price) &&
                                    <React.Fragment>
                                        <div className="plin-price">
                                            <img src="https://mercury.vteximg.com.br/arquivos/plin-logo-967A4AF583-seeklogo%201.png" alt="" />
                                            {
                                                (item.plinPrice > 0) &&
                                                this.formatMoney(item.plinPrice) // * item.quantity
                                            }
                                            {
                                                (item.plinPrice === 0) &&
                                                <span>Gratis</span>
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                                {
                                    (item.services.length > 0) &&
                                    <div className="services">
                                        {
                                            item.services.map((service, j) => {
                                                return (
                                                    <div className="services__item" key={`service-` + j}>
                                                        <div className="services__item__name">+ {service.name}</div>
                                                        <div className="services__item__price">{this.formatMoney(service.price)}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                {/* {this.getProductAvailability(item)} */}
                            </div>
                        </div>
                        {/* {(item.services && item.services.length > 0) &&
                            item.services.map((ad) => {
                                return (
                                    <tr key={ad.id}>
                                        <td className="p-0 border-top-0"></td>
                                        <td className="p-0 border-top-0" colSpan="2">
                                            <div className="d-flex justify-content-between additional-item">
                                                <small>{ad.name}</small>
                                                <span>S/ {(ad.price).toFixed(2)}</span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        } */}
                    </React.Fragment>
                )
            })
        } else {
            return (
                <span>No hay Items en el carrito</span>
            )
        }

        return (<React.Fragment>{cart}</React.Fragment>);
    }

    setHttps = (url) => {
        if (url) {
            return url.replace("http:", "https:")
        }
    }

    formatMoney = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' }).format(amount) : 'S/ 0.00';
    }

    formatNumber = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE').format(amount) : '0';
    }

    calculateRestAmount = () => {
        const { checkout, miles, totalCheckout } = this.props;

        if (!miles) {
            // if (totalCheckout && totalCheckout > 0)
            //     return totalCheckout;
            return checkout.total;
        }

        if (miles.type != 2) {
            return checkout.total;
        }

        let rest = (checkout.total - (miles.selected / miles.change));

        if (rest < 0) {
            rest = 0;
        }

        return rest;
    }

    calculateTotalIbk = () => {
        const { ibkPrices, checkout, start, form } = this.props;
        console.log("🚀 ~ file: Summary.js:279 ~ Summary ~ ibkPrices:", ibkPrices)

        let items = [];

        if (!checkout) {
            items = start ? start.items : [];
        } else {
            items = checkout ? checkout.items : [];
        }

        let total = 0;
        // ibkPrices.forEach(el => {
        //     total += el.price;
        // });
        if (checkout && checkout.externalCart) {
            items.forEach((p, j) => {
                let externalCart = checkout.externalCart;

                if (ibkPrices) {
                    let ibkPrice = ibkPrices.find((ibk) => {
                        return p.productId === ibk.id
                    });

                    if (ibkPrice && ibkPrice.price >= 0)
                        total += ibkPrice.price * p.quantity;
                    else
                        total += p.price * p.quantity;
                }
            })
        }
        if (form == "payment") {
            return total + checkout.shippingCost;
        } else {
            return total;
        }
        
    }

    calculateTotalPlin = () => {
        const { plinPrices, checkout, start, form } = this.props;
        console.log("🚀 ~ file: Summary.js:279 ~ Summary ~ ibkPrices:", plinPrices)

        let items = [];

        if (!checkout) {
            items = start ? start.items : [];
        } else {
            items = checkout ? checkout.items : [];
        }

        let total = 0;
        // ibkPrices.forEach(el => {
        //     total += el.price;
        // });
        if (checkout && checkout.externalCart) {
            items.forEach((p, j) => {
                let externalCart = checkout.externalCart;

                if (plinPrices) {
                    let plinPrice = plinPrices.find((plin) => {
                        return p.productId === plin.id
                    });

                    if (plinPrice && plinPrice.price >= 0)
                        total += plinPrice.price// * p.quantity;
                    else
                        total += p.price * p.quantity;
                }
            })
        }
        console.log(total, checkout.shippingCost)
        if (form == "payment") {
            return total + checkout.shippingCost;
        } else {
            return total;
        }
        
    }

    calculateIbkDiscountPercent = () => {
        const { checkout } = this.props;
        if (checkout.ibkVtexTotal === checkout.subTotal)
            return 0;

        let discount = 1 - (checkout.ibkVtexTotal / checkout.subTotal);

        discount = discount * 100;

        if (discount > 1)
            discount = Math.round(discount);

        return discount;
    }

    formatMoney = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' }).format(amount) : 'S/ 0.00';
    }

    existUnavailableProducts = () => {
        const { checkout } = this.props;
        let externalCart = checkout.externalCart;
        let availability = false;

        if (externalCart && externalCart.length > 0) {
            externalCart.forEach((el, i) => {
                let cart = JSON.parse(el.externalJsonCart);
                if (cart) {
                    let items = cart.items;
                    items.forEach((p, j) => {
                        if (p.availability && p.availability === "cannotBeDelivered") {
                            availability = true;
                        }
                    })
                }
            })
        }

        return availability;
    }

    renderShipping = () => {
        const { checkout } = this.props;

        let carts = checkout.externalCart;
        let shippingDiscountValue = 0;
        let freeShipping = null;

        carts.forEach(cart => {
            if (cart.externalJsonShipping) {
                let jsonCart = JSON.parse(cart.externalJsonShipping);
                let rates = jsonCart.ratesAndBenefitsData.rateAndBenefitsIdentifiers;
                let shippingDiscount = rates.find(rate => {
                    return rate.name.indexOf('DSCTO DELIVERY') > -1;
                });

                freeShipping = rates.find(rate => {
                    return rate.name.indexOf('Envio gratis') === 0;
                });


                if (shippingDiscount) {
                    shippingDiscountValue = parseFloat(shippingDiscount.name.substring(shippingDiscount.name.indexOf('S/.') + 3));

                    if (isNaN(shippingDiscountValue)) {
                        shippingDiscountValue = 0;
                    }
                }
            }
        });

        if (shippingDiscountValue > 0 && freeShipping !== null) {
            let shippingValue = 0;
            let shippingLabels = $(document).find('.shippingCost');

            $.each(shippingLabels, (i, el) => {
                let value = parseFloat($(el).html());

                if (!isNaN(value)) {
                    shippingValue += value;
                }
            });

            if (shippingDiscountValue > shippingValue) {
                shippingDiscountValue = shippingValue;
            }

            if (shippingValue > 0) {
                return (
                    <React.Fragment>
                        <div className="checkout__body__summary__item">
                            <span>Costo de envio</span>
                            <span>{this.formatMoney(shippingValue)}</span>
                        </div>
                        {
                            (shippingDiscountValue > 0) &&
                            <div className="checkout__body__summary__item">
                                <span>Descuento en costo de envio</span>
                                <span>{this.formatMoney(-1 * shippingDiscountValue)}</span>
                            </div>
                        }
                    </React.Fragment>
                )
            }
        } else {
            if (checkout.shippingCost > 0) {
                return (
                    <React.Fragment>
                        <div className="checkout__body__summary__item">
                            <span>Costo de envio</span>
                            <span>{this.formatMoney(checkout.shippingCost)}</span>
                        </div>
                    </React.Fragment>
                )
            }
        }
    }

    getProductAvailability = (product) => {
        const { checkout } = this.props;

        let externalCart = checkout.externalCart;
        let availability = "";

        if (externalCart && externalCart.length > 0) {
            externalCart.forEach((el, i) => {
                let cart = JSON.parse(el.externalJsonShipping);

                if (!cart)
                    cart = JSON.parse(el.externalJsonCart);

                if (cart) {
                    let messages = cart.messages;
                    let items = cart.items;
                    items.forEach((p, j) => {
                        let hasMessage = messages.find((m) => {
                            return m.fields.skuName === p.skuName || m.fields.itemIndex == p.requestIndex;
                        });

                        // product.productId === p.id && p.availability && p.availability === "cannotBeDelivered"
                        if (product.productId === p.id && hasMessage && hasMessage.code === 'cannotBeDelivered') {
                            p.index = j;

                            availability = (
                                <React.Fragment>
                                    <div className="w-100"></div>
                                    {/* <div className="unavailable-product-message">
                                        Este producto no se encuentra disponible para la dirección de envío. Elimínalo desde el <a href="https://shopstar.pe/checkout/#/cart">carrito</a>.
                                    </div> */}
                                    <div className="unavailable-product-message">
                                        Este producto no se encuentra disponible para la dirección de envío. <a role="button" onClick={(e) => { this.deleteCartItem(p); }}>Eliminar</a>.
                                    </div>
                                </React.Fragment>
                            )
                        }
                    })
                }
            })
        }

        return availability;
    }

    deleteCartItem = (product) => {
        const { handleDeleteCartItem } = this.props;

        handleDeleteCartItem(product);
    }

    render() {
        const { checkout, totalCheckout, ibkPrices, plinPrices, form } = this.props;
        const { showPrices, totalIbk } = this.state;
        const total = totalCheckout && totalCheckout > 0 ? totalCheckout : checkout.total;
        console.log('chk.tot', checkout.total);
        return (
            <div className="checkout__body__summary">
                <div className="card">
                    {/* <div className="card-header">
                        <div className="coupon-form">
                            <label htmlFor="coupon">¿Tienes un cupón de descuento?</label>
                            <div className="form-group">
                                <input id="coupon" type="text" className="form-control" />
                                <button className="btn btn-main">Aplicar</button>
                            </div>
                        </div>
                    </div> */}
                    <div className="card-body">
                        <h4>Resumen</h4>
                        {this.renderCart()}
                        {
                            checkout &&
                            (
                                <React.Fragment>
                                    {
                                        (showPrices) &&
                                        <React.Fragment>
                                            {
                                                (checkout.subTotal > 0) &&
                                                <React.Fragment>
                                                    <div className="separator"></div>
                                                    <div className="checkout__body__summary__item">
                                                        <span>Subtotal productos</span>
                                                        <span>{this.formatMoney(checkout.subTotal)}</span>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {/* {this.renderShipping()} */}
                                            {
                                                (checkout.shippingCost > 0 && (form == 'payment')) &&
                                                <React.Fragment>
                                                    <div className="separator"></div>
                                                    <div className="checkout__body__summary__item">
                                                        <span>Costo de envio</span>
                                                        <span>{this.formatMoney(checkout.shippingCost)}</span>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {
                                                (checkout.shippingCost == 0 && (form == 'payment')) &&
                                                <React.Fragment>
                                                    <div className="separator"></div>
                                                    <div className="checkout__body__summary__item">
                                                        <span>Costo de envio</span>
                                                        <span><b>GRATIS</b></span>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {
                                                (checkout.discount < 0) &&
                                                <React.Fragment>
                                                    <div className="checkout__body__summary__item">
                                                        <span>Descuento </span>
                                                        <span style={{ color: '#D35555', textDecoration: 'line-through' }}>{this.formatMoney(checkout.discount)}</span>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {
                                                (!this.existUnavailableProducts()) &&
                                                <React.Fragment>
                                                    <div className="separator"></div>
                                                    <div className="checkout__body__summary__item">
                                                        <span>Total</span>
                                                        {
                                                            (form == "user" || form == "address" || form == "shipping") && 
                                                            <b>{this.formatMoney(checkout.total - checkout.shippingCost)}</b>
                                                        }
                                                        {
                                                            (form == "payment") && 
                                                            <b>{this.formatMoney(this.calculateRestAmount())}</b>
                                                        }
                                                    </div>
                                                    {
                                                        (ibkPrices && ibkPrices.length > 0 && checkout.total >= this.calculateTotalIbk()) &&
                                                        <React.Fragment>
                                                            <div className="separator"></div>
                                                            <div className="checkout__body__summary__item">
                                                                <span style={{ color: '#05be50' }}>Total con Interbank</span>
                                                                <b className="total-ibk">
                                                                    <img src="https://mercury.vteximg.com.br/arquivos/ibk-checkout-price.png" alt="" />
                                                                    {this.formatMoney(this.calculateTotalIbk())}
                                                                </b>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                    {
                                                        (plinPrices && plinPrices.length > 0 && checkout.total >= this.calculateTotalPlin() && this.calculateTotalPlin() <= 500) &&
                                                        <React.Fragment>
                                                            <div className="checkout__body__summary__item">
                                                                <span style={{ color: '#009FE3' }} >Total con Plin</span>
                                                                <b className="total-plin" style={{ color: '#009FE3', fontSize: '16px' }}>
                                                                    <img src="https://mercury.vteximg.com.br/arquivos/plin-logo-967A4AF583-seeklogo%201.png" alt="" />
                                                                    {this.formatMoney(this.calculateTotalPlin())}
                                                                </b>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                    }
                                    {
                                        (!showPrices) &&
                                        <React.Fragment>
                                            <div className="separator"></div>
                                            <div className="checkout__body__summary__item">
                                                <span>Total</span>
                                                <b>Gratis</b>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {
                                        (this.existUnavailableProducts()) &&
                                        <React.Fragment>
                                            <div className="separator"></div>
                                            <div className="checkout__body__summary__item">
                                                <span>Total</span>
                                                <b>No disponible</b>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {/* {
                                        ((totalIbk + checkout.shippingCost) < checkout.total && totalIbk > 0) &&
                                        <div className="ibk-discount">
                                            <span>
                                                <span>Total pagando con Interbank</span>
                                                <br />
                                                <span>
                                                    <IconLabel></IconLabel>
                                                    &nbsp;
                                                    {this.formatMoney(total - totalIbk)} de descuento
                                                </span>
                                            </span>
                                            <span>
                                                <b>{this.formatMoney(totalIbk)}</b>
                                            </span>
                                        </div>
                                    } */}
                                </React.Fragment>
                            )
                        }
                        {/* {
                            (showPrices) &&
                            <div className="ibk-discount">
                                Los descuentos <LogoIbk></LogoIbk> lo verás reflejados al momento de ingresar tu tarjeta en el paso 4
                            </div>
                        } */}
                    </div>
                    {/* {
                        (total > 250) &&
                        <div className="card-footer">
                            <div className="help-text">
                                Obtén S/250 de dsct. solicitando <a target="_blank" href="https://tarjetadigital.interbank.pe/?hsede=online&htienda=shopstar"> tu tarjeta de crédito Interbank</a>.
                            </div>
                        </div>
                    } */}
                </div>
                {
                    (form == "shipping" || form == "user" || form == "address") && 
                    <div className="card shp-msg-plin">
                        <div className="card-body">
                            El costo del envi&oacute; se añadir&aacute; en el <b>resumen</b> cuando haya elegido el <b> m&eacute;todo de entrega.</b>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Summary;
