import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { Provider } from 'react-redux'
import createLogger, { logger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import { render } from 'react-dom'
import './index.css';
import App from './App';
import { checkoutPost, checkoutGet, userPut, shippingPut, cartItem, address, start, ibkAuth, ibkMiles, exchange, userInterbank, installments } from './components/checkout/reducers/checkout';
import { order } from './components/checkout-success/reducers';
import { transactionPost, authorizePost, embluePost, invalidatePost, milesToken } from './components/checkout/reducers/transaction';
import * as serviceWorker from './serviceWorker';
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';

//ReactDOM.render(<App />, document.getElementById('root'));

//// If you want your app to work offline and load faster, you can change
//// unregister() to register() below. Note this comes with some pitfalls.
//// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

//const middleware = [thunk];
//if (process.env.NODE_ENV !== 'production') {
//	middleware.push(createLogger());
//}

const reducer = combineReducers({
	checkoutPost,
	checkoutGet,
	userPut,
	transactionPost,
	authorizePost,
	shippingPut,
	cartItem,
	address,
	start,
	order,
	ibkAuth,
	ibkMiles,
	exchange,
	userInterbank,
	installments,
	embluePost,
	invalidatePost,
	milesToken
})

const store = createStore(
	reducer,
	applyMiddleware(thunkMiddleware, logger)
)

render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
)
