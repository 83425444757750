import moment from 'moment';
import React, { Component } from 'react';

class DeliveryExpress extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
            deliveryType: props.deliveryExpressSla && props.deliveryExpressSla.id === 'Entrega hoy shopstar' ? 'SameDay' : 'NextDay'
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.deliveryType !== this.props.deliveryType) {
            this.setState({ deliveryType: this.props.deliveryExpressSla && this.props.deliveryExpressSla.id === 'Entrega hoy shopstar' ? 'SameDay' : 'NextDay'});
        }
    }

    render() {
        const { deliveryType } = this.state;

        const today = moment().format('LL'); 
        const nextDay = moment().add(1, 'days').format('LL'); 

        return (
            <div>
                {deliveryType === 'SameDay' ? <div className="card shipping-days">
                    <div className="card-body">
                        <i className="far fa-dot-circle mr-2 text-main"></i>
                        Recíbelo hoy {today} si lo pides antes de las 12:00 pm
                    </div>
                </div> : <div className="card shipping-days">
                    <div className="card-body">
                        <i className="far fa-dot-circle mr-2 text-main"></i>
                        Recíbelo mañana {nextDay} si lo pides después de las 12:00 pm
                    </div>
                </div>}
            </div>
        );
    }
}

export default DeliveryExpress;