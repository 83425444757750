import React from "react";
import {
    validateEmail
} from '../../../../services/Service';

class UserForm extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        email: "",
        firstName: "",
        lastName: "",
        identificationNumber: "",
        identificationType: "1",
        phone: "",
        documentNumberLength: 8,
        disabledEmail: true,
        disabledIdentification: true,
        errors: {},
        serverErrorMessage: ""
    }

    componentDidMount = () => {
        const { checkout, email, identificationNumber } = this.props;
        let user = this.state;
        let disabledIdentification = true;
        let documentNumberLength = 8;

        user.email = checkout.customerEmail || "";
        user.firstName = checkout.customerFirstName || "";
        user.lastName = checkout.customerLastName || "";
        user.phone = checkout.customerPhone || "";
        user.identificationType = checkout.customerIdentificationType || "1";
        user.identificationNumber = checkout.customerIdentificationNumber || "";

        if (user.email.indexOf("*") > -1 && !checkout.customerIdentificationNumber) {
            user.email = email;
            user.identificationNumber = identificationNumber;
            if(identificationNumber && identificationNumber !== '') {
                if(identificationNumber.length === 8) {
                    user.identificationType = '1';
                } else {
                    user.identificationType = '2';
                    documentNumberLength = 9
                }
            }
        }

        if (!user.identificationNumber || user.identificationNumber === '') {
            disabledIdentification = false;
        }

        this.setState({
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            identificationType: user.identificationType,
            identificationNumber: user.identificationNumber,
            phone: user.phone,
            disabledIdentification: disabledIdentification,
            documentNumberLength: documentNumberLength
        })

        if (checkout.customerIdentificationNumber && checkout.customerIdentificationNumber !== "") {
            this.fsIdentify();
        }
    }

    fsIdentify = () => {
        let user = this.state;

        if (window['_fs_namespace'] && window['_fs_namespace']['identify']) {
            window['_fs_namespace']['identify'](user.identificationNumber, {
                displayName: user.firstName + ' ' + user.lastName,
                email: user.email
            });
        }
    }

    toggleFieldError = (name, value) => {
        const { errors } = this.state;
        let _errors = errors;

        _errors[name] = value;
        this.setState({ errors: _errors });
    }

    validState = () => {
        let state = this.state;
        let valid = true;
        let errors = {};
        let requiredFields = ["email", "firstName", "lastName", "identificationNumber", "identificationType", "phone"];

        for (let field in state) {
            if (requiredFields.indexOf(field) > -1 && (!state[field] || state[field] === "")) {
                errors[field] = true;
                valid = false;
            }
            if (field === 'phone') {
                if (state[field].length > 11 || state[field].length < 9) {
                    errors[field] = true;
                    valid = false;
                }
            }
            if (field === 'identificationNumber') {
                if (state[field].length !== state.documentNumberLength) {
                    errors[field] = true;
                    valid = false;
                }
            }
            if (field === 'email') {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(state[field])) {
                    errors[field] = true;
                    valid = false;
                }
            }
        }

        this.setState({ errors });
        return valid;
    }

    setDocumentNumberLength = (event) => {
        let value = event.target.value
        let length = 8;

        if (value == '2') {
            length = 9;
        }

        this.setState({
            documentNumberLength: length
        })
    }

    handleChangeValue = (field, event) => {
        const { identificationType } = this.state;
        let value = event.target.value

        if (field === 'phone' || (field === 'identificationNumber' && identificationType === '1')) {
            var re = /^[0-9]*$/;
            if (!re.test(value))
                return;
        }

        if (field === 'firstName' || field === 'lastName') {
            let reLetters = /^[a-zA-Z\s]*$/;
            if (!reLetters.test(value))
                return;
        }

        if (!value || value === "") {
            this.toggleFieldError(field, true)
        } else {
            this.toggleFieldError(field, false)
        }

        this.setState({
            [field]: value
        });
    }

    handleSubmitUser = async () => {
        const user = this.state;
        const { handleSubmitUser } = this.props;

        let valid = this.validState();

        if (!valid)
            return;

        const response = await handleSubmitUser(user);

        if (response.status === 403) {
            
            this.setState({
                serverErrorMessage: response.message || "You are blacklisted and cannot proceed.",
            });
            return;
        }
    }

    handleEditUser = () => {
        const { handleEditUser } = this.props;
        const { email } = this.state;

        if (email.indexOf("*") > -1) {
            this.setState({
                email: "",
                firstName: "",
                lastName: "",
                identificationNumber: "",
                identificationType: "1",
                phone: "",
            })
        }

        this.setState({
            disabledEmail: false,
            disabledIdentification: false
        });

        handleEditUser();
    }

    render() {
        const { completed, loaders, promo } = this.props;
        const { errors, documentNumberLength, serverErrorMessage } = this.state;

        return (
            <form className="checkout__body__form">
                <h3 className="checkout__body__form__title mt-0">
                    <span className={"number purple"}>1</span>
                    Información de cliente
                </h3>
                {
                    (!completed) &&
                    <div className="card step-form">
                        <div className="card-body">

                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className={'form-group ' + (errors.firstName ? 'invalid' : '')}>
                                            <label>Nombre</label>
                                            <input type="text" className="form-control"
                                                placeholder="Ingresa tu nombre"
                                                value={this.state.firstName}
                                                onChange={(e) => this.handleChangeValue('firstName', e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className={'form-group ' + (errors.lastName ? 'invalid' : '')}>
                                            <label>Apellido</label>
                                            <input type="text" className="form-control"
                                                placeholder="Ingresa tu apellido"
                                                value={this.state.lastName}
                                                onChange={(e) => this.handleChangeValue('lastName', e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Documento de identidad</label>
                                            <div className="row">
                                                <div className={'col-md-6 col-6 ' + (errors.identificationType ? 'invalid' : '')}>
                                                    <select className="form-control" value={this.state.identificationType}
                                                        disabled={this.state.disabledIdentification}
                                                        onChange={(e) => { this.handleChangeValue('identificationType', e); this.setDocumentNumberLength(e) }}>
                                                        <option value="1">DNI</option>
                                                        <option value="2">CE</option>
                                                    </select>
                                                </div>
                                                <div className={'col-md-6 col-6 ' + (errors.identificationNumber ? 'invalid' : '')}>
                                                    <input type="text" className="form-control"
                                                        placeholder="N&uacute;mero de documento"
                                                        maxLength={documentNumberLength}
                                                        value={this.state.identificationNumber}
                                                        // readOnly={promo ? true : false}
                                                        onChange={(e) => this.handleChangeValue('identificationNumber', e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className={'form-group ' + (errors.email ? 'invalid' : '')}>
                                            <label>Correo electrónico</label>
                                            <input type="text" className="form-control"
                                                placeholder="correo@shopstar.com.pe"
                                                value={this.state.email}
                                                readOnly={this.state.disabledEmail}
                                                onChange={(e) => this.handleChangeValue('email', e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className={'form-group ' + (errors.phone ? 'invalid' : '')}>
                                            <label>Celular</label>
                                            <input type="text" className="form-control"
                                                placeholder="Ingresa tu n&uacute;mero de celular"
                                                maxLength="11"
                                                value={this.state.phone}
                                                onChange={(e) => this.handleChangeValue('phone', e)} />
                                        </div>
                                    </div>
                                </div>
                                {serverErrorMessage && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="alert alert-danger">{serverErrorMessage}</div>
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mb-0">
                                            <button disabled={loaders.user} type="button" className="btn btn-main" onClick={this.handleSubmitUser}>
                                                Continuar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                }

                {
                    completed &&
                    <div className="card">
                        <div className="card-body">
                            <React.Fragment>
                                <div className="checkout__body__form__summary">
                                    <div className="checkout__body__form__summary__item d-flex justify-content-between">
                                        <span>Estos son tus datos</span>
                                        <button type="button" className="btn btn-edit btn-outline-main" onClick={(e) => { this.handleEditUser() }}>Editar</button>
                                    </div>
                                    <div className="checkout__body__form__summary__item">
                                        <b>Nombre y apellido</b>
                                        <span>{this.state.firstName} {this.state.lastName}</span>
                                    </div>
                                    <div className="checkout__body__form__summary__item">
                                        <b>Documento de identidad</b>
                                        <span>DNI - {this.state.identificationNumber}</span>
                                    </div>
                                    <div className="checkout__body__form__summary__item">
                                        <b>Correo electrónico</b>
                                        <span>{this.state.email}</span>
                                    </div>
                                    <div className="checkout__body__form__summary__item">
                                        <b>Celular</b>
                                        <span>{this.state.phone}</span>
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                }
            </form>
        )
    }
}

export default UserForm;