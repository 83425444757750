import React from "react";
import * as $ from 'jquery';
import { ReactComponent as IconHelp } from './../../../assets/help.svg';
import { ReactComponent as IconIbk } from './../../../assets/ibk-ico-bg.svg';

class Summary extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        showPrices: true,
        totalIbk: 0,
        collapsed: false
    }

    componentDidMount = () => {
        this.loadPromo();
        this.getIbkTotal();

        let width = window.innerWidth;
        if (width < 480) {
            this.setState({
                collapsed: true
            })
        }
    }

    componentWillReceiveProps = () => { }

    loadPromo = () => {
        const { promo } = this.props;
        if (promo) {
            this.setState({
                showPrices: promo.showPrices
            })
        }
    }

    getIbkTotal = async () => {
        // const { checkout } = this.props;

        // let totalIbk = 0;
        // let bin = "998422";
        // let discountModel = {
        //     bin: bin,
        //     installments: 1,
        //     referenceValue: checkout.total,
        //     paymentSystem: 1,
        //     customerId: window.localStorage.getItem("__customerId__")
        // }

        // let discount = await getDiscount(checkout.draftId, discountModel);
        // if (discount.data) {
        //     totalIbk = discount.data.total;
        // }

        // console.log(totalIbk);

        // this.setState({
        //     totalIbk: totalIbk
        // });
    }

    renderCart = () => {
        const { checkout, start, ibkPrices } = this.props;
        const { showPrices } = this.state;

        let cart = "";
        let items = [];

        if (!checkout) {
            // items = start ? start.items : [];
        } else {
            items = checkout ? checkout.items : [];
        }

        // if (start.loading) {
        //     return (
        //         <tr key={'00'}>
        //             <td colSpan="3" className="text-center main-text">
        //                 <i className="fas fa-spinner fa-pulse d-block"></i>
        //                 Cargando items
        //             </td>
        //         </tr>
        //     )
        // }

        if (items) {
            let shippingItems = (checkout && checkout.shippingData) ? checkout.shippingData.items : [];
            items.forEach((el, k) => {
                let i = shippingItems.filter((x, j) => {
                    return el.productId == x.id
                });

                if (i.length > 0)
                    i = i[0];

                el.availability = i.availability;
            })

            if (checkout && checkout.externalCart) {
                items.forEach((p, j) => {
                    let externalCart = checkout.externalCart;
                    // console.log(p);
                    // if (ibkPrices) {
                    //     let ibkPrice = ibkPrices.find((ibk) => {
                    //         return p.productId === ibk.id
                    //     });

                    //     console.log(ibkPrice)
                    //     p.ibkPrice = ibkPrice.price;
                    // }

                    externalCart.forEach((c, k) => {
                        let cart = JSON.parse(c.externalJsonCart);
                        let index = 0;
                        let cartItems = cart.items;
                        let lInfo = cart.shippingData ? cart.shippingData.logisticsInfo : cart.logisticsInfo;

                        cartItems.forEach((cItem, m) => {
                            if (p.productId === cItem.id) {
                                index = cItem.requestIndex
                            }
                        })

                        lInfo.forEach((item, l) => {
                            if (index === item.itemIndex) {

                                if (!item.slas || item.slas.length === 0) {
                                    p.outOfStock = true;
                                }
                            }
                        })
                    })
                })
            }

            cart = items.map((item) => {
                return (
                    <React.Fragment key={item.draftItemId}>
                        <div className="summary__product">
                            <div className="summary__product__info">
                                <div className="name">{item.productName}</div>
                                <div className="seller">{item.sellerName}</div>
                                <div className="quantity">Cantidad: {item.quantity}</div>
                                {
                                    (item.services.length > 0) &&
                                    <div className="services">
                                        {
                                            item.services.map((service, j) => {
                                                return (
                                                    <div className="services__item" key={`service-` + j}>
                                                        <div className="services__item__name">+ {service.name}</div>
                                                        <div className="services__item__price">{this.formatMoney(service.price)}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                            <div className="summary__product__price">
                                {
                                    (item.total > 0 && showPrices) &&
                                    <React.Fragment>
                                        {
                                            (item.basePrice > item.price) &&
                                            <React.Fragment>
                                                {
                                                    this.formatMoney(item.basePrice * item.quantity)
                                                }
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                            <div className="summary__product__special-price">
                                {
                                    (item.total > 0 && showPrices) &&
                                    <React.Fragment>
                                        {
                                            this.formatMoney(item.total)
                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </React.Fragment>
                )
            })
        } else {
            return (
                <span>No hay Items en el carrito</span>
            )
        }

        return (<React.Fragment>{cart}</React.Fragment>);
    }

    setHttps = (url) => {
        if (url) {
            return url.replace("http:", "https:")
        }
    }

    formatMoney = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' }).format(amount) : 'S/ 0.00';
    }

    formatNumber = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE').format(amount) : '0';
    }

    calculateRestAmount = () => {
        const { checkout, miles, totalCheckout } = this.props;

        if (!miles) {
            // if (totalCheckout && totalCheckout > 0)
            //     return totalCheckout;
            return checkout.total;
        }

        if (miles.type != 2) {
            return checkout.total;
        }

        let rest = (checkout.total - (miles.selected / miles.change));

        if (rest < 0) {
            rest = 0;
        }

        return rest;
    }

    calculateTotalIbk = () => {
        const { ibkPrices } = this.props;
        let total = 0;
        ibkPrices.forEach(el => {
            total += el.price;
        });

        return total;
    }

    calculateIbkDiscountPercent = () => {
        const { checkout } = this.props;
        if (checkout.ibkVtexTotal === checkout.subTotal)
            return 0;

        let discount = 1 - (checkout.ibkVtexTotal / checkout.subTotal);

        discount = discount * 100;

        if (discount > 1)
            discount = Math.round(discount);

        return discount;
    }

    formatMoney = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' }).format(amount) : 'S/ 0.00';
    }

    existUnavailableProducts = () => {
        const { checkout } = this.props;
        let externalCart = checkout.externalCart;
        let availability = false;

        if (externalCart && externalCart.length > 0) {
            externalCart.forEach((el, i) => {
                let cart = JSON.parse(el.externalJsonCart);
                if (cart) {
                    let items = cart.items;
                    items.forEach((p, j) => {
                        if (p.availability && p.availability === "cannotBeDelivered") {
                            availability = true;
                        }
                    })
                }
            })
        }

        return availability;
    }

    renderShipping = () => {
        const { checkout } = this.props;

        let carts = checkout.externalCart;
        let shippingDiscountValue = 0;
        let freeShipping = null;

        carts.forEach(cart => {
            if (cart.externalJsonShipping) {
                let jsonCart = JSON.parse(cart.externalJsonShipping);
                let rates = jsonCart.ratesAndBenefitsData.rateAndBenefitsIdentifiers;
                let shippingDiscount = rates.find(rate => {
                    return rate.name.indexOf('DSCTO DELIVERY') > -1;
                });

                freeShipping = rates.find(rate => {
                    return rate.name.indexOf('Envio gratis') === 0;
                });


                if (shippingDiscount) {
                    shippingDiscountValue = parseFloat(shippingDiscount.name.substring(shippingDiscount.name.indexOf('S/.') + 3));

                    if (isNaN(shippingDiscountValue)) {
                        shippingDiscountValue = 0;
                    }
                }
            }
        });

        if (shippingDiscountValue > 0 && freeShipping !== null) {
            let shippingValue = 0;
            let shippingLabels = $(document).find('.shippingCost');

            $.each(shippingLabels, (i, el) => {
                let value = parseFloat($(el).html());

                if (!isNaN(value)) {
                    shippingValue += value;
                }
            });

            if (shippingDiscountValue > shippingValue) {
                shippingDiscountValue = shippingValue;
            }

            if (shippingValue > 0) {
                return (
                    <React.Fragment>
                        <div className="summary__item">
                            <span>Costo de envio</span>
                            <span>{this.formatMoney(shippingValue)}</span>
                        </div>
                        {
                            (shippingDiscountValue > 0) &&
                            <div className="summary__item">
                                <span>Descuento en costo de envio</span>
                                <span>{this.formatMoney(-1 * shippingDiscountValue)}</span>
                            </div>
                        }
                    </React.Fragment>
                )
            }
        } else {
            if (checkout.shippingCost > 0) {
                return (
                    <React.Fragment>
                        <div className="summary__item">
                            <span>Costo de envio</span>
                            <span>{this.formatMoney(checkout.shippingCost)}</span>
                        </div>
                    </React.Fragment>
                )
            }
        }
    }

    getProductAvailability = (product) => {
        const { checkout } = this.props;

        let externalCart = checkout.externalCart;
        let availability = "";

        if (externalCart && externalCart.length > 0) {
            externalCart.forEach((el, i) => {
                let cart = JSON.parse(el.externalJsonShipping);

                if (!cart)
                    cart = JSON.parse(el.externalJsonCart);

                if (cart) {
                    let messages = cart.messages;
                    let items = cart.items;
                    items.forEach((p, j) => {
                        let hasMessage = messages.find((m) => {
                            return m.fields.skuName === p.skuName || m.fields.itemIndex == p.requestIndex;
                        });

                        // product.productId === p.id && p.availability && p.availability === "cannotBeDelivered"
                        if (product.productId === p.id && hasMessage && hasMessage.code === 'cannotBeDelivered') {
                            p.index = j;

                            availability = (
                                <React.Fragment>
                                    <div className="w-100"></div>
                                    {/* <div className="unavailable-product-message">
                                        Este producto no se encuentra disponible para la dirección de envío. Elimínalo desde el <a href="https://shopstar.pe/checkout/#/cart">carrito</a>.
                                    </div> */}
                                    <div className="unavailable-product-message">
                                        Este producto no se encuentra disponible para la dirección de envío. <a role="button" onClick={(e) => { this.deleteCartItem(p); }}>Eliminar</a>.
                                    </div>
                                </React.Fragment>
                            )
                        }
                    })
                }
            })
        }

        return availability;
    }

    deleteCartItem = (product) => {
        console.log(product);
        const { handleDeleteCartItem } = this.props;

        handleDeleteCartItem(product);
    }

    toggleSummary = () => {
        const { collapsed } = this.state;

        this.setState({
            collapsed: !collapsed
        })
    }

    render() {
        const { checkout, totalCheckout, totalIbk, showShippingCostMessage } = this.props;
        const { showPrices, collapsed } = this.state;

        const total = totalCheckout && totalCheckout > 0 ? totalCheckout : checkout.total;

        return (
            <div className="summary">
                <div className="card">
                    {/* <div className="card-header">
                        <div className="coupon-form">
                            <label htmlFor="coupon">¿Tienes un cupón de descuento?</label>
                            <div className="form-group">
                                <input id="coupon" type="text" className="form-control" />
                                <button className="btn btn-main">Aplicar</button>
                            </div>
                        </div>
                    </div> */}
                    <div className="card-body">
                        <div className={`toggler ` + (collapsed ? 'collapsed' : '')} onClick={this.toggleSummary}>
                            <span>Total a pagar</span>
                            <span className="total">
                                {this.formatMoney(this.calculateRestAmount())}
                                <img src="/select-arrow.svg" alt="" />
                            </span>
                        </div>
                        {
                            (!collapsed) &&
                            (
                                <React.Fragment>
                                    <h4>Resumen de compra</h4>
                                    {this.renderCart()}
                                    {
                                        checkout &&
                                        (
                                            <React.Fragment>
                                                {
                                                    (showPrices) &&
                                                    <React.Fragment>
                                                        {
                                                            (checkout.subTotal > 0) &&
                                                            <React.Fragment>
                                                                <div className="separator"></div>
                                                                <div className="summary__title">Detalles de precio</div>
                                                                <div className="summary__item">
                                                                    <span>Subtotal productos</span>
                                                                    <span>{this.formatMoney(checkout.subTotal)}</span>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                        {/* {this.renderShipping()} */}
                                                        {
                                                            (checkout.shippingCost > 0) &&
                                                            <React.Fragment>
                                                                <div className="summary__item">
                                                                    <span>Costo de envio</span>
                                                                    <span>{this.formatMoney(checkout.shippingCost)}</span>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            (checkout.discount < 0) &&
                                                            <React.Fragment>
                                                                <div className="summary__item">
                                                                    <span>Descuento</span>
                                                                    <span>{this.formatMoney(checkout.discount)}</span>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            (!this.existUnavailableProducts()) &&
                                                            <React.Fragment>
                                                                <div className="separator"></div>
                                                                <div className="summary__item total">
                                                                    <span>Total a pagar</span>
                                                                    <b>{this.formatMoney(this.calculateRestAmount())}</b>
                                                                </div>
                                                                {
                                                                    (totalIbk && totalIbk > 0) && (
                                                                        <div className="summary__item total">
                                                                            <span>Total con Interbank</span>
                                                                            <b className="ibk-total">
                                                                                <IconIbk></IconIbk>
                                                                                {this.formatMoney(totalIbk)}
                                                                            </b>
                                                                        </div>
                                                                    )
                                                                }
                                                                {/* {
                                                        (ibkPrices && ibkPrices.length > 0) &&
                                                        <React.Fragment>
                                                            <div className="separator"></div>
                                                            <div className="summary__item">
                                                                <span>Total con Interbank</span>
                                                                <b className="total-ibk">
                                                                    <img src="https://mercury.vteximg.com.br/arquivos/ibk-checkout-price.png" alt="" />
                                                                    {this.formatMoney(this.calculateTotalIbk())}
                                                                </b>
                                                            </div>
                                                        </React.Fragment>
                                                    } */}
                                                            </React.Fragment>
                                                        }
                                                    </React.Fragment>
                                                }
                                                {
                                                    (!showPrices) &&
                                                    <React.Fragment>
                                                        <div className="separator"></div>
                                                        <div className="summary__item total">
                                                            <span>Total a pagar</span>
                                                            <b>Gratis</b>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                {
                                                    (this.existUnavailableProducts()) &&
                                                    <React.Fragment>
                                                        <div className="separator"></div>
                                                        <div className="summary__item total">
                                                            <span>Total a pagar</span>
                                                            <b>No disponible</b>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                {/* {
                                        ((totalIbk + checkout.shippingCost) < checkout.total && totalIbk > 0) &&
                                        <div className="ibk-discount">
                                            <span>
                                                <span>Total pagando con Interbank</span>
                                                <br />
                                                <span>
                                                    <IconLabel></IconLabel>
                                                    &nbsp;
                                                    {this.formatMoney(total - totalIbk)} de descuento
                                                </span>
                                            </span>
                                            <span>
                                                <b>{this.formatMoney(totalIbk)}</b>
                                            </span>
                                        </div>
                                    } */}
                                            </React.Fragment>
                                        )
                                    }
                                    {
                                        showShippingCostMessage &&
                                        <div className="summary__info">
                                            <IconHelp></IconHelp>
                                            El costo de envío se mostrará en el siguiente paso.
                                        </div>
                                    }
                                </React.Fragment>
                            )
                        }
                    </div>
                    {/* {
                        (total > 250) &&
                        <div className="card-footer">
                            <div className="help-text">
                                Obtén S/250 de dsct. solicitando <a target="_blank" href="https://compradigital.interbank.pe/solicitar/tarjeta/credito/inicio?htienda=shopstar&hsede=online"> tu tarjeta de crédito Interbank</a>.
                            </div>
                        </div>
                    } */}
                </div>
            </div>
        )
    }
}

export default Summary;