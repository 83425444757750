import React from "react";
import { ReactComponent as IconContact } from '../../../../assets/contact.svg';
import { ReactComponent as IconWhatsapp } from '../../../../assets/whatsapp.svg';
import { ReactComponent as IconPhone } from '../../../../assets/phone.svg';
import { ReactComponent as IconEmail } from '../../../../assets/mail.svg';

class Contact extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <div className="contact">
                    <div className="contact__icon">
                        <img src="/customer-service.png" alt="" />
                    </div>
                    <div className="contact__info">
                        <h4>
                            ¿Necesitas ayuda con tu compra?
                    </h4>
                        <p>
                        Conversa con nosotros de Lunes a Viernes de 09:00am a 08:00pm y sábados de 09:00am a 01:00pm.
                    </p>
                        <ul className="contact__info__channels">
                            <li>
                                <IconPhone></IconPhone>
                                Llámanos al <a href="tel:016797700">(01) 679-7700</a>
                            </li>
                            {/* <li>
                                <IconWhatsapp></IconWhatsapp>
                            Escríbenos por <a href="">Whatsapp</a>
                            </li> */}
                            <li>
                                <IconEmail></IconEmail>
                                Mandar una <a href="https://informacion.shopstar.pe/atencion-cliente" target="_blank">solicitud online</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="copyright">
                    &copy; Copyright 2021 Shopstar. Todos los derechos reservados.
                </div>
            </React.Fragment>
        )
    }
}

export default Contact;