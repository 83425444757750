export const cart = {
	"orderFormId": "77639a6cc9ee4e2da5720203ef84cebe",
	"salesChannel": "1",
	"loggedIn": false,
	"isCheckedIn": false,
	"storeId": null,
	"checkedInPickupPointId": null,
	"allowManualPrice": false,
	"canEditData": true,
	"userProfileId": null,
	"userType": null,
	"ignoreProfileData": false,
	"value": 196900,
	"messages": [

	],
	"items": [
		{
			"uniqueId": "6AE14D07700C41849C2812E82D43670D",
			"id": "18215",
			"productId": "17710",
			"refId": "1324706",
			"ean": "2800013247069",
			"name": "Laptop 15.6\" Aspire A315 AMD Ryzen 5 6GB 1TB Negro",
			"skuName": "Laptop 15.6\" Aspire A315 AMD Ryzen 5 6GB 1TB Negro",
			"modalType": null,
			"parentItemIndex": null,
			"parentAssemblyBinding": null,
			"assemblies": [

			],
			"priceValidUntil": "2019-07-13T05:00:00Z",
			"tax": 0,
			"price": 196900,
			"listPrice": 249900,
			"manualPrice": null,
			"sellingPrice": 196900,
			"rewardValue": 0,
			"isGift": false,
			"additionalInfo": {
				"brandName": "Acer",
				"brandId": "390572",
				"offeringInfo": null,
				"offeringType": null,
				"offeringTypeId": null
			},
			"preSaleDate": null,
			"productCategoryIds": "/4/132/172/",
			"productCategories": {
				"4": "Tecnolog�a",
				"132": "Computo",
				"172": "Laptops"
			},
			"quantity": 1,
			"seller": "oechsle",
			"sellerChain": [
				"oechsle"
			],
			"imageUrl": "http://mercury.vteximg.com.br/arquivos/ids/205583-55-55/image-c0f7f228b6be4b4c8c0e19d550d73235.jpg?v=636765320224730000",
			"detailUrl": "/laptop-15-6--aspire-a315-amd-ryzen-5-6gb-1tb-negro-17710/p",
			"components": [

			],
			"bundleItems": [

			],
			"attachments": [

			],
			"attachmentOfferings": [

			],
			"offerings": [
				{
					"type": "Plan de reparaci�n 12 meses ( 1207232 )",
					"id": "13014",
					"name": "Plan de reparaci�n 12 meses ( 1207232 )",
					"allowGiftMessage": false,
					"attachmentOfferings": [

					],
					"price": 16450
				}
			],
			"priceTags": [
				{
					"name": "discount@shipping-e944d386-39a9-4956-bf95-eadbfe760895#24966544-898b-4564-aec9-a0bcf809f587",
					"value": -1416,
					"rawValue": -14.16,
					"isPercentual": false,
					"identifier": "e944d386-39a9-4956-bf95-eadbfe760895"
				}
			],
			"availability": "available",
			"measurementUnit": "un",
			"unitMultiplier": 1
		}
	],
	"selectableGifts": [

	],
	"totalizers": [
		{
			"id": "Items",
			"name": "Total de los items",
			"value": 196900
		},
		{
			"id": "Shipping",
			"name": "Costo total del env�o",
			"value": 0,
			"alternativeTotals": [
				{
					"id": "AlternativeShippingTotal",
					"name": "Alternative Shipping Total",
					"value": 1416
				},
				{
					"id": "AlternativeShippingDiscount",
					"name": "Alternative Shipping Discount",
					"value": -1416
				}
			]
		}
	],
	"shippingData": {
		"address": {
			"addressType": "residential",
			"receiverName": "Fabrizio Cordova",
			"addressId": "4681326418074",
			"postalCode": "150131",
			"city": "Lima",
			"state": "Lima",
			"country": "PER",
			"street": "Avenida Pardo y Aliaga",
			"number": "581",
			"neighborhood": "San Isidro",
			"complement": null,
			"reference": null,
			"geoCoordinates": [
				-77.03258514404297,
				-12.097183227539062
			]
		},
		"logisticsInfo": [
			{
				"itemIndex": 0,
				"selectedSla": "Despacho a Domicilio",
				"selectedDeliveryChannel": "delivery",
				"addressId": "4681326418074",
				"slas": [
					{
						"id": "Despacho a Domicilio",
						"deliveryChannel": "delivery",
						"name": "Despacho a Domicilio",
						"deliveryIds": [
							{
								"courierId": "10c512f",
								"warehouseId": "204",
								"dockId": "20536557858",
								"courierName": "Transporte Oechsle Lima",
								"quantity": 1
							}
						],
						"shippingEstimate": "13h",
						"shippingEstimateDate": null,
						"lockTTL": null,
						"availableDeliveryWindows": [
							{
								"startDateUtc": "2019-07-11T09:00:00+00:00",
								"endDateUtc": "2019-07-11T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-12T09:00:00+00:00",
								"endDateUtc": "2019-07-12T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-13T09:00:00+00:00",
								"endDateUtc": "2019-07-13T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-15T09:00:00+00:00",
								"endDateUtc": "2019-07-15T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-16T09:00:00+00:00",
								"endDateUtc": "2019-07-16T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-17T09:00:00+00:00",
								"endDateUtc": "2019-07-17T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-18T09:00:00+00:00",
								"endDateUtc": "2019-07-18T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-19T09:00:00+00:00",
								"endDateUtc": "2019-07-19T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-20T09:00:00+00:00",
								"endDateUtc": "2019-07-20T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-22T09:00:00+00:00",
								"endDateUtc": "2019-07-22T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-23T09:00:00+00:00",
								"endDateUtc": "2019-07-23T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-24T09:00:00+00:00",
								"endDateUtc": "2019-07-24T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-25T09:00:00+00:00",
								"endDateUtc": "2019-07-25T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-26T09:00:00+00:00",
								"endDateUtc": "2019-07-26T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-27T09:00:00+00:00",
								"endDateUtc": "2019-07-27T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-29T09:00:00+00:00",
								"endDateUtc": "2019-07-29T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-30T09:00:00+00:00",
								"endDateUtc": "2019-07-30T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-07-31T09:00:00+00:00",
								"endDateUtc": "2019-07-31T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-08-01T09:00:00+00:00",
								"endDateUtc": "2019-08-01T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-08-02T09:00:00+00:00",
								"endDateUtc": "2019-08-02T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-08-03T09:00:00+00:00",
								"endDateUtc": "2019-08-03T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-08-05T09:00:00+00:00",
								"endDateUtc": "2019-08-05T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-08-06T09:00:00+00:00",
								"endDateUtc": "2019-08-06T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-08-07T09:00:00+00:00",
								"endDateUtc": "2019-08-07T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							},
							{
								"startDateUtc": "2019-08-08T09:00:00+00:00",
								"endDateUtc": "2019-08-08T20:00:00+00:00",
								"price": 0,
								"lisPrice": 0,
								"tax": 0
							}
						],
						"deliveryWindow": {
							"startDateUtc": "2019-07-12T09:00:00+00:00",
							"endDateUtc": "2019-07-12T20:00:00+00:00",
							"price": 0,
							"lisPrice": 0,
							"tax": 0
						},
						"price": 0,
						"listPrice": 1416,
						"tax": 0,
						"pickupStoreInfo": {
							"isPickupStore": false,
							"friendlyName": null,
							"address": null,
							"additionalInfo": null,
							"dockId": null
						},
						"pickupPointId": null,
						"pickupDistance": null,
						"polygonName": null
					}
				],
				"shipsTo": [
					"PER"
				],
				"itemId": "18215",
				"deliveryChannels": [
					{
						"id": "delivery"
					}
				]
			}
		],
		"selectedAddresses": [
			{
				"addressType": "residential",
				"receiverName": "Fabrizio Cordova",
				"addressId": "4681326418074",
				"postalCode": "150131",
				"city": "Lima",
				"state": "Lima",
				"country": "PER",
				"street": "Avenida Pardo y Aliaga",
				"number": "581",
				"neighborhood": "San Isidro",
				"complement": null,
				"reference": null,
				"geoCoordinates": [
					-77.03258514404297,
					-12.097183227539062
				]
			}
		],
		"availableAddresses": [
			{
				"addressType": "search",
				"receiverName": "Fabrizio Cordova",
				"addressId": "3120884278716",
				"postalCode": "150131",
				"city": "Lima",
				"state": "Lima",
				"country": "PER",
				"street": null,
				"number": null,
				"neighborhood": "San Isidro",
				"complement": null,
				"reference": null,
				"geoCoordinates": [
					-77.03258514404297,
					-12.097183227539062
				]
			},
			{
				"addressType": "residential",
				"receiverName": "Fabrizio Cordova",
				"addressId": "4681326418074",
				"postalCode": "150131",
				"city": "Lima",
				"state": "Lima",
				"country": "PER",
				"street": "Avenida Pardo y Aliaga",
				"number": "581",
				"neighborhood": "San Isidro",
				"complement": null,
				"reference": null,
				"geoCoordinates": [
					-77.03258514404297,
					-12.097183227539062
				]
			}
		],
		"pickupPoints": [

		]
	},
	"clientProfileData": {
		"email": "fabrizio1793@gmail.com",
		"firstName": "Fabrizio",
		"lastName": "Cordova",
		"document": "12312312",
		"documentType": "dni",
		"phone": "+51987987987",
		"corporateName": null,
		"tradeName": null,
		"corporateDocument": null,
		"stateInscription": null,
		"corporatePhone": null,
		"isCorporate": false,
		"profileCompleteOnLoading": false,
		"profileErrorOnLoading": false,
		"customerClass": null
	},
	"paymentData": {
		"installmentOptions": [
			{
				"paymentSystem": "1",
				"bin": null,
				"paymentName": null,
				"paymentGroupName": null,
				"value": 196900,
				"installments": [
					{
						"count": 1,
						"hasInterestRate": false,
						"interestRate": 0,
						"value": 196900,
						"total": 196900,
						"sellerMerchantInstallments": [
							{
								"id": "OECHSLE",
								"count": 1,
								"hasInterestRate": false,
								"interestRate": 0,
								"value": 196900,
								"total": 196900
							}
						]
					}
				]
			},
			{
				"paymentSystem": "2",
				"bin": null,
				"paymentName": null,
				"paymentGroupName": null,
				"value": 196900,
				"installments": [
					{
						"count": 1,
						"hasInterestRate": false,
						"interestRate": 0,
						"value": 196900,
						"total": 196900,
						"sellerMerchantInstallments": [
							{
								"id": "OECHSLE",
								"count": 1,
								"hasInterestRate": false,
								"interestRate": 0,
								"value": 196900,
								"total": 196900
							}
						]
					}
				]
			},
			{
				"paymentSystem": "3",
				"bin": null,
				"paymentName": null,
				"paymentGroupName": null,
				"value": 196900,
				"installments": [
					{
						"count": 1,
						"hasInterestRate": false,
						"interestRate": 0,
						"value": 196900,
						"total": 196900,
						"sellerMerchantInstallments": [
							{
								"id": "OECHSLE",
								"count": 1,
								"hasInterestRate": false,
								"interestRate": 0,
								"value": 196900,
								"total": 196900
							}
						]
					}
				]
			},
			{
				"paymentSystem": "4",
				"bin": null,
				"paymentName": null,
				"paymentGroupName": null,
				"value": 196900,
				"installments": [
					{
						"count": 1,
						"hasInterestRate": false,
						"interestRate": 0,
						"value": 196900,
						"total": 196900,
						"sellerMerchantInstallments": [
							{
								"id": "OECHSLE",
								"count": 1,
								"hasInterestRate": false,
								"interestRate": 0,
								"value": 196900,
								"total": 196900
							}
						]
					}
				]
			}
		],
		"paymentSystems": [
			{
				"id": 1,
				"name": "American Express",
				"groupName": "creditCardPaymentGroup",
				"validator": {
					"regex": "^3[47][0-9]{13}$",
					"mask": "9999 999999 99999",
					"cardCodeRegex": "^[0-9]{4}$",
					"cardCodeMask": "9999",
					"weights": [
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1
					],
					"useCvv": true,
					"useExpirationDate": true,
					"useCardHolderName": true,
					"useBillingAddress": true
				},
				"stringId": "1",
				"template": "creditCardPaymentGroup-template",
				"requiresDocument": false,
				"isCustom": false,
				"description": null,
				"requiresAuthentication": false,
				"dueDate": "2019-07-17T05:29:30.3382807Z",
				"availablePayments": null
			},
			{
				"id": 2,
				"name": "Visa",
				"groupName": "creditCardPaymentGroup",
				"validator": {
					"regex": "^4[0-9]{15}$",
					"mask": "9999 9999 9999 9999",
					"cardCodeRegex": "^[0-9]{3}$",
					"cardCodeMask": "999",
					"weights": [
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2
					],
					"useCvv": true,
					"useExpirationDate": true,
					"useCardHolderName": true,
					"useBillingAddress": true
				},
				"stringId": "2",
				"template": "creditCardPaymentGroup-template",
				"requiresDocument": false,
				"isCustom": false,
				"description": null,
				"requiresAuthentication": false,
				"dueDate": "2019-07-17T05:29:30.3382807Z",
				"availablePayments": null
			},
			{
				"id": 4,
				"name": "Mastercard",
				"groupName": "creditCardPaymentGroup",
				"validator": {
					"regex": "^((5(1(0(0(0([0-9])|[1-9][0-9])|[1-9][0-9]{0})|[1-9][0-9]{0})|3(0(4(0([0-9]))|[0-3][0-9]{0}))|2[0-9]{0}|0(6([0-9]{0}))|0(1([0-9]{0})))|^5(3(0(4(2([0-9])|[3-9][0-9])|[5-9][0-9]{0})|[1-9][0-9]{0})|5(9(9(9([0-9])|[0-8][0-9])|[0-8][0-9]{0})|[0-8][0-9]{0})|4[0-9]{0}))|((508116)\\d{0,10})|((502121)\\d{0,10})|((589916)\\d{0,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{0,10})",
					"mask": "9999 9999 9999 9999",
					"cardCodeRegex": "^[0-9]{3}$",
					"cardCodeMask": "999",
					"weights": [
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2
					],
					"useCvv": true,
					"useExpirationDate": true,
					"useCardHolderName": true,
					"useBillingAddress": true
				},
				"stringId": "4",
				"template": "creditCardPaymentGroup-template",
				"requiresDocument": false,
				"isCustom": false,
				"description": null,
				"requiresAuthentication": false,
				"dueDate": "2019-07-17T05:29:30.3382807Z",
				"availablePayments": null
			},
			{
				"id": 3,
				"name": "Diners",
				"groupName": "creditCardPaymentGroup",
				"validator": {
					"regex": "^3(0[0-5]|[68][0-9])[0-9]{11}$",
					"mask": "9999 999999 9999",
					"cardCodeRegex": "^[0-9]{3}$",
					"cardCodeMask": "999",
					"weights": [
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2,
						1,
						2
					],
					"useCvv": true,
					"useExpirationDate": true,
					"useCardHolderName": true,
					"useBillingAddress": true
				},
				"stringId": "3",
				"template": "creditCardPaymentGroup-template",
				"requiresDocument": false,
				"isCustom": false,
				"description": null,
				"requiresAuthentication": false,
				"dueDate": "2019-07-17T05:29:30.3382807Z",
				"availablePayments": null
			}
		],
		"payments": [
			{
				"paymentSystem": "2",
				"bin": null,
				"accountId": null,
				"tokenId": null,
				"installments": 1,
				"referenceValue": 196900,
				"value": 196900,
				"merchantSellerPayments": [
					{
						"id": "OECHSLE-oechsle",
						"installments": 1,
						"referenceValue": 196900,
						"value": 196900,
						"interestRate": 0,
						"installmentValue": 196900
					}
				]
			}
		],
		"giftCards": [

		],
		"giftCardMessages": [

		],
		"availableAccounts": [

		],
		"availableTokens": [

		]
	},
	"marketingData": null,
	"sellers": [
		{
			"id": "oechsle",
			"name": "oechsle",
			"logo": ""
		}
	],
	"clientPreferencesData": {
		"locale": "es-PE",
		"optinNewsLetter": true
	},
	"commercialConditionData": null,
	"storePreferencesData": {
		"countryCode": "PER",
		"saveUserData": true,
		"timeZone": "SA Pacific Standard Time",
		"currencyCode": "PEN",
		"currencyLocale": 10250,
		"currencySymbol": "S/.",
		"currencyFormatInfo": {
			"currencyDecimalDigits": 2,
			"currencyDecimalSeparator": ".",
			"currencyGroupSeparator": ",",
			"currencyGroupSize": 3,
			"startsWithCurrencySymbol": true
		}
	},
	"giftRegistryData": null,
	"openTextField": null,
	"invoiceData": null,
	"customData": null,
	"itemMetadata": {
		"items": [
			{
				"id": "18215",
				"seller": "oechsle",
				"name": "Laptop 15.6\" Aspire A315 AMD Ryzen 5 6GB 1TB Negro",
				"skuName": "Laptop 15.6\" Aspire A315 AMD Ryzen 5 6GB 1TB Negro",
				"productId": "17710",
				"refId": "1324706",
				"ean": "2800013247069",
				"imageUrl": "http://mercury.vteximg.com.br/arquivos/ids/205583-55-55/image-c0f7f228b6be4b4c8c0e19d550d73235.jpg?v=636765320224730000",
				"detailUrl": "/laptop-15-6--aspire-a315-amd-ryzen-5-6gb-1tb-negro-17710/p",
				"assemblyOptions": [

				]
			}
		]
	},
	"hooksData": null,
	"ratesAndBenefitsData": {
		"rateAndBenefitsIdentifiers": [
			{
				"id": "e944d386-39a9-4956-bf95-eadbfe760895",
				"name": "Cyberweek Envio Gratis",
				"featured": false,
				"description": null,
				"matchedParameters": {
					"Seller@CatalogSystem": "oechsle,plazavea,promart,passarelape,coliseum|inclusive"
				},
				"additionalInfo": null
			}
		],
		"teaser": [

		]
	},
	"subscriptionData": null,
	"itemsOrdination": null
}