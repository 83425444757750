import {
	GET_ORDER_BEGIN,
	GET_ORDER_SUCCESS,
	GET_ORDER_FAILURE,
} from '../constants';
import { api } from '../../../api/api';

const apiUrl = api;
const headers = {
	'Access-Control-Allow-Origin': '*',
	'Content-Type': 'application/json',
	'Accept': 'application/json',
}

function handleErrors(response) {
	if (!response.ok) {
		throw Error(response.statusText);
	}
	return response;
}

// Checkout Post

export const getOrder = (checkoutId, orderNumber) => {
	return dispatch => {
		dispatch(getOrderBegin());
		return fetch(`${apiUrl}transactions/orders/v2/${checkoutId}/${orderNumber}`, {
			method: 'get',
			headers: headers
		})
			.then(handleErrors)
			.then(res => res.json())
			.then(json => {
				dispatch(getOrderSuccess(json));

				return json;
			})
			.catch(error => dispatch(getOrderFailure(error)));
	}
}



// Actions

// Checkout Post

export const getOrderBegin = () => ({
	type: GET_ORDER_BEGIN
});

export const getOrderSuccess = (payload) => ({
	type: GET_ORDER_SUCCESS,
	payload: payload,
});
export const getOrderFailure = (error) => ({
	type: GET_ORDER_FAILURE,
	payload: error,
});
