import React from "react";
import { KEYS } from "../../constants";
import "./CallbackV2.scss"

class CallbackView extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {

	}

	componentDidMount = async () => {
		// const { authInterbank } = this.props;
		// const urlParams = new URLSearchParams(window.location.search);
		// const code = urlParams.get('code');
		// const checkoutId = window.localStorage.getItem("__checkoutId__");
		// const orderFormId = window.localStorage.getItem("__orderFormId__");
		// const data = JSON.parse(window.localStorage.getItem("__get__"));
		// let model = {
		// 	code: code,
		// 	checkOutId: data.draft.draftId,
		// 	redirectUri: KEYS.redirectUri
		// }
		// await authInterbank(model);
		// const { userInterbank } = this.props;

		// if (userInterbank.data) {
		// 	window.localStorage.setItem("__customerId__", userInterbank.data.customerId);
		// 	window.localStorage.setItem("__ibkLoginTime__", new Date());
		// 	window.location.href = `/checkout/${orderFormId}`;
		// }
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get('code');
		const customerId = urlParams.get('customerId');

		let model = {
			code: code,
			customerId: customerId
		}

		window.localStorage.setItem("__authInfo__", JSON.stringify(model));
	}


	render() {
		// const { userInterbank } = this.props;
		return (
			<div className="App callback-loader">
				<span>Cargando tus millas benefits ...</span>
				{/* {userInterbank.loading &&
					<span>Cargando Checkout ...</span>
				}
				{userInterbank.error &&
					<span>Hubo un error, por favor vuelva a cargar su checkout.</span>
				} */}
			</div>
		);
	}
}

export default CallbackView;
