import React from "react";
import { cart } from "./../../data/dummy/cart";
import "./Cart.scss"

class CartView extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		items: cart.items
	}

	formatPrice = (price) => {
		price = price.toString();

		let i = price.substring(0, price.length - 2);
		let d = price.substring(price.length - 2, price.length);

		return `S/ ${i}.${d}`;
	}

	handleChangeQuantity = (e, item) => {
		const { items } = this.state;
		console.log(e.target.value)
		items.map((i) => {
			if (i.id === item.id) {

				i.quantity = e.target.value;
			}
		});

		this.setState({ items: items })
	}

	handleAddOffering = (e, item, offering) => {
		console.log(e, item, offering);
	}

	renderOfferings = (item) => {
		let offerings = item.offerings.map((offering) => {
			let price = this.formatPrice(offering.price);
			return (<a key={offering.id} role="button" onClick={(e) => { this.handleAddOffering(e, item, offering) }}>Sumar {offering.name}: {price}</a>)
		});
		return (<React.Fragment>{offerings}</React.Fragment>);
	}

	handleClickBtnQuantity = (increase, item) => {
		const { items } = this.state;

		increase = parseInt(increase);

		items.map((i) => {
			if (i.id === item.id) {
				if (increase === 1)
					i.quantity++;
				else
					if (i.quantity > 1)
						i.quantity--;
			}
		});

		this.setState({ items: items })
	}

	render() {

		const { items } = this.state;

		return (
			<div className="App">
				<div className="cart">
					<div className="container">
						<div className="row">
							<div className="col">
								<h1 className="cart__title">
									<span className="cart__title__icon"></span>
									Carro de Compra
								</h1>
							</div>
						</div>
						<div className="row">
							<div className="col-md-8 col-sm-8 col-12">
								<div className="cart__table">
									<table className="table">
										<thead>
											<tr>
												<th>Productos</th>
												<th></th>
												<th className="text-center">Precio</th>
												<th className="text-center">Cantidad</th>
												<th className="text-center">Subtotal</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{items.map((item) => {
												return (
													<tr className="cart-item" key={item.id}>
														<td className="cart-item__image">
															<a href={item.detailUrl}>
																<img src={item.imageUrl} title={item.name} alt={item.name} />
															</a>
														</td>
														<td className="cart-item__info">
															<a className="cart-item__info__name" href={item.detailUrl}>{item.name}</a>
															<span className="cart-item__info__brand">{item.additionalInfo.brandName}</span>
															<div className="cart-item__info__offerings">
																<span>Agregar</span>
																{this.renderOfferings(item)}
															</div>
														</td>
														<td className="cart-item__price">
															<del className="d-block">{this.formatPrice(item.listPrice)}</del>
															<span className="d-block">{this.formatPrice(item.price)}</span>
														</td>
														<td className="cart-item__quantity">
															<div className="cart-item__quantity__picker">
																<button className="btn" onClick={(e) => { this.handleClickBtnQuantity('-1', item) }}>-</button>
																<input type="tel" className="form-control" value={item.quantity} onChange={(e) => { this.handleChangeQuantity(e, item) }} />
																<button className="btn" onClick={(e) => { this.handleClickBtnQuantity('1', item) }}>+</button>
															</div>
														</td>
														<td className="cart-item__subtotal">
															<span>{this.formatPrice(item.sellingPrice)}</span>
														</td>
														<td className="cart-item__actions">
															<button className="btn btn-link">
																<i className="fas fa-times"></i>
															</button>
														</td>
													</tr>
												)
											})
											}
										</tbody>
									</table>
								</div>
							</div>
							<div className="col-md-4 col-sm-4 col-12">
								<div className="cart__summary">
									<div className="card">
										<div className="card-body">
											<div className="cart__summary__coupon">
												<h4 className="text-center">&#191;Tienes un cup&oacute;n de descuento?</h4>
												<div className="cart__summary__coupon__form d-flex">
													<input className="form-control" type="text" placeholder="Codigo"/>
													<button className="btn btn-main text-uppercase ml-2">
														<b>Aplicar</b>
													</button>
												</div>
											</div>
											<div className="cart__summary__details">
												<h4 className="text-center text-uppercase">Resumen de tu compra</h4>
												<ul className="list-unstyled pl-0">
													<li className="d-flex justify-content-between">
														<span>Subtotal</span>
														<span>S/ 1969.00</span>
													</li>
													<li className="d-flex justify-content-between">
														<span>Costo de env&iacute;o</span>
														<span>Gratis</span>
													</li>
													<li className="d-flex justify-content-between total">
														<span>Total</span>
														<span>S/ 1969.00</span>
													</li>
												</ul>
											</div>
											<div className="cart__summary__continue">
												<button className="btn btn-main text-uppercase">
													<b>Continuar</b>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CartView;
