export const CHECKOUT_POST_BEGIN = 'CHECKOUT_POST_BEGIN';
export const CHECKOUT_POST_SUCCESS = 'CHECKOUT_POST_SUCCESS';
export const CHECKOUT_POST_FAILURE = 'CHECKOUT_POST_FAILURE';

export const START_BEGIN = 'START_BEGIN';
export const START_SUCCESS = 'START_SUCCESS';
export const START_FAILURE = 'START_FAILURE';

export const CHECKOUT_GET_BEGIN = 'CHECKOUT_GET_BEGIN';
export const CHECKOUT_GET_SUCCESS = 'CHECKOUT_GET_SUCCESS';
export const CHECKOUT_GET_FAILURE = 'CHECKOUT_GET_FAILURE';

export const USER_PUT_BEGIN = 'USER_PUT_BEGIN';
export const USER_PUT_SUCCESS = 'USER_PUT_SUCCESS';
export const USER_PUT_FAILURE = 'USER_PUT_FAILURE';
export const USER_CHANGE_VIEW = 'USER_CHANGE_VIEW';

export const TRANSACTION_POST_BEGIN = 'TRANSACTION_POST_BEGIN';
export const TRANSACTION_POST_SUCCES = 'TRANSACTION_POST_SUCCES';
export const TRANSACTION_POST_FAILURE = 'TRANSACTION_POST_FAILURE';

export const AUTHORIZE_POST_BEGIN = 'AUTHORIZE_POST_BEGIN';
export const AUTHORIZE_POST_SUCCES = 'AUTHORIZE_POST_SUCCES';
export const AUTHORIZE_POST_FAILURE = 'AUTHORIZE_POST_FAILURE';

export const SHIPPING_PUT_BEGIN = 'SHIPPING_PUT_BEGIN';
export const SHIPPING_PUT_SUCCESS = 'SHIPPING_PUT_SUCCESS';
export const SHIPPING_PUT_FAILURE = 'SHIPPING_PUT_FAILURE';
export const SHIPPING_MAP_CHANGE = 'SHIPPING_MAP_CHANGE';

export const ITEM_DELETE_BEGIN = 'ITEM_DELETE_BEGIN';
export const ITEM_DELETE_SUCCESS = 'ITEM_DELETE_SUCCESS';
export const ITEM_DELETE_FAILURE = 'ITEM_DELETE_FAILURE';

export const GET_ADDRESS_BEGIN = 'GET_ADDRESS_BEGIN';
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_FAILURE = 'GET_ADDRESS_FAILURE';

export const DISCOUNT_BEGIN = 'DISCOUNT_BEGIN';
export const DISCOUNT_SUCCESS = 'DISCOUNT_SUCCESS';
export const DISCOUNT_FAILURE = 'DISCOUNT_FAILURE';

export const IBK_AUTH_BEGIN = 'IBK_AUTH_BEGIN';
export const IBK_AUTH_SUCCESS = 'IBK_AUTH_SUCCESS';
export const IBK_AUTH_FAILURE = 'IBK_AUTH_FAILURE';

export const GET_MILES_BEGIN = 'GET_MILES_BEGIN';
export const GET_MILES_SUCCESS = 'GET_MILES_SUCCESS';
export const GET_MILES_FAILURE = 'GET_MILES_FAILURE';

export const CONVERT_BEGIN = 'CONVERT_BEGIN';
export const CONVERT_SUCCESS = 'CONVERT_SUCCESS';
export const CONVERT_FAILURE = 'CONVERT_FAILURE';

export const AUTH_INTERBANK_BEGIN = 'AUTH_INTERBANK_BEGIN';
export const AUTH_INTERBANK_SUCCESS = 'AUTH_INTERBANK_SUCCESS';
export const AUTH_INTERBANK_FAILURE = 'AUTH_INTERBANK_FAILURE';

export const GET_INSTALLMENTS_BEGIN = 'GET_INSTALLMENTS_BEGIN';
export const GET_INSTALLMENTS_SUCCESS = 'GET_INSTALLMENTS_SUCCESS';
export const GET_INSTALLMENTS_FAILURE = 'GET_INSTALLMENTS_FAILURE';

export const SEND_EMBLUE_DATA_BEGIN = 'SEND_EMBLUE_DATA_BEGIN';
export const SEND_EMBLUE_DATA_SUCCESS = 'SEND_EMBLUE_DATA_SUCCESS';
export const SEND_EMBLUE_DATA_FAILURE = 'SEND_EMBLUE_DATA_FAILURE';

export const INVALIDATE_ORDER_BEGIN = 'INVALIDATE_ORDER_BEGIN';
export const INVALIDATE_ORDER_SUCCESS = 'INVALIDATE_ORDER_SUCCESS';
export const INVALIDATE_ORDER_FAILURE = 'INVALIDATE_ORDER_FAILURE';

export const GET_MILES_TOKEN_BEGIN = 'GET_MILES_TOKEN_BEGIN';
export const GET_MILES_TOKEN_SUCCESS = 'GET_MILES_TOKEN_SUCCESS';
export const GET_MILES_TOKEN_FAILURE = 'GET_MILES_TOKEN_FAILURE';
