import {
	GET_ORDER_BEGIN,
	GET_ORDER_SUCCESS,
	GET_ORDER_FAILURE
} from '../constants';
import { DRAFT_CHECKOUT } from './../../../data/dummy/draft';

const defaultOrderState = {
	data: null,
	loading: false,
	error: null
};

export const order = (state = defaultOrderState, { type, payload }) => {
	switch (type) {
		case GET_ORDER_BEGIN: {
			return {
				...state,
				loading: true,
				error: null
			};
		}
		case GET_ORDER_SUCCESS: {
			return {
				...state,
				loading: false,
				data: payload
			};
		}
		case GET_ORDER_FAILURE: {
			return {
				...state,
				loading: false,
				error: payload,
				data: null
			};
		}
		default: {
			return state;
		}
	}
}