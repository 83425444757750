import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HomeView from './HomeView';
import { withRouter } from 'react-router';
import { authInterbank } from './../checkout/actions/checkout';

function HomeContainer() {
	return (
		<HomeView/>
	)
}

function mapStateToProps(state) {
	return { }
}

const mapDispatchToProps = { }

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HomeContainer);
