const apiUrlDev = "http://localhost:46000/";
const apiUrlProd = "http://apidev.shopstar.pe/";

const urlParams = new URLSearchParams(window.location.search);
const version = urlParams.get('version');

let apiUrl = process.env.REACT_APP_API_URL;

if(version && version !== '') 
    apiUrl = apiUrl + version + '/';

// url
export const api = apiUrl;
