import {
	CHECKOUT_POST_BEGIN,
	CHECKOUT_POST_SUCCESS,
	CHECKOUT_POST_FAILURE,
	START_BEGIN,
	START_SUCCESS,
	START_FAILURE,
	CHECKOUT_GET_BEGIN,
	CHECKOUT_GET_SUCCESS,
	CHECKOUT_GET_FAILURE,
	USER_PUT_BEGIN,
	USER_PUT_SUCCESS,
	USER_PUT_FAILURE,
	USER_CHANGE_VIEW,
	SHIPPING_PUT_BEGIN,
	SHIPPING_PUT_SUCCESS,
	SHIPPING_PUT_FAILURE,
	SHIPPING_MAP_CHANGE,
	ITEM_DELETE_BEGIN,
	ITEM_DELETE_SUCCESS,
	ITEM_DELETE_FAILURE,
	GET_ADDRESS_BEGIN,
	GET_ADDRESS_SUCCESS,
	GET_ADDRESS_FAILURE,
	IBK_AUTH_BEGIN,
	IBK_AUTH_SUCCESS,
	IBK_AUTH_FAILURE,
	GET_MILES_BEGIN,
	GET_MILES_SUCCESS,
	GET_MILES_FAILURE,
	CONVERT_BEGIN,
	CONVERT_SUCCESS,
	CONVERT_FAILURE,
	AUTH_INTERBANK_BEGIN,
	AUTH_INTERBANK_SUCCESS,
	AUTH_INTERBANK_FAILURE,
	GET_INSTALLMENTS_BEGIN,
	GET_INSTALLMENTS_SUCCESS,
	GET_INSTALLMENTS_FAILURE
} from '../constants/ActionTypes';
import { DRAFT_CHECKOUT } from './../../../data/dummy/draft';

const defaultPostState = {
	draft: null,
	loading: false,
	error: null
};

export const checkoutPost = (state = defaultPostState, { type, payload }) => {
	switch (type) {
		case CHECKOUT_POST_BEGIN: {
			return {
				...state,
				loading: true,
				error: null
			};
		}
		case CHECKOUT_POST_SUCCESS: {
			return {
				...state,
				loading: false,
				draft: payload
			};
		}
		case CHECKOUT_POST_FAILURE: {
			return {
				...state,
				loading: false,
				error: payload,
				draft: null
			};
		}
		default: {
			return state;
		}
	}
}


const defaultGetState = {
	draft: null,
	loading: false,
	error: null
};

export const checkoutGet = (state = defaultGetState, { type, payload }) => {
	switch (type) {
		case CHECKOUT_GET_BEGIN: {
			return {
				...state,
				loading: true,
				error: null
			};
		}
		case CHECKOUT_GET_SUCCESS: {
			if (payload.externalJsonCart && payload.externalJsonCart !== "") {
				payload.shippingData = JSON.parse(payload.externalJsonCart);
			}
			return {
				...state,
				loading: false,
				draft: payload
			};
		}
		case CHECKOUT_GET_FAILURE: {
			return {
				...state,
				loading: false,
				error: payload,
				draft: null
			};
		}
		default: {
			return state;
		}
	}
}

const defaultUserState = {
	loading: false,
	view: 'form',
	error: null
}

export const userPut = (state = defaultUserState, { type, payload }) => {
	switch (type) {
		case USER_PUT_BEGIN: {
			return {
				...state,
				loading: true,
				error: null,
			}
		}
		case USER_PUT_SUCCESS: {
			return {
				...state,
				loading: false,
				view: 'summary',
				error: null,
			}
		}
		case USER_PUT_FAILURE: {
			return {
				...state,
				loading: false,
				view: 'form',
				error: payload,
			}
		}
		case USER_CHANGE_VIEW: {
			return {
				...state,
				loading: false,
				view: payload,
			}
		}
		default: {
			return state;
		}
	}
}

const defaultShippingState = {
	loading: false,
	view: 'form',
	modalView: '',
	error: null
}

export const shippingPut = (state = defaultShippingState, { type, payload }) => {
	switch (type) {
		case SHIPPING_PUT_BEGIN: {
			return {
				...state,
				loading: true,
				view: 'loader',
				modalView: 'map',
				error: null,
			}
		}
		case SHIPPING_PUT_SUCCESS: {
			return {
				...state,
				loading: false,
				view: '',
				modalView: 'map',
				error: null,
			}
		}
		case SHIPPING_PUT_FAILURE: {
			return {
				...state,
				loading: false,
				view: '',
				modalView: '',
				error: payload,
			}
		}
		case SHIPPING_MAP_CHANGE: {
			return {
				...state,
				loading: false,
				view: '',
				modalView: payload,
				error: null,
			}
		}
		default: {
			return state;
		}
	}
}

const defaultItemState = {
	loading: false,
	error: null
}

export const cartItem = (state = defaultItemState, { type, payload }) => {
	switch (type) {
		case ITEM_DELETE_BEGIN: {
			return {
				...state,
				loading: true,
				error: null,
			}
		}
		case ITEM_DELETE_SUCCESS: {
			return {
				...state,
				loading: false,
				error: null,
			}
		}
		case ITEM_DELETE_FAILURE: {
			return {
				...state,
				loading: false,
				error: payload,
			}
		}
		default: {
			return state;
		}
	}
}

const defaultAddressState = {
	data: null,
	loading: false,
	error: null
}

export const address = (state = defaultAddressState, { type, payload }) => {
	switch (type) {
		case GET_ADDRESS_BEGIN: {
			return {
				...state,
				data: null,
				loading: true,
				error: null,
			}
		}
		case GET_ADDRESS_SUCCESS: {
			return {
				...state,
				data: payload,
				loading: false,
				error: null,
			}
		}
		case GET_ADDRESS_FAILURE: {
			return {
				...state,
				loading: false,
				data: null,
				error: payload,
			}
		}
		default: {
			return state;
		}
	}
}

const defaultStartState = {
	data: null,
	loading: false,
	error: null
}

export const start = (state = defaultStartState, { type, payload }) => {
	switch (type) {
		case START_BEGIN: {
			return {
				...state,
				data: null,
				loading: true,
				error: null,
			}
		}
		case START_SUCCESS: {
			return {
				...state,
				data: payload,
				loading: false,
				error: null,
			}
		}
		case START_FAILURE: {
			return {
				...state,
				loading: false,
				data: null,
				error: payload,
			}
		}
		default: {
			return state;
		}
	}
}

const defaultIbkAuthState = {
	isAuthenticated: false,
	loading: false,
	error: null
}

export const ibkAuth = (state = defaultIbkAuthState, { type, payload }) => {
	switch (type) {
		case IBK_AUTH_BEGIN: {
			return {
				...state,
				isAuthenticated: false,
				loading: true,
				error: null,
			}
		}
		case IBK_AUTH_SUCCESS: {
			return {
				...state,
				isAuthenticated: true,
				loading: false,
				error: null,
			}
		}
		case IBK_AUTH_FAILURE: {
			return {
				...state,
				loading: false,
				isAuthenticated: false,
				error: payload,
			}
		}
		default: {
			return state;
		}
	}
}

const defaultIbkMilesState = {
	data: null,
	loading: false,
	error: null
}

export const ibkMiles = (state = defaultIbkMilesState, { type, payload }) => {
	switch (type) {
		case GET_MILES_BEGIN: {
			return {
				...state,
				data: null,
				loading: true,
				error: null,
			}
		}
		case GET_MILES_SUCCESS: {
			return {
				...state,
				data: payload,
				loading: false,
				error: null,
			}
		}
		case GET_MILES_FAILURE: {
			return {
				...state,
				loading: false,
				data: null,
				error: payload,
			}
		}
		default: {
			return state;
		}
	}
}

const defaultExchangeMilesState = {
	data: null,
	loading: false,
	error: null
}

export const exchange = (state = defaultExchangeMilesState, { type, payload }) => {
	switch (type) {
		case CONVERT_BEGIN: {
			return {
				...state,
				data: null,
				loading: true,
				error: null,
			}
		}
		case CONVERT_SUCCESS: {
			return {
				...state,
				data: payload,
				loading: false,
				error: null,
			}
		}
		case CONVERT_FAILURE: {
			return {
				...state,
				loading: false,
				data: null,
				error: payload,
			}
		}
		default: {
			return state;
		}
	}
}

const defaultAuthInterbank = {
	data: null,
	loading: false,
	error: null
}

export const userInterbank = (state = defaultAuthInterbank, { type, payload }) => {
	switch (type) {
		case AUTH_INTERBANK_BEGIN: {
			return {
				...state,
				data: null,
				loading: true,
				error: null,
			}
		}
		case AUTH_INTERBANK_SUCCESS: {
			return {
				...state,
				data: payload,
				loading: false,
				error: null,
			}
		}
		case AUTH_INTERBANK_FAILURE: {
			return {
				...state,
				loading: false,
				data: null,
				error: payload,
			}
		}
		default: {
			return state;
		}
	}
}

const defaultInstallments = {
	data: null,
	loading: false,
	error: null
}

export const installments = (state = defaultInstallments, { type, payload }) => {
	switch (type) {
		case GET_INSTALLMENTS_BEGIN: {
			return {
				...state,
				data: null,
				loading: true,
				error: null,
			}
		}
		case GET_INSTALLMENTS_SUCCESS: {
			return {
				...state,
				data: payload,
				loading: false,
				error: null,
			}
		}
		case GET_INSTALLMENTS_FAILURE: {
			return {
				...state,
				loading: false,
				data: null,
				error: payload,
			}
		}
		default: {
			return state;
		}
	}
}
