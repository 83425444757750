import React from "react";
import { ReactComponent as IconBack } from '../../../../assets/back-icon.svg';

class Navigation extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="checkout__navigation">
                <a href="https://shopstar.pe/checkout/#/cart">
                    <IconBack></IconBack>
                    <span>Regresar</span>
                </a>
            </div>
        )
    }
}

export default Navigation;