import axios from 'axios';
import { KEYS } from '../../constants/index';

export const auth = async () => {
    var result = null;

    var __model = {
        "client_id": process.env.REACT_APP_MUSCLE_CLIENT_ID,
        "client_secret": process.env.REACT_APP_MUSCLE_CLIENT_SECRET,
        "audience": process.env.REACT_APP_MUSCLE_AUDIENCE,
        "grant_type": "client_credentials"
    }

    try {
        //const { data } = await axios.post(process.env.REACT_APP_MUSCLE_AUTH_URL, __model);
        const { data } = await axios.post(process.env.REACT_APP_MUSCLE_AUTH_URL, {});
        result = data;
    } catch (e) {
        result = e.response;
    }
    
    return result;
}

export const cartCashAndPoints = async (model, token) => {

    let result = null;

    var __headers = {
        headers: { Authorization: `Bearer ${token}` }
    }

    try {
        const { data } = await axios.post(process.env.REACT_APP_MUSCLE_CART_CASH_AND_POINTS_URL, model, __headers);
        
        result = data;
    } catch (e) {
        result = e.response;
    }

    return result;
}

export const customerActionsLogging = async (model, token) => {
    
    let result = null;

    var __headers = {
        headers: { Authorization: `Bearer ${token}` }
    }

    try {
        const { data } = await axios.post(process.env.REACT_APP_MUSCLE_PURCHASE_COMPLETE_URL, model, __headers);
        
        result = data;
    } catch (e) {
        result = e.response;
    }

    return result;
}