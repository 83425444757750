import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './App.scss';
import './styles/index.scss';

import CheckoutContainer from './components/checkout/CheckoutContainer';
import CheckoutV2Container from './components/checkoutV2/CheckoutV2Container';
import CheckoutSuccessContainer from './components/checkout-success/CheckoutSuccessContainer';
import CartContainer from './components/cart/CartContainer';
import CallbackContainer from './components/callback/CallbackContainer';
import CallbackV2Container from './components/callbackV2/CallbackV2Container';
import HomeContainer from './components/home/HomeContainer';

import CheckoutEmailContainer from './components/v3/email/CheckoutEmailContainer';
import CheckoutInfoContainer from './components/v3/info/CheckoutInfoContainer';
import CheckoutShippingContainer from './components/v3/shipping/CheckoutShippingContainer';
import CheckoutPaymentContainer from './components/v3/payment/CheckoutPaymentContainer';

import { getAppInsights } from './TelemetryService';
import TelemetryProvider from './TelemetryProvider';
import * as $ from 'jquery';


function App() {
	let appInsights = null;

	function trackEvent(e) {
		let el = $(e.target);
        appInsights.trackTrace({ message: el.attr("data") });
    }

	return (
		<Router>
			<TelemetryProvider instrumentationKey={process.env.REACT_APP_INSIGHTS} after={() => { appInsights = getAppInsights() }}>
				<button id="trackEvent" onClick={(e) => {trackEvent(e)}} className="d-none"></button>
				<Route exact path="/checkout-old/:id" component={() => <CheckoutContainer appInsights={appInsights} />} />
				<Route exact path="/checkout/:id" component={() => <CheckoutV2Container appInsights={appInsights} />} />
				<Route exact path="/promos/:promotionId" component={() => <CheckoutV2Container appInsights={appInsights} />} />
				<Route exact path="/checkout/exito/:id" component={CheckoutSuccessContainer} />
				<Route exact path="/cart" component={CartContainer} />
				<Route exact path="/auth/interbank/callback" component={CallbackContainer} />
				<Route exact path="/auth/interbank/v2/callback" component={CallbackV2Container} />
				<Route exact path="/:id/info" component={CheckoutInfoContainer} />
				<Route exact path="/:id/shipping" component={CheckoutShippingContainer} />
				<Route exact path="/:id/payment" component={CheckoutPaymentContainer} />
				<Route exact path="/:id/email" component={CheckoutEmailContainer} />
			</TelemetryProvider>
		</Router>
	);
}

export default App;
