import React from "react";
import * as $ from 'jquery';
import "./CheckoutPayment.scss";
import {
    saveOrder,
    saveOrderV5,
    invalidateOrder,
    authorizePayment,
    sendEmblueData,
    getDiscount,
    getInstallments,
    getMiles,
    getMilesToken,
    getOauthToken,
    sendHubspotData,
    getCheckout,
} from '../../../services/Service';
import {
    saveDataLayerEvent,
    saveDataLayerStepEvent,
    saveSatelliteEvent
} from '../../../services/TrackerService';

import valid from 'card-validator';
import { IBK_BINES, IBK_DEBIT_BINES } from './../../../data/bines/bines';
import { DISTRICTS, PROVINCES, DEPARTMENTS } from './../../../data/ubigeos/ubigeos';
import { KEYS } from '../../../constants/index';

import Summary from './../shared/Summary'
import Contact from './../shared/Contact'
import User from './../shared/User'
import Shipping from './../shared/Shipping'

import { ReactComponent as IconHelp } from './../../../assets/help.svg';
import { ReactComponent as IconCreditCard } from './../../../assets/credit-card.svg';
import { ReactComponent as LogoCardTypes } from './../../../assets/card-types.svg';
import { ReactComponent as LogoIbk } from './../../../assets/ibk-logo.svg';
import { ReactComponent as IconMiles } from './../../../assets/miles.svg';
import { ReactComponent as LogoBenefits } from './../../../assets/benefits.svg';
import { ReactComponent as LogoPagoEfectivo } from './../../../assets/pagoefectivo-logo.svg';
import { ReactComponent as IconDivide } from './../../../assets/divide.svg';
import { ReactComponent as LogoDivideIt } from './../../../assets/divide-it.svg';
import { ReactComponent as IconUser } from './../../../assets/user.svg';
import { ReactComponent as LogoVisa } from './../../../assets/visa.svg';
import { ReactComponent as LogoMc } from './../../../assets/mc.svg';
import { ReactComponent as LogoAmex } from './../../../assets/amex.svg';
import { ReactComponent as LogoDiners } from './../../../assets/diners.svg';
import { ReactComponent as IconCard } from './../../../assets/card.svg';
import { ReactComponent as IconCheck } from './../../../assets/check-circle.svg';
import { ReactComponent as IconInfoWarning } from './../../../assets/info-circle-warning.svg';
import { ReactComponent as IconIbk } from './../../../assets/ibk-ico.svg';
import { ReactComponent as IconAlert } from './../../../assets/alert.svg';
import { ReactComponent as IconAlert3d } from './../../../assets/alert-3d.svg';

class CheckoutPaymentView extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        checkout: null,
        address: null,
        user: null,
        gateway: null,
        shippingItems: null,
        stores: null,

        total: 0,
        totalIbk: 0,

        stepNumber: 0,
        type: null,
        paymentSystem: 2,
        cardNumber: "",
        maskedCardNumber: "",
        cardType: "",
        placeHolder: "",
        placeHolderDocumentNumber: "",
        cvv: "",
        unmaskedCvv: "",
        expirationDate: "",
        year: "0",
        month: "0",
        installments: "1",
        installmentAmount: 0,
        installmentOptions: [],
        cvvLength: 0,
        validCard: '',
        cardLength: 16,
        capture: false,
        isIbkCard: '',
        validBin: false,
        showIsIbkCardMessage: false,
        orderType: 'boleta',
        entityName: '',
        entityIdentificationNumber: '',
        installmentsList: null,
        loading: false,
        error: "",
        miles: {
            miles: 0,
            total: 0,
            selected: 0,
            exchange: 0,
            change: 0,
            error: false,
            loading: false,
            tokenMiles: ""
        },
        milesToken: null,
        policies: {
            personalData: false,
            terms: false,
            privacy: false,
        },
        errors: {},
        ubigeos: {
            departments: DEPARTMENTS,
            provinces: [],
            districts: []
        },
        billingAddress: 'same',
        department: "0",
        province: "0",
        district: "0",
        addressLine: "",
        addressNumber: "",
        flatNumber: "",
        reference: "",
        postalCode: "",
        countryISO: "PER",
        showPrices: true,
        cardObject: null,
        submitting: false
    }

    componentDidMount = async () => {
        this.loadUser();
        this.loadAddress();
        this.loadGateway();
        this.loadShippingItems();
        this.stores();
        this.loadTotals();
        this.loadInstallments();

        await this.loadCheckout();

        // this.initProviders();
        this.authenticate();
        this.getMilesToken();
        this.getStepNumber();
        this.clearSession();
        this.loadPromo();
    }

    // componentWillReceiveProps = (newProps) => {
    //     if (newProps.currentForm === 'payment' && this.props.currentForm !== 'payment') {
    //         let _context = this;

    //         setTimeout(() => {
    //             _context.loadPayuForms();
    //         }, 1000)
    //     }
    // }
    loadTotals = () => {
        let total = window.localStorage.getItem("__total__");
        let totalIbk = window.localStorage.getItem("__totalIbk__");

        total = total ? parseFloat(total) : 0;
        totalIbk = totalIbk ? parseFloat(totalIbk) : 0;

        this.setState({
            total,
            totalIbk
        });
    }

    loadInstallments = () => {
        let installmentsList = JSON.parse(window.localStorage.getItem("__installments__"));
        console.log(installmentsList)
        this.setState({
            installmentsList
        });
    }

    loadUser = () => {
        let user = JSON.parse(window.localStorage.getItem("__user__"));

        this.setState({
            user
        })
    }

    loadAddress = () => {
        let address = JSON.parse(window.localStorage.getItem("__address__"));

        this.setState({
            address
        })
    }

    loadGateway = () => {
        let gateway = JSON.parse(window.localStorage.getItem("__gateway__"));

        this.setState({
            gateway
        })
    }

    loadShippingItems = () => {
        let shippingItems = JSON.parse(window.localStorage.getItem("__shippingItems__"));

        this.setState({
            shippingItems
        })
    }

    stores = () => {
        let stores = JSON.parse(window.localStorage.getItem("__stores__"));

        this.setState({
            stores
        })
    }

    getCheckoutId = () => {
        const { match } = this.props;

        let id = match.params.id;
        return id;
    }

    loadCheckout = async () => {
        let id = this.getCheckoutId();

        let checkout = await getCheckout(id);
        if (checkout.data) {
            this.setState({
                checkout: checkout.data,
            });
        }
    }

    loadPayuForms = () => {
        console.log("Creando elemento loadPayuForms")
        const { promo } = this.state;

        if (promo)
            return;

        const fonts = [
            {
                src: 'https://fonts.googleapis.com/css?family=Source+Code+Pro',
            }
        ]

        const { type } = this.state;
        let key = KEYS.payuApiKey;

        $('#card-number').empty();
        $('#exp-date').empty();

        if (type == '3') {
            key = KEYS.payuInstallmentsApiKey;
        } else {
            key = KEYS.payuApiKey;
        }

        const formElements = new window.POS.Fields(key, {
            fonts
        })

        const placeholders = {
            cardNumber: 'Ingresa un número de tarjeta',
            expDate: 'MM / YY'
        }

        const style = {
            base: {
                fontWeight: 600,
                height: '40px',
                fontSize: '16px',
                color: '#495057'
            },
        };

        const classes = {
            base: 'payu-input'
        }

        const cardNumber = formElements.create('cardNumber', {
            placeholders,
            classes,
            style
        })
        cardNumber.mount('#card-number');

        const expiry = formElements.create('creditCardExpiry', {
            placeholders,
            classes,
            style
        })
        expiry.mount('#exp-date');

        cardNumber.on('change', (event) => {
            if (!event.bin) {
                this.setState({
                    validBin: false
                })
                return;
            }

            
            let e = {
                target: {
                    value: event.bin
                }
            };
            this.validateCardNumber(e);
            this.getDiscount(e);
            this.validateIbkCardNumber(e);
        });

        cardNumber.on('blur', (event) => {
            if (!event.bin)
                return;

            if (!event.complete) {
                this.toggleFieldError('cardNumber', true);
            } else {
                this.toggleFieldError('cardNumber', false);
            }

            let e = {
                target: {
                    value: event.bin
                }
            };

            this.getDiscount(e);
        });

        expiry.on('blur', (event) => {
            if (!event.complete) {
                this.toggleFieldError('expirationDate', true);
            } else {
                this.toggleFieldError('expirationDate', false);
            }
        });

        this.setState({
            cardObject: cardNumber
        });
        console.log("Se termino de crear el objeto tarjeta");
        console.log(cardNumber);
    }

    loadPromo = () => {
        let promo = JSON.parse(localStorage.getItem("__promo__"));

        if (promo) {
            this.setState({
                showPrices: promo.showPrices
            })
        }
    }

    getStepNumber = () => {
        let number = $(".checkout__body__form").length;
        this.setState({
            stepNumber: number + 1
        });
    }

    clearSession = () => {
        window.localStorage.removeItem("__customerId__");
        window.localStorage.removeItem("__code__");
        window.localStorage.removeItem("__authInfo__");

        this.setState({
            type: null
        })
    }

    getMilesToken = async () => {
        let result = await getMilesToken();

        if (result.status === 200) {
            this.setState({
                milesToken: result.data
            });
        }
    }

    showMilesLoginForm = () => {
        let src = "";
        const { milesToken } = this.state;

        if (milesToken) {
            src = KEYS.interbankLoginPageV2 + "?client_id=SHOPSTAR&redirect_uri=" + KEYS.redirectUriV2 + "&cancel_uri=" + KEYS.redirectUriV2 + "&scope=login&response_mode=app_message&token=" + milesToken.token.access_token;
        }

        return (<React.Fragment><iframe src={src} frameBorder="0"></iframe></React.Fragment>);
    }

    validateMilesLogin = () => {
        let _context = this;

        var milesLoginWatcher = setInterval(() => {
            let authInfo = JSON.parse(window.localStorage.getItem("__authInfo__"));

            if (authInfo) {
                setTimeout(() => {
                    clearInterval(milesLoginWatcher);
                }, 1000);

                _context.toggleModal("#benefitLoginModal", "hide");

                window.localStorage.setItem("__customerId__", authInfo.customerId);
                window.localStorage.setItem("__code__", authInfo.code);
                window.localStorage.removeItem("__authInfo__");

                _context.authenticate();
            }
        }, 4000);
    }

    authenticate = async () => {
        // const { , setMiles } = this.props;
        const { checkout, miles, type } = this.state;

        let customerId = localStorage.getItem("__customerId__");
        let code = localStorage.getItem("__code__");

        let _miles = miles;
        _miles.loading = true;

        if (!customerId)
            return;

        this.setState({ type: '2', miles: _miles })

        let model = {
            customerId: customerId,
            checkOutId: checkout.draftId,
            code: code
        }

        let tokenResponse = await getOauthToken(model);

        if (tokenResponse.status !== 200) {
            console.error("Error getting miles token");
            return;
        }

        let milesModel = {
            checkOutId: checkout.draftId,
            tokenMiles: tokenResponse.data.access_token,
            customerId: customerId,
        }

        let milesResponse = await getMiles(milesModel);

        if (milesResponse.status === 200) {
            let selectedMiles = milesResponse.data.miles;
            const exchange = milesResponse.data.miles / milesResponse.data.exchange;
            const totalOrder = checkout.total;

            if (selectedMiles > 0)
                await this.getInterbankDiscount()

            if (milesResponse.data.exchange > totalOrder) {
                selectedMiles = this.calculateSelectedMilesAmount(milesResponse.data);
            }
            _miles = {
                total: milesResponse.data.miles,
                exchange: milesResponse.data.exchange,
                selected: Math.ceil(selectedMiles),
                change: exchange
            };

            _miles.loading = false;
            _miles.tokenMiles = tokenResponse.data.access_token;
            _miles.customerId = tokenResponse.data.customerId;
            // setMiles(_miles, type);
            this.setState({ miles: _miles });
        } else {
            this.clearSession()
        }
    }

    getInterbankDiscount = async () => {
        const { checkout, type } = this.state;

        let model = {};

        if (type == '2') {
            let bin = "998422";
            model = {
                bin: bin,
                installments: 1,
                referenceValue: checkout.total,
                paymentSystem: 1,
                customerId: window.localStorage.getItem("__customerId__")
            }

            await getDiscount(checkout.draftId, model);
            await this.loadCheckout();
            this.getInstallments(checkout.draftId);
        }
    }

    calculateSelectedMilesAmount = (miles) => {
        const { checkout } = this.state;
        const exchange = miles.miles / miles.exchange;
        const totalOrder = checkout.total;

        let milesAmount = checkout.total * exchange;
        let milesDiscount = miles / exchange;

        if (milesDiscount < totalOrder) {
            milesAmount += 1;
        }

        return milesAmount;
    }

    handleChangeValue = (field, event) => {
        let value = event.target.value;

        if (field === 'unmaskedCvv' || field === 'placeHolderDocumentNumber' || field === 'entityIdentificationNumber') {
            var re = /^[0-9]*$/;
            if (!re.test(value))
                return;
        }

        if (!value || value === "") {
            this.toggleFieldError(field, true)
        } else {
            this.toggleFieldError(field, false)
        }

        this.setState({
            [field]: value
        });
    }

    handleChangeMiles = (event) => {
        const { checkout, miles, type } = this.state;

        let _miles = miles;
        let value = event.target.value;

        if (value > 0) {
            if (value > _miles.total) {
                value = _miles.total;
            }

            if ((value / miles.change) > checkout.total) {
                value = Math.ceil(checkout.total * miles.change);
            }
        } else {
            value = 0;
        }

        _miles.selected = value;

        this.setState({
            miles: _miles
        })
    }

    onSelectPaymentMethod = (type) => {
        const { miles } = this.state;
        // const { setMiles } = this.props;

        let _context = this;

        let customer = localStorage.getItem("__customerId__");
        if (type === '2') {

            if (!customer) {
                $("#benefitLoginModal").modal("show");
                this.validateMilesLogin();

                return;
            } else {
                this.setState({ type });
            }
        } else {
            this.setState({ type });
        }

        // setMiles(miles, type);

        setTimeout(() => {
            _context.loadPayuForms();
        }, 500)
    }

    closeValidateMilesModal = () => {
        $("#milesModal").modal("hide");
    }

    formatMoney = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' }).format(amount) : 'S/ 0.00';
    }

    formatNumber = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE').format(amount) : '0';
    }

    validateCardNumber = (e) => {
        const { checkout } = this.state;

        let cardNumber = e.target.value;

        var re = /^[0-9]*$/;
        if (!re.test(cardNumber))
            return;

        let card = valid.number(cardNumber);
        let cards = {
            visa: "Visa",
            amex: "American Express",
            diners: "Diners",
            mastercard: "Mastercard"
        }
        let validCard = "";
        let cardType = "";
        let cardLength = 16;
        let installmentOptions = [];
        let cvvLength = 0;

        validCard = card.isValid ? 'valid' : '';

        if (card.card) {
            cardType = card.card.type;
            cardLength = Math.max.apply(null, card.card.lengths);

            if (checkout && checkout.shippingData) {
                let paymentData = checkout.shippingData.paymentData.installmentOptions.filter((el, index) => {
                    return el.paymentName === cards[card.card.type];
                })[0];
                if (paymentData) {
                    installmentOptions = paymentData.installments;
                }
            }
        }

        if (card.code && card.code.name === 'CVV') {
            cvvLength = card.code.size;
        } else {
            cvvLength = 3;
        }

        this.toggleFieldError('cardNumber', false)

        this.setState({
            cardNumber: cardNumber,
            validCard: validCard,
            cardType: cardType,
            cardLength: cardLength,
            installmentOptions: installmentOptions,
            cvvLength: cvvLength
        });
    }

    validateIbkCardNumber = (e) => {
        const { type } = this.state;

        let cardNumber = e.target.value;

        let bines = IBK_BINES;

        if (type != '3') {
            bines = bines.concat(IBK_DEBIT_BINES);
        }

        let ibkCard = 'invalid';
        bines.forEach(bin => {
            if (cardNumber.indexOf(bin) > -1) {
                ibkCard = 'valid';
            }
        });

        if (ibkCard === 'invalid') {
            $('#installmentsList').collapse('hide');
            this.setState({
                installments: 1
            })
        }

        this.setState({ isIbkCard: ibkCard });
    }

    getDiscount = async (e) => {
        const { checkout, type, validBin } = this.state;

        if(validBin) {
            return;
        }

        let cards = {
            visa: "Visa",
            amex: "American Express",
            diners: "Diners",
            mastercard: "Mastercard"
        }
        let model = {};

        // || (e.type === "blur" && e.target.value.length >= 6)
        if (e.target.value.length == 6) {
            let bin = e.target.value.substring(0, 6);
            model = {
                bin: bin,
                installments: 1,
                referenceValue: checkout.total,
                paymentSystem: 1,
                customerId: window.localStorage.getItem("__customerId__")
            }

            if (type == 2) {

            } else {
                let checkoutId = checkout.draftId;
                await getDiscount(checkout.draftId, model);
                await this.loadCheckout();
                this.getInstallments(checkoutId);
            }

            this.setState({
                validBin: true
            })
        }
    }

    getInstallments = async (checkoutId) => {
        let installmentsResponse = await getInstallments(checkoutId);

        if (installmentsResponse.error)
            return;

        this.setState({
            installmentsList: installmentsResponse.data
        });
    }

    onCvvBlur = () => {
        const { cvv, unmaskedCvv } = this.state;
        let _cvv = cvv;
        let _unmaskedCvv = unmaskedCvv;

        if (_unmaskedCvv.indexOf('*') > -1)
            return;

        if (_unmaskedCvv.length < 3)
            return;

        _cvv = _unmaskedCvv;
        this.setState({
            cvv: _cvv
        });
        clearTimeout(window.maskCvvTimeout);

        window.maskCvvTimeout = setTimeout(() => {
            _unmaskedCvv = _unmaskedCvv.replace(/./g, '*');
            this.setState({
                unmaskedCvv: _unmaskedCvv
            });
        }, 2000);
    }

    getMilesDiscount = () => {
        return 0;
    }

    getOrderTotal = () => {
        const { checkout } = this.state;

        return checkout.total;
    }

    renderInstallmentsList = () => {
        const { checkout, installments, installmentsList, isIbkCard, errors } = this.state;
        console.log(installmentsList)
        if (!installmentsList) {
            return (
                <div className="installments-list">
                    <label>Dividir en cuotas con <LogoDivideIt></LogoDivideIt></label>
                    <div className="card">
                        <div className="card-header" id="installmentsListHeading">
                            <h2 className="mb-0">
                                <button className="btn btn-link btn-block text-left collapsed" disabled={true}>
                                    Pago único de {this.formatMoney(checkout.total)}
                                </button>
                            </h2>
                        </div>
                    </div>
                </div>
            );
        }

        let selectedInstallments = installments;
        let installmentsControl = "";
        let installmentsText = "";
        let installmentsAmounts = installmentsList;

        if ((selectedInstallments == 1 || selectedInstallments == 0) && checkout) {
            installmentsText = "Pago único de " + this.formatMoney(checkout.total);
        } else {
            let installmentInfo = installmentsAmounts.filter((el, i) => {
                return selectedInstallments == el.installments;
            })
            if (installmentInfo.length > 0) {
                installmentsText = selectedInstallments + " cuotas de " + this.formatMoney(installmentInfo[0].amount);
            }
        }

        installmentsControl = (
            <div className={isIbkCard == 'valid' ? 'installments-list valid-ibk-card' : 'installments-list '}>
                <label>Dividir en cuotas con <LogoDivideIt></LogoDivideIt></label>
                <div className="card">
                    <div className="card-header" id="installmentsListHeading">
                        <h2 className="mb-0">
                            {/*disabled={this.state.isIbkCard !== 'valid'}*/}
                            <button className={'btn btn-link btn-block text-left collapsed' + (errors.installments || isIbkCard == 'invalid' ? ' border-danger' : '')}
                                type="button"
                                data-toggle="collapse"
                                data-target="#installmentsList"
                                aria-expanded="false"
                                aria-controls="collapseOne"
                            >
                                {installmentsText}
                                {/* {isIbkCard == 'valid' &&
                                    <i className="fas"></i>
                                } */}
                            </button>
                        </h2>
                    </div>

                    <div id="installmentsList" className="collapse" aria-labelledby="installmentsListHeading">
                        <ul className="list-group list-group-flush">
                            {
                                installmentsAmounts.map((el, i) => {
                                    return (
                                        <li className="list-group-item" key={'installment-' + i}>
                                            <a role="button" onClick={(e) => { this.setInstallments(el) }}>
                                                <span>{el.installments} cuotas de {this.formatMoney(el.amount)}&nbsp;<span style={{ display: 'none' }} className="installmentsTotal">(Total {this.formatMoney(el.amount * el.installments)})</span></span>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );

        return <React.Fragment>{installmentsControl}</React.Fragment>;
    }

    setInstallments = (item) => {
        $(".installments-list .card").removeClass("error");
        $('#installmentsList').collapse('hide');

        this.toggleFieldError('installments', false);

        this.setState({
            installments: item.installments,
            installmentAmount: item.amount
        });
    }

    toggleModal = (modal, action) => {
        $(modal).modal(action);
    }

    validState = () => {
        const { checkout } = this.state;
        let state = this.state;
        let valid = true;
        let errors = {};

        let requiredFields = ["cardNumber", "placeHolder", "cvv", "unmaskedCvv", "placeHolderDocumentNumber"];

        if (checkout.total === 0) {
            requiredFields = [];
        }

        if (state.billingAddress === "diff") {
            requiredFields = requiredFields.concat(["department", "province", "district", "addressLine", "addressNumber", "postalCode"]);
        }

        if (state.orderType === "factura") {
            requiredFields = requiredFields.concat(["entityName", "entityIdentificationNumber"]);
        }

        for (let field in state) {
            if (requiredFields.indexOf(field) > -1 && (!state[field] || state[field] === "" || state[field] === "0")) {
                errors[field] = true;
                valid = false;
            }
            if (state.orderType === "factura") {
                if (field === "entityIdentificationNumber" && (state[field].length !== 11 || isNaN(state[field]))) {
                    errors[field] = true;
                    valid = false;
                }
            }
        }

        this.setState({ errors });
        return valid;
    }

    sendHubspotData = async () => {
        const { user, checkout } = this.state;

        let categories = [];

        let externalCart = checkout.externalCart;
        externalCart.forEach((el) => {
            let cart = JSON.parse(el.externalJsonShipping);
            let cartItems = cart.items;
            cartItems.forEach((item) => {
                let categoryTree = item.productCategories;
                let categoryName = '';

                for (let k in categoryTree) {
                    categoryName = categoryTree[k];
                }

                categories.push(categoryName);
            });
        });

        let model = {
            firstname: user.firstName,
            lastname: user.lastName,
            email: user.email,
            phone: user.phone,
            mobilephone: user.phone,
            dni: user.identificationNumber,
            orden_de_compra: '123123123123',
            cuenta_maestra: 'Buyers',
            propietario_asignado___atencion_al_cliente: 'hola@shopstar.com.pe',
            categoria_de_interes: categories.join(',')
        }

        await sendHubspotData(user.email, model);
    }

    handleSubmitPayment = async () => {
        const { orderType, miles, isIbkCard, policies, billingAddress, type, installments, entityName, entityIdentificationNumber, showPrices, cardObject,
            checkout, user, address, stores, shippingItems, gateway, promo } = this.state;

        let payment = {
            ...this.state
        };

        payment.checkout = undefined;
        payment.errors = undefined;
        payment.installmentsList = undefined;
        payment.stores = undefined;
        payment.ubigeos = undefined;

        payment.cardObject = null;

        let gatewayName = gateway.name;
        let gatewaySessionId = gateway.sessionId;
        let model = {};
        let milesDiscount = this.calculateMilesDiscount();
        let customerId = window.localStorage.getItem("__customerId__");
        let ip = window.localStorage.getItem("__ipAddress__");
        let _stores = stores;
        let _address = address;

        let uncheckedPolicies = false;
        if (!policies.terms) {
            uncheckedPolicies = true;
            this.toggleFieldError("terms", true);
        }

        if (!policies.personalData) {
            uncheckedPolicies = true;
            this.toggleFieldError("personalData", true);
        }

        if (uncheckedPolicies)
            return;

        let milesAmount = this.calculateRestAmount();

        if (type == '2' && milesAmount == 0) {

        } else {
            if (checkout.total === 0 && !promo) {
                this.setState({
                    error: "Algo ha ido mal. El monto de tus productos debe ser mayor al monto de tu cupón aplicado."
                });

                setTimeout(() => {
                    this.toggleModal("#basicErrorModal", "show");
                }, 100);

                return;
            }
        }

        if (type == '3') {
            if (installments <= 1) {
                this.toggleFieldError('installments', true);
                return
            }

            if (isIbkCard !== 'valid') {
                // this.toggleFieldError('installments', true);
                return;
            }
        }

        let valid = this.validState();
        // if (!valid)
        //     return;

        let usePayu = true;


        if ((type == '2' && milesAmount == 0) || type == '5') {
            usePayu = false;
        } else {
            if (!valid)
                return;
        }

        if (promo) {
            usePayu = false;
        }

        //Payu call
        if (usePayu) {
            this.toggleModal("#loadingModal", "show");

            const additionalData = {
                holder_name: payment.placeHolder,
                identity_document: {
                    type: 'CC',
                    number: payment.placeHolderDocumentNumber
                }
            }
            const result = await window.POS.createToken(cardObject, {
                additionalData,
                environment: KEYS.payuEnv
            })
            if (!result.error) {
                let payuResponse = JSON.parse(result);

                gatewaySessionId = payuResponse.token;
                payment.cardNumber = this.maskPayuCreditCardNumber(payuResponse.bin_number, payuResponse.last_4_digits);
                let expirationDateArray = payuResponse.expiration_date.split('/');

                if (expirationDateArray && expirationDateArray.length === 2) {
                    this.setState({
                        year: `20${expirationDateArray[1]}`,
                        month: expirationDateArray[0],
                        maskedCardNumber: payment.cardNumber,
                    });

                    payment.year = '****'; //expirationDateArray[1];
                    payment.month = '**'; //expirationDateArray[0];
                }
            } else {
                this.setState({
                    error: "Algo ha ido mal. Por favor revisa que los datos de tu tarjeta sean los correctos."
                });

                setTimeout(() => {
                    this.toggleModal("#loadingModal", "hide");
                    this.toggleModal("#basicErrorModal", "show");
                }, 500);

                return;
            }
        } else {
            this.toggleModal("#loadingModal", "show");
        }

        saveDataLayerEvent({
            'event': 'virtualEvent',
            'category': 'Checkout - Metodo de pago',
            'action': 'Seleccionar boton',
            'label': 'Comprar ahora'
        });

        let storedData = localStorage.getItem("__state__");
        if (storedData) {
            storedData = JSON.parse(storedData);
            _stores = storedData.stores;
        }

        window.localStorage.setItem("__checkoutId__", checkout.draftId);

        payment.amountToAuthorize = this.getOrderTotal();
        payment.creditCardTokenId = gatewaySessionId;

        if (payment.type == 2 && customerId) {
            if (milesDiscount < checkout.total && isIbkCard !== "valid") {
                setTimeout(() => {
                    this.toggleModal("#loadingModal", "hide");
                    $("#loadingPaymentModal").modal("hide");
                    $("#benefitCardErrorModal").modal("show");
                }, 500);
                return;
            }
            model.tokenMiles = miles.tokenMiles;
            payment.miles = {
                value: miles.selected,
                customerId: miles.customerId
            }
        } else {
            payment.miles = undefined;
        }

        if (payment.type == 3) {
            payment.paymentMethodType = "payment-installments";
        } else if(payment.type == 5) {
            payment.paymentMethodType = "payment-pago-efectivo";
        } else {
            payment.paymentMethodType = "default";
        }

        if (gatewayName === 'payu') {
            if (payment.cardType === 'visa') {
                payment.cardType = 'VISA';
            } else if (payment.cardType === 'mastercard') {
                payment.cardType = 'MASTERCARD';
            } else if (payment.cardType === 'diners-club') {
                payment.cardType = 'DINERS';
            } else if (payment.cardType === 'american-express') {
                payment.cardType = 'AMEX';
            }
        }

        _address.ubigeos = null;

        model.checkOutId = checkout.draftId;
        model.orderType = orderType;
        model.entityName = entityName;
        model.entityIdentificationNumber = entityIdentificationNumber;
        model.shipping = address;

        //Payu hub data
        model.deviceSessionId = gateway.sessionId;
        model.userAgent = navigator.userAgent;
        model.ipAddress = ip;

        if (billingAddress === 'diff') {
            model.billing = {
                department: this.state.department,
                province: this.state.province,
                district: this.state.district,
                addressLine: this.state.addressLine,
                addressNumber: this.state.addressNumber,
                flatNumber: this.state.flatNumber,
                reference: this.state.reference,
                postalCode: this.state.postalCode,
                countryISO: "PER",
            };
        } else {
            model.billing = address;
        }

        model.payment = payment;

        model.shipping.firstName = user.firstName;
        model.shipping.lastName = user.lastName;
        model.shipping.phone = user.phone;
        model.shipping.cellPhone = user.phone;

        model.authorizePersonalData = policies.privacy;

        if (policies.privacy) {
            let emblueModel = {
                "eventName": "shopstar",
                "email": user.email,
                "attributes": {
                    "nombre": user.firstName,
                    "apellidos": user.lastName,
                    "telefono": user.phone,
                    "DNI": user.identificationNumber,
                    "AceptoTerminosCondiciones": policies.terms,
                    "aceptoTerminosPagoCanje": policies.personalData,
                    "aceptoUsoDeDatos": policies.privacy,
                    "tag": "Checkout Millas"
                }
            };

            sendEmblueData(emblueModel);
        }

        let items = [];

        _stores.forEach((el, i) => {
            el.products.forEach((p, j) => {
                let item = null;

                if (el.shippingMethod === "delivery") {
                    if (!el.selectedDeliverySla) {
                        let sla = p.logisticInfo.slas.filter((s, k) => {
                            return s.deliveryChannel === "delivery";
                        });

                        sla = sla.length > 0 ? sla[0] : null;
                        el.selectedDeliverySla = sla;
                    }
                    let vtxJson = checkout.externalCart.filter((s, k) => {
                        return s.externalSellerId === el.store;
                    });
                    let shippingPrice = 0;
                    let productSla = [];

                    if (vtxJson.length > 0) {
                        vtxJson = vtxJson[0];
                        let vtxShipping = JSON.parse(vtxJson.externalJsonShipping);
                        if (vtxShipping && vtxShipping.shippingData && vtxShipping.shippingData.logisticsInfo) {
                            vtxShipping.shippingData.logisticsInfo.forEach((lInfo, l) => {
                                let slas = lInfo.slas;
                                if (p.requestIndex === lInfo.itemIndex || p.id === lInfo.itemId) {
                                    productSla = slas.filter((s, k) => {
                                        return p.logisticInfo.selectedSla === s.id;
                                    });

                                    if (productSla.length > 0)
                                        shippingPrice = productSla[0].price / 100;
                                }
                            })
                        }
                    }

                    item = {
                        "addressId": address.addressId, //"addressId": `addressd-${i}-${j}`,
                        "itemIndex": p.requestIndex || j,
                        "itemId": p.id,
                        "selectedDeliveryChannel": el.shippingMethod,
                        "selectedSla": el.selectedDeliverySla.id,
                        "price": shippingPrice, //el.selectedDeliverySla.price / 100,
                        "deliveryWindow": el.deliveryDeliveryWindow,
                        "shippingEstimate": el.selectedDeliverySla.shippingEstimate,
                        "lockTTL": el.selectedDeliverySla.lockTTL,
                        "shippingEstimateDate": el.selectedDeliverySla.shippingEstimateDate,
                        "addressLine": address.addressLine,
                        "addressNumber": address.addressNumber,
                        "reference": address.reference,
                        "countryISO": "PER",
                        "postalCode": address.postalCode,
                        "department": address.department,
                        "province": address.province,
                        "district": address.district
                    }
                } else if (el.shippingMethod === "pickup-in-point") {
                    let storeInfo = shippingItems.items.find((shippingItem, k) => {
                        return shippingItem.productId === p.id;
                    });

                    item = {
                        "addressId": storeInfo ? storeInfo.storeInfo.addressId : `addressw-${i}-${j}`,
                        "itemIndex": p.requestIndex || j,
                        "itemId": p.id,
                        "selectedDeliveryChannel": el.shippingMethod,
                        "selectedSla": el.selectedSla.id,
                        "price": el.selectedSla.price / 100,
                        "deliveryWindow": el.deliveryWindow,
                        "shippingEstimate": el.selectedSla.shippingEstimate,
                        "lockTTL": el.selectedSla.lockTTL,
                        "shippingEstimateDate": el.selectedSla.shippingEstimateDate,
                        "storePickUpAddress": el.address,
                        "addressLine": storeInfo.storeInfo.street,
                        "addressNumber": storeInfo.storeInfo.number,
                        "reference": storeInfo.storeInfo.reference,
                        "countryISO": storeInfo.storeInfo.country,
                        "postalCode": storeInfo.storeInfo.postalCode,
                        "department": storeInfo.storeInfo.state,
                        "province": storeInfo.storeInfo.city,
                        "district": storeInfo.storeInfo.neighborhood,
                        "geoCoordinates": storeInfo.storeInfo.geoCoordinates,
                        "alternativePickupPersonFullName": el.alternativePickupPersonFullName,
                        "alternativePickupPersonIdentificationNumber": el.alternativePickupPersonIdentificationNumber,
                        "alternativePickupPersonIdentificationType": el.alternativePickupPersonIdentificationType,
                        "alternativePickupPersonPhone": el.alternativePickupPersonPhone,
                    }
                }

                item.sellerId = el.store;
                items.push(item);
            })
        })

        this.setState({
            loading: true
        })

        model.shippingMethods = items;
        model.hiddenPrices = !showPrices;
console.log(model);
this.toggleModal("#loadingModal", "hide");
return;
        let responseOrder = await saveOrderV5(model);

        if (responseOrder.status !== 200 && responseOrder.status !== 201) {
            let message = responseOrder.data;
            if (responseOrder.status === 500) {
                message = "Ocurrió un error, inténtalo nuevamente"
            }

            this.setState({
                loading: false,
                error: message
            });

            this.toggleModal("#loadingModal", "hide");
            setTimeout(() => {
                this.toggleModal("#errorModal", "show");
            }, 100);
            return;
        }


        let callback = async (response, order) => {
            if (response.error) {
                // $("#loadingPaymentModal").modal("hide");
                // $("#kushkiErrorModal").modal("show");
                // $("#trackEvent").attr("data", response.message);
                // $("#trackEvent").trigger('click');

                //-->Invalidate order
                // let invalidateModel = {
                // 	checkOutId: checkoutId,
                // 	orderGroup: transaction.transaction.orders[0].orderGroup,
                // 	reason: response.message
                // };

                // invalidateOrder(invalidateModel);
                // return;
            }

            let token = response.token;
            let sessionId = response.sessionId;

            let kushkiPayment = {
                sessionId: sessionId,
                creditCardTokenId: token,
                cardNumber: payment.cardNumber,
                placeHolder: payment.placeHolder,
                cvv: payment.cvv,
                year: payment.year,
                month: payment.month,
                installments: payment.installments,
                cardType: payment.cardType,
                orderNumber: order.orderNumber,
                amountToAuthorize: order.total,
                paymentMethodType: payment.paymentMethodType,
                placeHolderDocumentNumber: payment.placeHolderDocumentNumber
            };

            if (gatewayName === 'payu') {
                if (payment.cardType === 'visa') {
                    kushkiPayment.cardType = 'VISA';
                } else if (payment.cardType === 'mastercard') {
                    kushkiPayment.cardType = 'MASTERCARD';
                } else if (payment.cardType === 'diners-club') {
                    kushkiPayment.cardType = 'DINERS';
                } else if (payment.cardType === 'american-express') {
                    kushkiPayment.cardType = 'AMEX';
                }
            }

            authorizeModel.payments.push(kushkiPayment);

            if (authorizeModel.payments.length === orderInfo.orders.length) {
                authorizeModel.gateway = gatewayName;
                authorizeModel.tokenMiles = miles.tokenMiles;
                this.authorize(authorizeModel);
            }
        }



        let orderInfo = responseOrder.data;

        const urlParams = new URLSearchParams(window.location.search);
        const version = urlParams.get('version');

        window.location.href = "/checkout/exito/" + orderInfo.orders[0].orderNumber.split("-")[0] + "?showPrices=" + showPrices;
        return;

        let authorizeModel = {
            checkOutId: checkout.draftId,
            orderGroup: orderInfo.orders[0].orderNumber.split("-")[0],
            payments: [],
            billing: address
        }

        if (orderInfo.orders && orderInfo.orders.length > 0) {
            orderInfo.orders.forEach((order, i) => {
                if (order.total > 0) {
                    let paymentGateway = gatewayName;
                    let paymentSessionId = gatewaySessionId;
                    let paymentModel = {
                        amount: order.total,
                        currency: "PEN",
                        card: {
                            name: payment.placeHolder,
                            number: payment.cardNumber,
                            cvc: payment.cvv,
                            expiryMonth: payment.month,
                            expiryYear: payment.year
                        },
                    };

                    if (paymentGateway === 'payu') {
                        callback({ token: '', sessionId: paymentSessionId }, order);
                    } else if (paymentGateway === 'kushki') {
                        if (payment.type == 3) {
                            window.kushkiInstallments.requestToken(paymentModel, (response) => {
                                callback(response, order);
                            });
                        } else {
                            window.kushki.requestToken(paymentModel, (response) => {
                                callback(response, order);
                            });
                        }
                    }
                } else {
                    callback({ token: null }, order);
                }
            })
        }
    }

    authorize = async (model) => {
        const { showPrices } = this.state;
        var authResponse = await authorizePayment(model);

        if (authResponse.status !== 200 && authResponse.status !== 201) {
            this.setState({
                loading: false,
                error: authResponse.data
            })

            this.toggleModal("#loadingModal", "hide");
            this.toggleModal("#errorModal", "show");
            return;
        }

        window.location.href = "/checkout/exito/" + authResponse.data.orderGroup + "?showPrices=" + showPrices;
    }

    changeMilesAmount = (e, op) => {
        console.log("Iniciando CheckoutPaymentView.changeMilesAmount")
        const { checkout, miles, type } = this.state;
        const change = miles.change;

        let amount = op * 100;
        let selected = parseFloat(miles.selected) + amount;

        if (selected > 0) {
            if (selected > miles.total) {
                selected = miles.total;
            }

            if ((selected / change) > checkout.total) {
                selected = Math.ceil(checkout.total * change);
            }
        } else {
            selected = 0;
        }

        let _miles = miles;
        _miles.selected = Math.round(selected);

        this.setState({ miles: _miles });
    }

    calculateExchange = (e) => {
        const { miles } = this.state;
        let _miles = miles;

        _miles.exchange = miles.selected / miles.change;
        this.setState({ miles: _miles });
    }

    calculateMilesDiscount = () => {
        const { checkout, miles } = this.state;

        let discount = miles.selected / miles.change;

        if (discount > checkout.total) {
            discount = checkout.total;
        }

        return discount
    }

    calculateRestAmount = () => {
        const { checkout, miles, type } = this.state;

        let _context = this;

        if (type != 2) {
            return checkout.total;
        }
        let rest = (checkout.total - (miles.selected / miles.change));

        if (rest < 0) {
            rest = 0;
        }

        if (rest > 0) {
            let cardElement = document.getElementById("card-number");
            if (!cardElement) {
                setTimeout(() => {
                    _context.loadPayuForms();
                }, 500)
            }
        }

        return rest;
    }

    calculateOrderTotalMiles = () => {
        const { checkout, totalIbk } = this.state;

        if (!checkout)
            return 0;

        let ibkTotal = checkout.total;

        if (totalIbk && totalIbk > 0)
            ibkTotal = totalIbk;

        var total = ibkTotal * 33.33333;

        return Math.ceil(total);
    }

    onChangePolicyCheck = (e) => {
        const { policies, errors } = this.state;
        const name = e.target.getAttribute("name");

        let p = policies;

        p[name] = !p[name];

        this.toggleFieldError(name, false);
        this.setState({ policies: p })
    }

    toggleFieldError = (name, value) => {
        const { errors } = this.state;
        let _errors = errors;

        _errors[name] = value;
        this.setState({ errors: _errors });
    }

    loadProvinces = (e) => {
        const { ubigeos, department } = this.state;

        let provinces = PROVINCES[e.target.value];

        if (provinces && provinces.length > 0) {
            ubigeos.provinces = provinces;

            this.setState({ ubigeos: ubigeos });
        }
    }

    loadDistricts = (e) => {
        const { ubigeos, province } = this.state;

        let districts = DISTRICTS[e.target.value];

        if (districts && districts.length > 0) {
            ubigeos.districts = districts;

            this.setState({ ubigeos: ubigeos });
        }
    }

    setPostalCode = (e) => {
        const { ubigeos } = this.state;
        let value = e.target.value;
        let district = ubigeos.districts.filter((e, i) => { return e.id == value })[0];

        this.setState({
            postalCode: district.ubigeo
        });
    }

    handleChangeBillingAddress = (event, type) => {
        this.setState({
            billingAddress: type
        });
    }

    handleOrderType = () => {
        var orderType = "";
        if (this.state.orderType == 'boleta') {
            orderType = 'factura';
        } else {
            orderType = 'boleta';
        }

        this.setState({ orderType: orderType })
    }

    maskCreditCardNumber = () => {
        const { cardNumber } = this.state;
        if (!cardNumber || cardNumber === '')
            return '';

        return '************' + cardNumber.substring(cardNumber.length - 4, cardNumber.length);
    }

    maskPayuCreditCardNumber = (bin, lastDigits) => {
        const { cardNumber, cardLength } = this.state;

        // let mask = new Array(cardLength - bin.length - lastDigits.length).join('*');

        if (!cardNumber || cardNumber === '')
            return '';

        return `${bin}******${lastDigits}`;
    }

    renderDivideItDescription = () => {
        const { installmentsList } = this.state;

        if (installmentsList.length > 0) {
            var lowerInstallment = installmentsList.at(-1);

            return (
                <React.Fragment>
                    Divide tu pago en {lowerInstallment.installments} cuotas de {this.formatMoney(lowerInstallment.amount)} al mes con <LogoDivideIt className="logo-ibk"></LogoDivideIt>
                </React.Fragment>
            )
        }

        return "";
    }

    renderInstallmentAmount = () => {
        const { installmentsList } = this.state;

        if (installmentsList.length > 0) {
            var lowerInstallment = installmentsList.at(-1);

            return (
                <React.Fragment>
                    <span className="total-payment">
                        {this.formatMoney(lowerInstallment.amount)}
                    </span>
                </React.Fragment>
            )
        }

        return "";
    }

    changePaymentType = () => {
        this.setState({
            type: null
        });
    }

    render() {
        const { type, error, miles, stepNumber, maskedCardNumber, cardType, policies, billingAddress, errors, ubigeos, isIbkCard, orderType, installmentAmount, installments,
            user, address, checkout, totalIbk, total, promo, enableBenefits, submitting, stores } = this.state;

        return (
            <div className="v3">
                <div className="header">
                    <a href="https://shopstar.pe">
                        <img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
                    </a>
                </div>
                <div className="container">
                    <div className="visible-mobile mt-4">
                        {
                            (checkout) &&
                            <Summary checkout={checkout} total={total} totalIbk={totalIbk}></Summary>
                        }
                    </div>
                    <div className="stepper">
                        <div className="stepper__item active completed">
                            <span>1</span>
                            <div className="name">Datos de cliente</div>
                        </div>
                        <div className="stepper__item active completed">
                            <span>2</span>
                            <div className="name">Forma de entrega</div>
                        </div>
                        <div className="stepper__item active">
                            <span>3</span>
                            <div className="name">Forma de pago</div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="section">
                                {
                                    (!type) &&
                                    <h3 className="section__title">Forma de pago</h3>
                                }
                                {
                                    (type) &&
                                    <React.Fragment>
                                        <a role="button" className="section__action-link mb-3" onClick={(e) => { this.changePaymentType() }}>Elegir otro médio de pago</a>
                                        {
                                            (type == '1') &&
                                            <h3 className="section__title">Pagar con Tarjeta débito o crédito Interbank</h3>
                                        }
                                        {
                                            (type == '2') &&
                                            <h3 className="section__title">Canjea tu compra con Millas Benefit</h3>
                                        }
                                        {
                                            (type == '3') &&
                                            <h3 className="section__title">Paga en cuotas con una tasa preferencial</h3>
                                        }
                                        {
                                            (type == '5') &&
                                            <h3 className="section__title">
                                                Paga sin tarjeta con
                                                <LogoPagoEfectivo></LogoPagoEfectivo>
                                            </h3>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    (checkout && checkout.total === 0 && promo) &&
                                    <React.Fragment>
                                        <div className="section__payment-alert">
                                            ¡Felicitaciones!
                                            <br />
                                            Tienes el 100% de descuento en tu compra.
                                        </div>
                                    </React.Fragment>
                                }
                                {
                                    (!type) &&
                                    <React.Fragment>
                                        {
                                            (checkout && checkout.total > 0) &&
                                            <div className="section__payment-methods">
                                                <div className={'section__payment-methods__item ' + (type === '1' ? 'selected' : '')} onClick={(e) => { this.onSelectPaymentMethod('1') }}>
                                                    <div className="section__payment-methods__item__info">
                                                        <h4>
                                                            <span>Tarjeta débito o crédito</span>
                                                            <span className="total-payment">
                                                                {this.formatMoney(total)}
                                                            </span>
                                                        </h4>
                                                        <LogoCardTypes className="logo"></LogoCardTypes>
                                                    </div>
                                                </div>
                                                <div className={'section__payment-methods__item ' + (type === '3' ? 'selected' : '')} onClick={(e) => { this.onSelectPaymentMethod('3') }}>
                                                    <div className="section__payment-methods__item__info">
                                                        <h4>
                                                            <span>Divide tu pago en cuotas con Interbank</span>
                                                            {this.renderInstallmentAmount()}

                                                        </h4>
                                                        <p>
                                                            {this.renderDivideItDescription()}
                                                        </p>
                                                        <span className="no-commission">Sin comisión</span>
                                                    </div>
                                                </div>
                                                <div className={'section__payment-methods__item ' + (type === '2' ? 'selected' : '')} onClick={(e) => { this.onSelectPaymentMethod('2') }}>
                                                    <div className="section__payment-methods__item__info">
                                                        <h4>
                                                            <span>Millas Interbank Benefit</span>
                                                            <span className="total-payment">
                                                                {this.formatNumber(this.calculateOrderTotalMiles())} Millas
                                                            </span>
                                                        </h4>
                                                        <p>Paga el equivalente al precio Interbank usando las Millas Benefit de tu tarjeta.</p>
                                                        <p id="totalMilesAmount" style={{ display: 'none' }}>
                                                            <b>Total de la compra  {this.formatNumber(this.calculateOrderTotalMiles())} Millas Benefit</b>
                                                        </p>
                                                        <LogoBenefits className="logo"></LogoBenefits>
                                                    </div>
                                                </div>
                                                <div className={'section__payment-methods__item ' + (type === '5' ? 'selected' : '')} onClick={(e) => { this.onSelectPaymentMethod('5') }}>
                                                    <div className="section__payment-methods__item__info">
                                                        <h4>
                                                            <span>Paga sin tarjeta con PagoEfectivo</span>
                                                            <span className="total-payment">
                                                                {this.formatMoney(total)}
                                                            </span>
                                                        </h4>
                                                        <p>Usa un código CIP para pagar en puntos físicos o por tu banca por Internet.</p>
                                                        <LogoPagoEfectivo></LogoPagoEfectivo>
                                                        <img src="/pagoefectivo-logo.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    (type) &&
                                    <React.Fragment>
                                        {
                                            (type == 3) &&
                                            <div className="divide-it">
                                                {this.renderInstallmentsList()}
                                            </div>
                                        }
                                        {
                                            (type == 2) &&
                                            <React.Fragment>
                                                <div className="miles-total">
                                                    <IconCheck></IconCheck>
                                                    Tienes {this.formatNumber(miles.total)} Millas Benefit disponibles para canjear en tu compra.
                                                </div>
                                                <div className="miles">
                                                    <div className="form-group">
                                                        <h5>¿Cuántas millas quieres canjear?</h5>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <button className="btn" type="button" onClick={(e) => { this.changeMilesAmount(e, -1) }}>-</button>
                                                            </div>
                                                            <input type="text" className="form-control" name="selected" value={miles.selected} onChange={(e) => { this.handleChangeMiles(e); this.calculateExchange(e) }} />
                                                            <div className="input-group-append">
                                                                <button className="btn" type="button" onClick={(e) => { this.changeMilesAmount(e, 1) }}>+</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="separator"></div>
                                                    <div className="miles__exchange">
                                                        <span>Costo de tu compra en Shopstar</span>
                                                        <span>
                                                            <b>{this.formatNumber(this.calculateOrderTotalMiles())} Millas</b>
                                                            <br />
                                                            Equivale: {this.formatMoney(checkout.total)}
                                                        </span>
                                                    </div>
                                                    <div className="separator"></div>
                                                    <div className="miles__total">
                                                        <span>Completar con tarjeta Interbank</span>
                                                        <b>
                                                            {this.formatMoney(this.calculateRestAmount())}
                                                        </b>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                        {
                                            (checkout && checkout.total > 0) &&
                                            <React.Fragment>
                                                {
                                                    ((type != 2 && type != 5) || this.calculateRestAmount() > 0) &&
                                                    <React.Fragment>
                                                        {
                                                            (type == 2 && this.calculateRestAmount() > 0) &&
                                                            <div className="section__title">Completa tu compra con tarjeta</div>
                                                        }
                                                        <div className="section__form credit-card">
                                                            <div className={"form-group cc-input " + this.state.validCard + (errors.cardNumber ? ' invalid' : '')}>
                                                                <label>Número de tarjeta</label>
                                                                <div id="card-number" className="form-control full-story-hidden payu-item"></div>
                                                                {
                                                                    (isIbkCard === 'valid') &&
                                                                    <i className="ibk-card-ico">
                                                                        <IconIbk></IconIbk>
                                                                    </i>
                                                                }
                                                                {
                                                                    (type == '3' && isIbkCard === 'invalid') &&
                                                                    <div className="invalid-ibk-card">
                                                                        <small className="text-danger">
                                                                            Para dividir su pago en cuotas, debe usar una tarjeta de cr&eacute;dito Interbank
                                                                        </small>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className={'form-group ' + (errors.placeHolder ? ' invalid' : '')}>
                                                                <label>Titular de la tarjeta</label>
                                                                <input type="text" className="form-control full-story-hidden"
                                                                    placeholder="Ingresa el nombre del titular"
                                                                    value={this.state.placeHolder}
                                                                    onChange={(e) => this.handleChangeValue('placeHolder', e)} />
                                                            </div>
                                                            {
                                                                (type == 3 || true) &&
                                                                <div className={'form-group ' + (errors.placeHolderDocumentNumber ? ' invalid' : '')}>
                                                                    <label>Documento de identidad</label>
                                                                    <input type="text" placeholder="Ingresa tu n&uacute;mero de documento" className="form-control" required name="placeHolderDocumentNumber" value={this.state.placeHolderDocumentNumber} onChange={(e) => this.handleChangeValue('placeHolderDocumentNumber', e)} />
                                                                </div>
                                                            }
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <div className={'form-group cc-input' + (errors.expirationDate ? ' invalid' : '')}>
                                                                        <label>Fecha de vencimiento</label>
                                                                        <div id="exp-date" className="form-control full-story-hidden payu-item"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className={"form-group" + (errors.unmaskedCvv ? ' invalid' : '')}>
                                                                        <label>
                                                                            Ingresa CVV
                                                                            <span className="cvv-help">
                                                                                <IconHelp></IconHelp>
                                                                                <div className="cvv-help__content">Es un código de verificación de 3 o 4 dígitos ubicado en el reverso de la tarjeta de crédito o débito.</div>
                                                                            </span>
                                                                        </label>
                                                                        <input type="text" className="form-control full-story-hidden"
                                                                            placeholder="Ingresar código"
                                                                            value={this.state.unmaskedCvv}
                                                                            maxLength={4}
                                                                            onChange={(e) => this.handleChangeValue('unmaskedCvv', e)}
                                                                            onBlur={(e) => this.onCvvBlur()} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }
                                        {
                                            (checkout) &&
                                            <React.Fragment>
                                                <div className="credit-card">
                                                    <div className="section__form">
                                                        {
                                                            (checkout.total > 0) &&
                                                            <React.Fragment>
                                                                <div className="form-group">
                                                                    <label>Comprobante</label>
                                                                    <select className="form-control full-story-hidden" value={this.state.orderType} onChange={(e) => this.handleChangeValue('orderType', e)}>
                                                                        <option value="boleta">Boleta</option>
                                                                        <option value="factura">Factura</option>
                                                                    </select>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            (orderType === 'factura') &&
                                                            <React.Fragment>
                                                                <div className={"form-group" + (errors.entityIdentificationNumber ? ' invalid' : '')}>
                                                                    <label>RUC</label>
                                                                    <input type="text" className="form-control" required name="entityIdentificationNumber" id="entityIdentificationNumber" value={this.state.entityIdentificationNumber} onChange={(e) => this.handleChangeValue('entityIdentificationNumber', e)} maxLength="11" />
                                                                </div>
                                                                <div className={"form-group" + (errors.entityName ? ' invalid' : '')}>
                                                                    <label>Raz&oacute;n Social</label>
                                                                    <input type="text" className="form-control" required name="entityName" id="entityName" value={this.state.entityName} onChange={(e) => this.handleChangeValue('entityName', e)} />
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                    {
                                                        (checkout.total > 0) &&
                                                        <React.Fragment>
                                                            <h4 className="section__subtitle">Dirección {this.state.orderType === 'boleta' ? 'para la boleta' : 'de facturación'}</h4>
                                                            <div className="billing">
                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio" id="billingAddressSame" name="billingAddress" value="same" className="custom-control-input" checked={billingAddress === 'same'} onChange={(e) => { this.handleChangeBillingAddress(e, 'same') }} />
                                                                    <label className="custom-control-label" htmlFor="billingAddressSame">Igual que la dirección de envío.</label>
                                                                </div>
                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio" id="billingAddressDifferent" name="billingAddress" value="diff" className="custom-control-input" checked={billingAddress === 'diff'} onChange={(e) => { this.handleChangeBillingAddress(e, 'diff') }} />
                                                                    <label className="custom-control-label" htmlFor="billingAddressDifferent">Otra dirección.</label>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                    {
                                                        (billingAddress === 'diff') &&
                                                        <React.Fragment>
                                                            <div className="section__billing-address section__form">
                                                                <div className="section__title">Dirección</div>
                                                                <div className="section__billing-address__info">
                                                                    <IconHelp></IconHelp>
                                                                    Está dirección se usa para la facturación. No cambiará tu punto de envío de productos
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 col-md-4">
                                                                        <div className={'form-group ' + (errors.department ? 'invalid' : '')}>
                                                                            <label>Departamento</label>
                                                                            <select className="form-control" required name="department" value={this.state.department} onChange={(e) => { this.handleChangeValue('department', e); this.loadProvinces(e) }}>
                                                                                <option value="0" disabled>Seleccionar</option>
                                                                                {
                                                                                    ubigeos.departments.map(d => {
                                                                                        return (
                                                                                            <option key={'d-' + d.id} value={d.id}>{d.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-4">
                                                                        <div className={'form-group ' + (errors.province ? 'invalid' : '')}>
                                                                            <label>Provincia</label>
                                                                            <select className="form-control" required name="province" value={this.state.province} onChange={(e) => { this.handleChangeValue('province', e); this.loadDistricts(e) }}>
                                                                                <option value="0">Seleccionar</option>
                                                                                {
                                                                                    ubigeos.provinces.map(d => {
                                                                                        return (
                                                                                            <option key={'p-' + d.id} value={d.id}>{d.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-4">
                                                                        <div className={'form-group ' + (errors.district ? 'invalid' : '')}>
                                                                            <label>Distrito</label>
                                                                            <select className="form-control" required name="district" value={this.state.district} onChange={(e) => { this.handleChangeValue('district', e); this.setPostalCode(e) }}>
                                                                                <option value="0">Seleccionar</option>
                                                                                {
                                                                                    ubigeos.districts.map(d => {
                                                                                        return (
                                                                                            <option key={'ds-' + d.id} value={d.id}>{d.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className={'form-group ' + (errors.addressLine ? 'invalid' : '')}>
                                                                            <label>Direcci&oacute;n</label>
                                                                            <input type="text" className="form-control"
                                                                                placeholder="Calle/Oficina/Jiron"
                                                                                value={this.state.addressLine}
                                                                                onChange={(e) => this.handleChangeValue('addressLine', e)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className={'form-group ' + (errors.addressNumber ? 'invalid' : '')}>
                                                                            <label>Num. de casa/Dpto/Oficina</label>
                                                                            <input type="text" className="form-control"
                                                                                placeholder="Num. de casa"
                                                                                value={this.state.addressNumber}
                                                                                onChange={(e) => this.handleChangeValue('addressNumber', e)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className={'form-group'}>
                                                                            <label>Referencia para llegar</label>
                                                                            <input type="text" className="form-control"
                                                                                placeholder="Referencia"
                                                                                value={this.state.reference}
                                                                                onChange={(e) => this.handleChangeValue('reference', e)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                    <h4 className="section__subtitle">Términos y condiciones</h4>
                                                    <div className="policies">
                                                        <div className={"custom-control custom-checkbox" + (errors.terms ? ' invalid' : '')}>
                                                            <input type="checkbox" className="custom-control-input" id="termsCheck" name="terms" checked={policies.terms} onChange={(e) => { this.onChangePolicyCheck(e) }} />
                                                            <label className="custom-control-label" htmlFor="termsCheck">
                                                                Acepto los <a href="https://www.shopstar.pe/Institucional/terminos-y-condiciones" target="_blank">t&eacute;rminos y condiciones.</a>
                                                            </label>
                                                        </div>
                                                        <div className={"custom-control custom-checkbox" + (errors.personalData ? ' invalid' : '')}>
                                                            <input type="checkbox" className="custom-control-input" id="personalDataCheck" name="personalData" checked={policies.personalData} onChange={(e) => { this.onChangePolicyCheck(e) }} />
                                                            <label className="custom-control-label" htmlFor="personalDataCheck">
                                                                Acepto los <a href="https://www.shopstar.pe/Institucional/terminos-y-condiciones-pago" target="_blank">t&eacute;rminos y condiciones de pago y canje.</a>
                                                            </label>
                                                        </div>
                                                        <div className="custom-control custom-checkbox mb-4">
                                                            <input type="checkbox" className="custom-control-input" id="privacyCheck" name="privacy" checked={policies.privacy} onChange={(e) => { this.onChangePolicyCheck(e) }} />
                                                            <label className="custom-control-label" htmlFor="privacyCheck">
                                                                Autorizo a Shopstar.pe el uso de mis datos personales para finalidades opcionales previstas en la <a href="https://shopstar.pe/Institucional/politica-de-privacidad" target="_blank">Pol&iacute;tica de Privacidad.</a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="section__form">
                                                    <div className="form-group">
                                                        <button disabled={submitting} type="button" className="btn btn-main btn-submit" onClick={this.handleSubmitPayment}>Pagar {checkout.total}</button>
                                                    </div>
                                                </div>
                                            </React.Fragment>

                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="hidden-mobile">
                                {
                                    (checkout) &&
                                    <Summary checkout={checkout} total={total} totalIbk={totalIbk}></Summary>
                                }
                            </div>
                            <div className="section mt-5 mb-2">
                                <div className="section__title mb-3">Información guardada</div>
                            </div>
                            {
                                (user) &&
                                <User user={user} checkoutId={this.getCheckoutId()}></User>
                            }
                            {
                                (checkout && stores) &&
                                <Shipping checkout={checkout} checkoutId={this.getCheckoutId()} stores={stores} address={address} user={user}></Shipping>
                            }
                        </div>
                    </div>
                </div>
                <div className="container">
                    <Contact></Contact>
                </div>

                {/* <div className="modal fade payment-modal" id="milesModal" role="dialog" aria-labelledby="milesModalLabel" data-backdrop="static" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <IconUser></IconUser>
                                <h4>Ingresa a tu cuenta de Interbank Benefit para usar tus Millas en Shopstar</h4>
                                <p className="text-center">Al darle clic a “Continuar” te llevaremos por un momento al sitio seguro de Interbank.</p>
                                <button className="btn btn-main" type="button" onClick={(e) => { redirectInterbankLogin() }}>Continuar</button>
                                <button className="btn btn-link" type="button" onClick={(e) => { this.closeValidateMilesModal() }}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="modal fade benefit-login-modal payment-modal" id="benefitLoginModal" role="dialog" aria-labelledby="benefitLoginModalLabel" data-backdrop="static" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a role="button" onClick={(e) => { this.toggleModal("#benefitLoginModal", "hide") }}></a>
                                {this.showMilesLoginForm()}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade payment-modal loading-modal" id="loadingModal" role="dialog" aria-labelledby="milesModalLabel" data-backdrop="static" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                {/* <i className="fas fa-spinner fa-pulse fa-2x mb-3"></i> */}
                                <div className="header">
                                    <a href="https://shopstar.pe">
                                        <img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
                                    </a>
                                </div>
                                <img src="/loader.gif" alt="" />
                                <h4>Estamos procesando tu pago</h4>
                                <p className="text-center">
                                    Porfavor, no cierres esta página.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade payment-modal  loading-modal" id="errorModal" role="dialog" aria-labelledby="milesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a role="button" className="close" onClick={(e) => { this.toggleModal("#errorModal", "hide") }}></a>
                                {/* <i className="far fa-times-circle fa-2x mb-3"></i> */}
                                <div className="header">
                                    <a href="https://shopstar.pe">
                                        <img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
                                    </a>
                                </div>
                                <img src="/alert-3d.png" alt="" width="150px" />
                                <h4>{error}</h4>
                                <div className="payment-alert">
                                    No se ha realizado ning&uacute;n cargo a tu tarjeta {maskedCardNumber}
                                </div>
                                <p className="text-center">
                                    Por favor, int&eacute;ntalo con otra forma de pago o comun&iacute;cate con tu banco.
                                </p>
                                <button className="btn btn-main" type="button" onClick={(e) => { this.toggleModal("#errorModal", "hide") }}>Volver a intentarlo</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade payment-modal  loading-modal" id="basicErrorModal" role="dialog" aria-labelledby="milesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a role="button" className="close" onClick={(e) => { this.toggleModal("#basicErrorModal", "hide") }}></a>
                                <div className="header">
                                    <a href="https://shopstar.pe">
                                        <img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
                                    </a>
                                </div>
                                <img src="/alert-3d.png" alt="" width="150px" />
                                <h4>{error}</h4>
                                <button className="btn btn-main" type="button" onClick={(e) => { this.toggleModal("#basicErrorModal", "hide") }}>Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade payment-modal" id="benefitCardErrorModal" role="dialog" aria-labelledby="milesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <i className="far fa-times-circle fa-2x mb-3"></i>
                                <h4>¡Ups!</h4>
                                <p className="text-center">
                                    Para poder usar tus millas, debes ingresar una tarjeta de cr&eacute;dito o d&eacute;bito Interbank.
                                </p>
                                <button className="btn btn-link" type="button" onClick={(e) => { this.toggleModal("#benefitCardErrorModal", "hide") }}>Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckoutPaymentView;
