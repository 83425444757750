import React from "react";

import { ReactComponent as IconUser } from '../../../../assets/user.svg';
import { postCart } from "./MilesMuscleIntegration";

class MilesBenefitsForm extends React.Component {

    state = {
        stepNumber: 0,
        type: '1',
        loading: false,
        error: "",
        poinstVsCash: [],
        indexSelected: 0,
        amountInCash: 0,
        cartId: "",
        hasError: false
    }

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.setState({loading: true})
        postCart(this.props.checkout, this.props.miles.total, this.props.ibkPrices).then((response) => {
            const keysResponse = Object.keys(response);
            if (keysResponse.includes("cart")) {
                //if(keysResponse.includes("cart_id")) {
                console.log("bidding_curve_points_vs_cash válido");
                this.setState({
                    cartId: response.cart.cart_id,
                    poinstVsCash: response.cart.bidding_curve_points_vs_cash
                }, () => {
                    this.setSelectedMiles();
                });

                this.setState({loading: false})
                return;
            } else {
                console.log("bidding_curve_points_vs_cash inválido");
                throw "Post Cart failed";
            }

        }).catch((error) => {
            console.log("Error => ", error);
            this.setState({
                hasError: true,
                loading: false
            })
        });
    }

    setSelectedMiles() {
        console.log("Iniciando setSelectedMiles");
        let index = this.state.poinstVsCash.findIndex(e => e.points < this.props.miles.total);

        if (index > -1) {

            let milesUpdated = {
                total: this.props.miles.total,
                ...this.props.miles,
                change: this.state.poinstVsCash[index].new_cost,
                selected: this.state.poinstVsCash[index].points
            }

            this.props.setMilesChild(milesUpdated);
            this.props.setEquivalentInMiles(this.state.poinstVsCash.find(e => e.cash == 0));

            this.props.setMscInfo(
                this.state.cartId,
                this.state.poinstVsCash[index].points,
                this.state.poinstVsCash[index].cash
            );

            this.props.setMiles(milesUpdated, this.props.miles.type)

            this.setState({
                indexSelected: index,
                amountInCash: this.state.poinstVsCash[index].cash
            });

            return;
        }

        console.warn("Not found in array cash vs points")
    }

    changeMilesAmount = (e, op) => {
        console.log("Iniciando MilesBenefitsForm.changeMilesAmount")
        let selected = null;
        let newIndex = this.state.indexSelected;

        if (op == 1) {

            newIndex = this.state.indexSelected - 1;

            if (newIndex < 0) {
                console.info("Negative")
                return;
            }

            if (this.state.poinstVsCash[newIndex].points > this.props.miles.total) {
                console.log("exceed user points");
                return;
            }
        }

        if (op == -1) {

            newIndex = this.state.indexSelected + 1;

            if (newIndex > this.state.poinstVsCash.length - 1) {
                console.info("Exceed")
                return;
            }
        }

        selected = this.state.poinstVsCash[newIndex];

        let milesUpdated = {
            ...this.props.miles,
            change: selected.new_cost,
            selected: selected.points
        }

        this.props.setMilesChild(milesUpdated);
        this.props.setMiles(milesUpdated, this.props.miles.type);

        this.props.setMscInfo(
            this.state.cartId,
            selected.points,
            selected.cash
        );

        this.setState({
            indexSelected: newIndex,
            amountInCash: selected.cash
        });

        this.props.setEquivalentInMiles(selected);

        console.log("setMscInfo => ");
        console.log(this.props.mscInfo);

        console.log("equivalentInMiles => ");
        console.log(this.props.equivalentInMiles);

    }

    formatMoney = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' }).format(amount) : 'S/ 0.00';
    }

    formatNumber = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE').format(amount) : '0';
    }

    handleChangeMiles = (event) => {
        const { checkout, setMiles, miles, type } = this.props;
        let _miles = miles;
        let value = event.target.value;

        if (value > 0) {
            if (value > _miles.total) {
                value = _miles.total;
            }

            if ((value / miles.change) > checkout.total) {
                value = Math.ceil(checkout.total * miles.change);
            }
        } else {
            value = 0;
        }

        _miles.selected = value;

        setMiles(_miles, type);

        this.props.setMilesChild(_miles);
        this.props.setMiles(_miles, _miles.type)
    }

    calculateExchange = (e) => {
        const { miles } = this.props;
        let _miles = miles;

        _miles.exchange = miles.selected / miles.change;
        // this.setState({ miles: _miles });
        this.props.setMilesChild(_miles);
        this.props.setMiles(_miles, _miles.type)
    }

    render() {
        const { hasError, loading } = this.state;
        const { user, checkout } = this.props;

        return (<React.Fragment>
            {
                loading &&
                <p className="section-title-text text-center mt-3">
                    <i className="fas fa-circle-notch fa-spin mr-2"></i>
                    <b>Cargando Millas...</b>
                </p>
            }
            {
                (!hasError && !loading) &&
                <div className="miles">
                    <h5 className="miles__title">
                        <IconUser></IconUser>
                        Hola {user.firstName} {user.lastName}
                    </h5>
                    <div className="miles__total">
                        Tienes un total de {this.formatNumber(this.props.miles.total)} Millas Benefit acumuladas.
                    </div>
                    <div className="separator"></div>
                    <div className="form-group">
                        <h5>¿Cuántas millas quieres canjear?</h5>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <button className="btn" type="button" onClick={(e) => { this.changeMilesAmount(e, -1) }}>-</button>
                            </div>
                            <input type="text" className="form-control" name="selected" value={this.props.miles.selected} readOnly />
                            <div className="input-group-append">
                                <button className="btn" type="button" onClick={(e) => { this.changeMilesAmount(e, 1) }}>+</button>
                            </div>
                        </div>
                    </div>
                    {/* <div className="separator"></div>
                    <div className="miles__total">
                        <span>Total de Millas a canjear</span>
                    </div> */}
                    <div className="separator"></div>
                    <div className="miles__total">
                        <b>
                            Total a pagar con tarjeta
                        </b>
                        <b>
                            {this.formatMoney(this.state.amountInCash)}
                        </b>
                    </div>
                </div>
            }
            {
                (hasError && !loading) &&
                <div className="text-center p-3">
                    <img src="/alert-3d.png" alt="" width="150px" />
                    <br />
                    <b>
                    Ups!
                    <br />
                    El servicio de millas no esta disponible por el momento.
                    </b>
                </div>
            }
        </React.Fragment>)
    }
}

export default MilesBenefitsForm;
