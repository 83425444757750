import {
	CHECKOUT_POST_BEGIN,
	CHECKOUT_POST_SUCCESS,
	CHECKOUT_POST_FAILURE,
	START_BEGIN,
	START_SUCCESS,
	START_FAILURE,
	CHECKOUT_GET_BEGIN,
	CHECKOUT_GET_SUCCESS,
	CHECKOUT_GET_FAILURE,
	USER_PUT_BEGIN,
	USER_PUT_SUCCESS,
	USER_PUT_FAILURE,
	USER_CHANGE_VIEW,
	SHIPPING_PUT_BEGIN,
	SHIPPING_PUT_SUCCESS,
	SHIPPING_PUT_FAILURE,
	SHIPPING_MAP_CHANGE,
	ITEM_DELETE_BEGIN,
	ITEM_DELETE_SUCCESS,
	ITEM_DELETE_FAILURE,
	GET_ADDRESS_BEGIN,
	GET_ADDRESS_SUCCESS,
	GET_ADDRESS_FAILURE,
	DISCOUNT_BEGIN,
	DISCOUNT_SUCCESS,
	DISCOUNT_FAILURE,
	IBK_AUTH_BEGIN,
	IBK_AUTH_SUCCESS,
	IBK_AUTH_FAILURE,
	GET_MILES_BEGIN,
	GET_MILES_SUCCESS,
	GET_MILES_FAILURE,
	CONVERT_BEGIN,
	CONVERT_SUCCESS,
	CONVERT_FAILURE,
	AUTH_INTERBANK_BEGIN,
	AUTH_INTERBANK_SUCCESS,
	AUTH_INTERBANK_FAILURE,
	GET_INSTALLMENTS_BEGIN,
	GET_INSTALLMENTS_SUCCESS,
	GET_INSTALLMENTS_FAILURE
} from '../constants/ActionTypes';
import { api } from '../../../api/api';
import { GET_ORDER_BEGIN } from '../../checkout-success/constants';
import * as $ from 'jquery';

const apiUrl = api;
const headers = {
	'Access-Control-Allow-Origin': '*',
	'Content-Type': 'application/json',
	'Accept': 'application/json',
}

function handleErrors(response) {
	if (!response.ok) {
		throw Error(response.statusText);
	}
	return response;
}

// Checkout Post

export const checkoutPost = (model) => {
	return dispatch => {

		dispatch(checkoutPostBegin());
		return fetch(`${apiUrl}checkout`, {
			method: 'post',
			headers: headers,
			body: JSON.stringify(model)
		})
			.then(handleErrors)
			.then(res => res.json())
			.then(json => {
				console.log("Submit email:", json);
				//dispatch(checkoutPostSuccess(json));
				window.localStorage.setItem("__paymentGateway__", json.gateway);
				window.localStorage.setItem("__payuSessionId__", json.sessionId);
				window.localStorage.setItem("__enableBenefits__", json.enableBenefits);

				$("#trackEvent").attr("data", JSON.stringify({
					gateway: json.gateway,
					sessionId: json.sessionId
				}));
				$("#trackEvent").trigger('click');

				if (json.gateway === 'payu') {
					var script = document.createElement('script');
					script.src = "https://maf.pagosonline.net/ws/fp/tags.js?id=" + json.sessionId;
					script.type = 'text/javascript';
					document.getElementsByTagName('head')[0].appendChild(script);
				}

				return fetch(`${apiUrl}checkout/${model.draftId}`, {
					method: 'get',
					headers: headers
				})
					.then(handleErrors)
					.then(res => res.json())
					.then(json => {
						dispatch(checkoutGetSuccess(json));
						return json;
					})
					.catch(error => dispatch(checkoutGetFailure(error)));

				return json;
			})
			.catch(error => dispatch(checkoutPostFailure(error)));
	}
}

export const checkoutGet = (id) => {
	return dispatch => {
		dispatch(checkoutGetBegin());
		let get = JSON.parse(window.localStorage.getItem("__get__"));
		if (get) {
			dispatch(checkoutGetSuccess(get));
			return;
		}
		return fetch(`${apiUrl}checkout/${id}`, {
			method: 'get',
			headers: headers
		})
			.then(handleErrors)
			.then(res => res.json())
			.then(json => {
				dispatch(checkoutGetSuccess(json));
				return json;
			})
			.catch(error => dispatch(checkoutGetFailure(error)));
	}
}

export const userPut = (id, user) => {
	return dispatch => {
		dispatch(userPutBegin());

		return fetch(`${apiUrl}checkout/${id}/profile`, {
			method: 'put',
			headers: headers,
			body: JSON.stringify(user)
		})
			.then(handleErrors)
			.then(res => res.json())
			.then(json => {
				dispatch(userPutSuccess(json));
				dispatch(checkoutGetSuccess(json));
				return json;
			})
			.catch(error => dispatch(userPutFailure(error)));
	}
}

export const shippingPut = (id, model, type) => {
	return dispatch => {
		let url = `${apiUrl}checkout/${id}/shipping`;

		dispatch(shippingPutBegin());

		if (type === 2) {
			url += '/selected';
		}
		return fetch(url, {
			method: 'put',
			headers: headers,
			body: JSON.stringify(model)
		})
			.then(handleErrors)
			.then(res => res.json())
			.then(json => {
				dispatch(shippingPutSuccess(json));
				checkoutGet(id);
				//dispatch(checkoutGetSuccess(json));
				//Get Checkout
				dispatch(checkoutGetBegin());
				return fetch(`${apiUrl}checkout/${id}`, {
					method: 'get',
					headers: headers
				})
					.then(handleErrors)
					.then(checkout => checkout.json())
					.then(checkout => {
						dispatch(checkoutGetSuccess(checkout));
						return json;
					})
					.catch(checkoutError => dispatch(checkoutGetFailure(checkoutError)));


				return json;
			})
			.catch(error => dispatch(shippingPutFailure(error)));
	}
}

export const userView = (view) => {
	return dispatch => {
		dispatch(userChangeView(view));
	}
}

export const mapView = (view) => {
	return dispatch => {
		dispatch(shippingMapChangeView(view));
	}
}

export const cartItemDelete = (id, productId) => {
	return dispatch => {
		dispatch(itemDeleteBegin());

		return fetch(`${apiUrl}checkout/${id}/items/${productId}`, {
			method: 'delete',
			headers: headers,
			body: JSON.stringify({})
		})
			.then(handleErrors)
			.then(res => res.json())
			.then(json => {
				dispatch(itemDeleteSuccess(json));
				dispatch(checkoutGetSuccess(json));
				return json;
			})
			.catch(error => dispatch(itemDeleteFailure(error)));
	}
}

export const getAddress = (q, id, model) => {
	return dispatch => {
		dispatch(getAddressBegin());
		dispatch(checkoutGetBegin());
		return fetch(`${apiUrl}checkout/${id}/shipping`, {
			method: 'put',
			headers: headers,
			body: JSON.stringify(model)
		})
			.then(handleErrors)
			.then(resShipping => resShipping.json())
			.then(jsonShipping => {
				dispatch(shippingPutSuccess(jsonShipping));
				checkoutGet(id);

				//Get Checkout

				return fetch(`${apiUrl}checkout/${id}`, {
					method: 'get',
					headers: headers
				})
					.then(handleErrors)
					.then(checkout => checkout.json())
					.then(checkout => {
						dispatch(checkoutGetSuccess(checkout));
						return checkout;
					})
					.catch(checkoutError => dispatch(checkoutGetFailure(checkoutError)));


				//return json;
			})
			.catch(errorShipping => dispatch(shippingPutFailure(errorShipping)));
	}
}

export const checkoutStart = (id) => {
	return dispatch => {
		//dispatch(checkoutGetBegin());
		dispatch(checkoutStartBegin());
		let get = JSON.parse(window.localStorage.getItem("__get__"));
		// if(get) {
		// 	dispatch(checkoutStartSuccess(get.draft));
		// 	dispatch(checkoutGetSuccess(get.draft));
		// 	dispatch(userPutSuccess(null));
		// 	return;
		// }
		return fetch(`${apiUrl}checkout/start?id=${id}`, {
			method: 'post',
			headers: headers
		})
			.then(handleErrors)
			.then(res => res.json())
			.then(json => {
				if (get && get.draft.subTotal === json.subTotal) {
					dispatch(checkoutStartSuccess(get.draft));
					dispatch(checkoutGetSuccess(get.draft));
					dispatch(userPutSuccess(null));
				} else {
					window.localStorage.removeItem("__state__");
					window.localStorage.removeItem("__get__");
					window.localStorage.removeItem("__checkoutId__");
					dispatch(checkoutStartSuccess(json));
				}

				return json;
			})
			.catch(error => {
				//dispatch(checkoutGetFailure(error))
				dispatch(checkoutStartFailure(error));
			});
	}
}

export const getDiscount = (id, model) => {
	return dispatch => {
		dispatch(getDiscountBegin());

		return fetch(`${apiUrl}checkout/${id}/payment`, {
			method: 'put',
			headers: headers,
			body: JSON.stringify(model)
		})
			.then(handleErrors)
			.then(res => res.json())
			.then(json => {
				dispatch(getDiscountSuccess(json));
				dispatch(checkoutGetBegin());
				return fetch(`${apiUrl}checkout/${id}`, {
					method: 'get',
					headers: headers
				})
					.then(handleErrors)
					.then(checkout => checkout.json())
					.then(checkout => {
						dispatch(checkoutGetSuccess(checkout));
						return json;
					})
					.catch(checkoutError => dispatch(checkoutGetFailure(checkoutError)));
				return json;
			})
			.catch(error => dispatch(getDiscountFailure(error)));
	}
}

export const authIbk = (model) => {
	return dispatch => {
		dispatch(authIbkBegin());

		return fetch(`${apiUrl}ibk/login`, {
			method: 'post',
			headers: headers,
			body: JSON.stringify(model)
		})
			.then(handleErrors)
			// .then(res => res.json())
			.then(json => {
				dispatch(authIbkSuccess(json));
				return json;
			})
			.catch(error => dispatch(authIbkFailure(error)));
	}
}

export const getMiles = (model) => {
	return dispatch => {
		dispatch(getMilesBegin());

		return fetch(`${apiUrl}miles/getMiles`, {
			method: 'post',
			headers: headers,
			body: JSON.stringify(model)
		})
			.then(handleErrors)
			.then(res => res.json())
			.then(json => {
				dispatch(getMilesSuccess(json));
				return json;
			})
			.catch(error => dispatch(getMilesFailure(error)));
	}
}

export const convertMiles = (model) => {
	return dispatch => {
		dispatch(convertMilesBegin());

		return fetch(`${apiUrl}miles/simulate`, {
			method: 'post',
			headers: headers,
			body: JSON.stringify(model)
		})
			.then(handleErrors)
			.then(res => res.json())
			.then(json => {
				dispatch(convertMilesSuccess(json));
				return json;
			})
			.catch(error => dispatch(convertMilesFailure(error)));
	}
}

export const authInterbank = (model) => {
	var url = `${apiUrl}ibk/oauth/token`;
	var checkoutId = model.checkOutId;
	var log = {
		url: url,
		checkoutId: checkoutId
	}
	return dispatch => {
		dispatch(authInterbankBegin());
		return fetch(url, {
			method: 'post',
			headers: headers,
			body: JSON.stringify(model)
		})
			.then(handleErrors)
			.then(res => res.json())
			.then(json => {
				dispatch(authInterbankSuccess(json));
				log.response = json;
				$("#trackEvent").attr("data", JSON.stringify(log));
				$("#trackEvent").trigger('click');
				return json;
			})
			.catch(error => {
				log.response = error.toString();
				$("#trackEvent").attr("data", JSON.stringify(log));
				$("#trackEvent").trigger('click');
				dispatch(authInterbankFailure(error))
			});
	}
}

export const getInstallments = (id) => {
	return dispatch => {
		dispatch(getInstallmentsBegin());

		return fetch(`${apiUrl}checkout/${id}/installments`, {
			method: 'get',
			headers: headers
		})
			.then(handleErrors)
			.then(res => res.json())
			.then(json => {
				dispatch(getInstallmentsSuccess(json));
				return json;
			})
			.catch(error => dispatch(getInstallmentsFailure(error)));
	}
}

// Actions

// Checkout Post

export const checkoutPostBegin = () => ({
	type: CHECKOUT_POST_BEGIN
});

export const checkoutPostSuccess = (draft) => ({
	type: CHECKOUT_POST_SUCCESS,
	payload: draft,
});
export const checkoutPostFailure = (error) => ({
	type: CHECKOUT_POST_FAILURE,
	payload: error,
});

// Checkout Get

export const checkoutGetBegin = () => ({
	type: CHECKOUT_GET_BEGIN
});

export const checkoutGetSuccess = (draft) => ({
	type: CHECKOUT_GET_SUCCESS,
	payload: draft,
});
export const checkoutGetFailure = (error) => ({
	type: CHECKOUT_GET_FAILURE,
	payload: error,
});

// User Put

export const userPutBegin = () => ({
	type: USER_PUT_BEGIN
})

export const userPutSuccess = (user) => ({
	type: USER_PUT_SUCCESS,
	payload: user
})

export const userPutFailure = (error) => ({
	type: USER_PUT_FAILURE,
	payload: error
})

export const userChangeView = (view) => ({
	type: USER_CHANGE_VIEW,
	payload: view
})

// Shipping Put

export const shippingPutBegin = () => ({
	type: SHIPPING_PUT_BEGIN
})

export const shippingPutSuccess = (user) => ({
	type: SHIPPING_PUT_SUCCESS,
	payload: user
})

export const shippingPutFailure = (error) => ({
	type: SHIPPING_PUT_FAILURE,
	payload: error
})

export const shippingMapChangeView = (view) => ({
	type: SHIPPING_MAP_CHANGE,
	payload: view
})

// Item Delete

export const itemDeleteBegin = () => ({
	type: ITEM_DELETE_BEGIN
})

export const itemDeleteSuccess = (user) => ({
	type: ITEM_DELETE_SUCCESS,
	payload: user
})

export const itemDeleteFailure = (error) => ({
	type: ITEM_DELETE_FAILURE,
	payload: error
})

// Get Address

export const getAddressBegin = () => ({
	type: GET_ADDRESS_BEGIN
})

export const getAddressSuccess = (payload) => ({
	type: GET_ADDRESS_SUCCESS,
	payload: payload
})

export const getAddressFailure = (error) => ({
	type: GET_ADDRESS_FAILURE,
	payload: error
})

// Start Checkout

export const checkoutStartBegin = () => ({
	type: START_BEGIN
})

export const checkoutStartSuccess = (payload) => ({
	type: START_SUCCESS,
	payload: payload
})

export const checkoutStartFailure = (error) => ({
	type: START_FAILURE,
	payload: error
})

// Get Discount

export const getDiscountBegin = () => ({
	type: DISCOUNT_BEGIN
})

export const getDiscountSuccess = (payload) => ({
	type: DISCOUNT_SUCCESS,
	payload: payload
})

export const getDiscountFailure = (error) => ({
	type: DISCOUNT_FAILURE,
	payload: error
})

// IBK Login

export const authIbkBegin = () => ({
	type: IBK_AUTH_BEGIN
})

export const authIbkSuccess = (payload) => ({
	type: IBK_AUTH_SUCCESS,
	payload: payload
})

export const authIbkFailure = (error) => ({
	type: IBK_AUTH_FAILURE,
	payload: error
})

// Get Miles

export const getMilesBegin = () => ({
	type: GET_MILES_BEGIN
})

export const getMilesSuccess = (payload) => ({
	type: GET_MILES_SUCCESS,
	payload: payload
})

export const getMilesFailure = (error) => ({
	type: GET_MILES_FAILURE,
	payload: error
})

// Convert Miles

export const convertMilesBegin = () => ({
	type: CONVERT_BEGIN
})

export const convertMilesSuccess = (payload) => ({
	type: CONVERT_SUCCESS,
	payload: payload
})

export const convertMilesFailure = (error) => ({
	type: CONVERT_FAILURE,
	payload: error
})

// Interbank Login

export const authInterbankBegin = () => ({
	type: AUTH_INTERBANK_BEGIN
})

export const authInterbankSuccess = (payload) => ({
	type: AUTH_INTERBANK_SUCCESS,
	payload: payload
})

export const authInterbankFailure = (error) => ({
	type: AUTH_INTERBANK_FAILURE,
	payload: error
})

// Interbank Login

export const getInstallmentsBegin = () => ({
	type: GET_INSTALLMENTS_BEGIN
})

export const getInstallmentsSuccess = (payload) => ({
	type: GET_INSTALLMENTS_SUCCESS,
	payload: payload
})

export const getInstallmentsFailure = (error) => ({
	type: GET_INSTALLMENTS_FAILURE,
	payload: error
})
