import React from "react";
import { KEYS } from "../../constants";

class HomeView extends React.Component {

	componentDidMount = async () => {
		window.location.href = process.env.REACT_APP_SITE_URL;
	}


	render() {
		return (
			<div className="App">
			</div>
		);
	}
}

export default HomeView;
