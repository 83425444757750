import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CallbackView from './CallbackView';
import { withRouter } from 'react-router';
import { authInterbank } from './../checkout/actions/checkout';

function CallbackContainer({
	match,
	authInterbank,
	userInterbank
}) {
	return (
		<CallbackView
			match={match}
			authInterbank={authInterbank}
			userInterbank={userInterbank}
		/>
	)
}

function mapStateToProps(state) {
	return {
		userInterbank: state.userInterbank
	}
}

const mapDispatchToProps = {
	authInterbank
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CallbackContainer);
