export const IBK_BINES = [
    "454775",
    "491337",
    "454779",
    "456814",
    "800009",
    "454776",
    "411090",
    "41109105",
    "411093",
    "421813",
    "47324403",
    "47324503",
    "422224",
    "422252",
    "477289",
    "477295",
    "477290",
    "377753",
    "377753",
    "377753",
    "377752",
    "377750",
    "377750",
    "377750",
    "377754",
    "377754",
    "377754",
    "544402",
    "544359",
    "547782",
    "549161",
    "411111",
    "410351",
    "483039"
];

export const IBK_DEBIT_BINES = [
    "421355",
    "454777",
    "411074",
    "483039"
];

export const IBK_ONLY_CC_BINES = [
    "454775",
    "491337",
    "454779",
    "456814",
    "454776",
    "411090",
    "411093",
    "421813",
    "422224",
    "422252",
    "477289",
    "477295",
    "477290",
    "411111",
    "410351"
];

export const IBK_ONLY_CC_VISA_AMEX_BINES = [
    "454775",
    "491337",
    "454779",
    "456814",
    "454776",
    "411090",
    "411093",
    "421813",
    "422224",
    "422252",
    "477289",
    "477295",
    "477290",
    "411111",
    "410351",
    "377753",
    "377753",
    "377753",
    "377752",
    "377750",
    "377750",
    "377750",
    "377754",
    "377754",
    "377754"
];