import React from "react";
import { DISTRICTS, PROVINCES, DEPARTMENTS } from './../../../../data/ubigeos/ubigeos';

class AddressForm extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        addressType: "residential",
        addressId: "",
        department: "0",
        province: "0",
        district: "0",
        addressLine: "",
        addressNumber: "",
        flatNumber: "",
        reference: "",
        postalCode: "",
        deliveryDate: new Date(),
        pickupDate: new Date(),
        countryISO: "PER",
        ubigeos: {
            departments: DEPARTMENTS,
            provinces: [],
            districts: []
        },
        selectedAddressId: "",
        view: "form",
        errors: {}
    }

    componentDidMount = () => {
        this.loadStoredData();
        this.loadAddresses();
    }

    validState = () => {
        let state = this.state;
        let valid = true;
        let errors = {};
        let requiredFields = ["department", "province", "district", "addressLine", "addressNumber"];

        if(state.view === 'list') {
            if(!state.addressId || state.addressId === '') {
                valid = false;
                errors['selectedAddress'] = true;
            }
        } else {
            for (let field in state) {
                if (requiredFields.indexOf(field) > -1 && (!state[field] || state[field] === "" || state[field] === "0")) {
                    errors[field] = true;
                    valid = false;
                }
            }
        }

        this.setState({ errors });
        return valid;
    }

    toggleFieldError = (name, value) => {
        const { errors } = this.state;
        let _errors = errors;

        _errors[name] = value;
        this.setState({ errors: _errors });
    }

    loadAddresses = () => {
        const { checkout } = this.props;
        let view = "form";

        if (checkout.additionalAddress && checkout.additionalAddress.length > 0) {
            view = "list";
            // this.selectAddress(checkout.additionalAddress[0], 0);
        }

        this.setState({ view });
    }

    loadStoredData = () => {
        // let storedData = localStorage.getItem("__state__");
        // if (storedData) {
        //     storedData = JSON.parse(storedData);
        //     this.setState({
        //         department: storedData.address.department,
        //         province: storedData.address.province,
        //         district: storedData.address.district,
        //         addressLine: storedData.address.addressLine,
        //         addressNumber: storedData.address.addressNumber,
        //         flatNumber: storedData.address.flatNumber,
        //         reference: storedData.address.reference,
        //         postalCode: storedData.address.postalCode,
        //     });

        //     this.loadProvinces({ target: { value: storedData.address.department } });
        //     this.loadDistricts({ target: { value: storedData.address.province } });
        //     this.setPostalCode({ target: { value: storedData.address.district } });
        // }
    }

    selectAddress = (address, index) => {
        let {errors} = this.state;

        errors.selectedAddress = false;

        this.setState({
            addressId: address.addressId,
            department: address.department,
            province: address.province,
            district: address.district,
            addressLine: address.addressLine,
            addressNumber: address.addressNumber,
            flatNumber: address.flatNumber,
            reference: address.reference,
            postalCode: address.stateCode,
            selectedAddressId: "address-" + index,
            errors: errors
        });
    }

    newAddress = () => {
        this.setState({
            addressId: "",
            department: "0",
            province: "0",
            district: "0",
            addressLine: "",
            addressNumber: "",
            flatNumber: "",
            reference: "",
            postalCode: "",
            view: "form"
        });
    }

    selectAdditionalAddress = () => {
        const { checkout } = this.props;
        let address = checkout.additionalAddress[0];

        this.setState({
            addressId: address.addressId,
            department: address.department,
            province: address.province,
            district: address.district,
            addressLine: address.addressLine,
            addressNumber: address.addressNumber,
            flatNumber: address.flatNumber,
            reference: address.reference,
            postalCode: address.stateCode,
            selectedAddressId: "address-0",
            view: "list"
        })
    }

    handleChangeValue = (field, event) => {
        let value = event.target.value
        let requiredFields = ["email", "firstName", "lastName", "identificationNumber", "identificationType", "phone"];

        if (requiredFields.indexOf(field) > -1 && (!value || value === "")) {
            this.toggleFieldError(field, true)
        } else {
            this.toggleFieldError(field, false)
        }

        this.setState({
            [field]: value
        });
    }

    handleSubmitAddress = () => {
        const { user, checkout } = this.props;
        const address = this.state;

        let valid = this.validState();

        if (!valid)
            return;

        let items = checkout.items;
        let model = {
            postalCode: address.postalCode,
            address: []
        };

        // let addressId = this.newGuid();
        // if (address.addressId && address.addressId !== "") {
        //     addressId = address.addressId;
        // }

        items.forEach((el, i) => {
            model.address.push({
                addressId: this.newGuid(),
                selectedAddressId: address.addressId,
                addressType: i === 0 ? "residential" : "search",
                firstName: user ? user.firstName : '',
                lastName: user ? user.lastName : '',
                phone: user ? user.phone : '',
                cellPhone: user ? user.phone : '',
                addressLine: address.addressLine,
                addressNumber: address.addressNumber,
                reference: address.reference,
                countryISO: "PER",
                postalCode: address.postalCode,
                department: address.department,
                province: address.province,
                district: address.district,
                geoCoordinates: []
            })
        })

        this.props.handleSubmitAddress(model, address);
    }

    newGuid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    loadProvinces = (e) => {
        const { ubigeos, department } = this.state;

        let provinces = PROVINCES[e.target.value];

        if (provinces && provinces.length > 0) {
            ubigeos.provinces = provinces;

            this.setState({ ubigeos: ubigeos });
        }
    }

    loadDistricts = (e) => {
        const { ubigeos, province } = this.state;

        let districts = DISTRICTS[e.target.value];

        if (districts && districts.length > 0) {
            ubigeos.districts = districts;

            this.setState({ ubigeos: ubigeos });
        }
    }

    setPostalCode = (e) => {
        const { ubigeos } = this.state;
        let value = e.target.value;
        let district = ubigeos.districts.filter((e, i) => { return e.id == value })[0];

        this.setState({
            postalCode: district.ubigeo
        });
    }

    handleEditAddress = () => {
        const { handleEditAddress } = this.props;
        handleEditAddress();
    }

    render() {
        const { ubigeos, view, selectedAddressId, errors } = this.state;
        const { checkout, currentForm, completed, loaders, start } = this.props;

        return (
            <form className="checkout__body__form">
                <h3 className="checkout__body__form__title">
                    <span className={"number " + ((currentForm === 'address' || completed) ? 'purple' : '')}>2</span>
                    Dirección
                </h3>
                <p>
                    Usaremos la dirección para el comprobante de tu compra y en caso elijas envío domicilio.
                </p>
                {
                    (currentForm === 'address') &&
                    <React.Fragment>
                        {
                            (!completed) &&
                            <React.Fragment>
                                {
                                    (view === "form") &&
                                    <React.Fragment>
                                        <div className="card step-form">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className={'form-group ' + (errors.department ? 'invalid' : '')}>
                                                            <label>Departamento</label>
                                                            <select className="form-control" required name="department" value={this.state.department} onChange={(e) => { this.handleChangeValue('department', e); this.loadProvinces(e) }}>
                                                                <option value="0" disabled>Seleccionar</option>
                                                                {
                                                                    ubigeos.departments.map(d => {
                                                                        return (
                                                                            <option key={'d-' + d.id} value={d.id}>{d.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className={'form-group ' + (errors.province ? 'invalid' : '')}>
                                                            <label>Provincia</label>
                                                            <select className="form-control" required name="province" value={this.state.province} onChange={(e) => { this.handleChangeValue('province', e); this.loadDistricts(e) }}>
                                                                <option value="0">Seleccionar</option>
                                                                {
                                                                    ubigeos.provinces.map(d => {
                                                                        return (
                                                                            <option key={'p-' + d.id} value={d.id}>{d.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className={'form-group ' + (errors.district ? 'invalid' : '')}>
                                                            <label>Distrito</label>
                                                            {/* this.getAddress() */}
                                                            <select className="form-control" required name="district" value={this.state.district} onChange={(e) => { this.handleChangeValue('district', e); this.setPostalCode(e) }}>
                                                                <option value="0">Seleccionar</option>
                                                                {
                                                                    ubigeos.districts.map(d => {
                                                                        return (
                                                                            <option key={'ds-' + d.id} value={d.id}>{d.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className={'form-group ' + (errors.addressLine ? 'invalid' : '')}>
                                                            <label>Direcci&oacute;n</label>
                                                            <input type="text" className="form-control"
                                                                placeholder="Calle/Oficina/Jiron"
                                                                value={this.state.addressLine}
                                                                onChange={(e) => this.handleChangeValue('addressLine', e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className={'form-group ' + (errors.addressNumber ? 'invalid' : '')}>
                                                            <label>N&uacute;mero</label>
                                                            <input type="text" className="form-control"
                                                                placeholder="N&uacute;mero"
                                                                value={this.state.addressNumber}
                                                                onChange={(e) => this.handleChangeValue('addressNumber', e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className={'form-group ' + (errors.flatNumber ? 'invalid' : '')}>
                                                            <label>Piso y/o departamento</label>
                                                            <input type="text" className="form-control"
                                                                placeholder="Piso y/o Dpto."
                                                                value={this.state.flatNumber}
                                                                onChange={(e) => this.handleChangeValue('flatNumber', e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className={'form-group'}>
                                                            <label>Referencia para llegar</label>
                                                            <input type="text" className="form-control"
                                                                placeholder="Referencia"
                                                                value={this.state.reference}
                                                                onChange={(e) => this.handleChangeValue('reference', e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (checkout.additionalAddress && checkout.additionalAddress.length > 0) &&
                                                    <p>
                                                        <a className="text-underline" role="button" onClick={(e) => { this.selectAdditionalAddress() }}>
                                                            <b>Elegir una direcci&oacute;n</b>
                                                        </a>
                                                    </p>
                                                }
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group mb-0">
                                                            <button disabled={loaders.address} type="button" className="btn btn-main w-100" onClick={this.handleSubmitAddress}>Continuar</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                {
                                    (view === "list") &&
                                    <React.Fragment>
                                        {
                                            checkout.additionalAddress.map((a, i) => {
                                                return (
                                                    <React.Fragment key={"address-" + i}>
                                                        {
                                                            (i < 3) &&
                                                            <div className={"additional-address card " + (selectedAddressId === "address-" + i ? "selected" : "") + (errors.selectedAddress ? 'error' : '')} id={"address-" + i} onClick={(e) => { this.selectAddress(a, i) }}>
                                                                <div className="card-body">
                                                                    <p>
                                                                        {a.addressLine} {a.addressNumber}
                                                                    </p>
                                                                    <p className="mb-0">
                                                                        {a.district}, {a.province}, {a.department}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                        <div className="form-group">
                                            <p>
                                                <a className="text-underline" role="button" onClick={(e) => { this.newAddress() }}>
                                                    <b>Agregar nueva dirección</b>
                                                </a>
                                            </p>
                                        </div>
                                        <div className="form-group mb-0">
                                            <button disabled={loaders.address} type="button" className="btn btn-main" onClick={this.handleSubmitAddress}>Continuar</button>
                                        </div>
                                    </React.Fragment>
                                }

                            </React.Fragment>
                        }
                    </React.Fragment>
                }

                {
                    (completed) &&
                    <React.Fragment>
                        <div className="card">
                            <div className="card-body">
                                <div className="checkout__body__form__summary">
                                    <div className="checkout__body__form__summary__item d-flex justify-content-between">
                                        <span>Esta es tu direcci&oacute;n</span>
                                        <button type="button" className="btn btn-edit btn-outline-main" onClick={(e) => { this.handleEditAddress() }}>Editar</button>
                                    </div>
                                    <div className="checkout__body__form__summary__item">
                                        <b>Dirección</b>
                                        <span>{this.state.addressLine} {this.state.addressNumber}, {this.state.department}, {this.state.province}, {this.state.district}</span>
                                    </div>
                                    <div className="checkout__body__form__summary__item">
                                        <b>Número de casa/depa/oficina</b>
                                        <span>{this.state.flatNumber}</span>
                                    </div>
                                    {
                                        (this.state.reference !== '') &&
                                        <div className="checkout__body__form__summary__item">
                                            <b>Referencia</b>
                                            <span>{this.state.reference}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </form>
        )
    }
}

export default AddressForm;