import * as React from 'react'
import { ReactComponent as IconClock} from '../../assets/notExpired.svg'
import { ReactComponent as IconExpired} from '../../assets/expired.svg'
import "./CheckoutSuccess.scss";

const CheckoutTimerQR = ({duration}) => {
  const initialTimer = duration;
  const timeoutId = React.useRef(null);
  const [timer, setTimer] = React.useState(initialTimer);

  const countTimer = React.useCallback(() => {
    if (timer <= 0) {
      window.localStorage.clear("timerQR");
    } else {
      setTimer(timer - 1000);
      window.localStorage.setItem("timerQR", timer);
    }
  }, [timer]);

  React.useEffect(() => {
    timeoutId.current = window.setTimeout(countTimer, 1000);
    // cleanup function
    return () => window.clearTimeout(timeoutId.current);
  }, [timer, countTimer]);

  const getFormatTime = (miliseconds) => {
      let seconds = parseInt(Math.floor(miliseconds / 1000));
      let minutes =  parseInt(Math.floor(seconds / 60));
  
      let secondsEnd = parseInt(seconds % 60);
      if (secondsEnd < 10) secondsEnd = '0' + secondsEnd;
      let minutesEnd =  parseInt(minutes % 60);
      return `${minutesEnd}:${secondsEnd}`;
    };

  return (
  <div  className={"checkout-success__body__group__timer " + ( timer <= 0 ? 'checkout-success__body__group__timer_expired' : '')} align="center">
    {
      (timer > 0) && <IconClock></IconClock>
    }
    {
      (timer == 0) && <IconExpired></IconExpired>
    }
    {
      (timer > 0) && 
      <div>QR expira: <b>{getFormatTime(timer)}</b></div>
    }
    {
      (timer == 0) && 
      <div>Tu QR ha expirado, lo sentimos.</div>
    }
  </div>
  )
};
export default CheckoutTimerQR;