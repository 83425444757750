import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	checkoutPost,
	userPut,
	userView,
	shippingPut,
	shippingMapChangeView,
	cartItemDelete,
	getAddress,
	checkoutStart,
	getDiscount,
	authIbk,
	getMiles,
	convertMiles,
	getInstallments
}
	from './actions/checkout';
import { transactionPost, authorizePayments, sendEmblueData, invalidateOrder, getMilesToken } from './actions/transaction';
import CheckoutView from './CheckoutView';
import { withRouter } from 'react-router';
import { installments } from './reducers/checkout';

function CheckoutContainer(
	{
		match,
		get,
		post,
		userStatus,
		checkoutPost,
		userPut,
		userView,
		transaction,
		transactionPost,
		authorizePayments,
		shippingStatus,
		shippingPut,
		shippingMapChangeView,
		cartItem,
		cartItemDelete,
		address,
		getAddress,
		start,
		checkoutStart,
		history,
		getDiscount,
		ibkAuth,
		authIbk,
		getMiles,
		convertMiles,
		ibkMiles,
		exchange,
		appInsights,
		title,
		getInstallments,
		installments,
		sendEmblueData,
		invalidateOrder,
		getMilesToken,
		milesToken
	}) {

	return (
		<CheckoutView
			match={match}
			checkoutPost={checkoutPost}
			get={get}
			post={post}
			userPut={userPut}
			userStatus={userStatus}
			userView={userView}
			shippingStatus={shippingStatus}
			shippingPut={shippingPut}
			transaction={transaction}
			authorizePayments={authorizePayments}
			transactionPost={transactionPost}
			shippingMapChangeView={shippingMapChangeView}
			cartItem={cartItem}
			cartItemDelete={cartItemDelete}
			address={address}
			getAddress={getAddress}
			start={start}
			checkoutStart={checkoutStart}
			history={history}
			getDiscount={getDiscount}
			ibkAuth={ibkAuth}
			authIbk={authIbk}
			getMiles={getMiles}
			ibkMiles={ibkMiles}
			convertMiles={convertMiles}
			exchange={exchange}
			appInsights={appInsights}
			getInstallments={getInstallments}
			installments={installments}
			sendEmblueData={sendEmblueData}
			invalidateOrder={invalidateOrder}
			getMilesToken={getMilesToken}
			milesToken={milesToken}
		/>
	)
}

function mapStateToProps(state) {
	return {
		post: state.checkoutPost,
		get: state.checkoutGet,
		userStatus: state.userPut,
		transaction: state.transactionPost,
		authorize: state.authorizePost,
		shippingStatus: state.shippingPut,
		cartItem: state.cartItem,
		address: state.address,
		start: state.start,
		ibkAuth: state.ibkAuth,
		ibkMiles: state.ibkMiles,
		exchange: state.exchange,
		installments: state.installments,
		invalidate: state.invalidatePost,
		milesToken: state.milesToken
	}
}

const mapDispatchToProps = {
	checkoutPost,
	userPut,
	shippingPut,
	userView,
	shippingMapChangeView,
	transactionPost,
	authorizePayments,
	cartItemDelete,
	getAddress,
	checkoutStart,
	getDiscount,
	authIbk,
	getMiles,
	convertMiles,
	getInstallments,
	sendEmblueData,
	invalidateOrder,
	getMilesToken
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(CheckoutContainer));
